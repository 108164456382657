import {
  FieldDefinition,
  FieldDefinitionType,
  FieldValue,
  Object$,
  Record,
  UpdateRecordRequest,
} from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { Fragment, useRef, useState } from "react";
import { disableQuery, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  getRecord,
  getUser,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import {
  arrow,
  autoPlacement,
  autoUpdate,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { PartialMessage, Timestamp } from "@bufbuild/protobuf";
import moment from "moment";
import { Link } from "react-router-dom";
import { FieldValueInputBoolean } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputBoolean";
import { FieldValueInputNumber } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputNumber";
import { FieldValueInputString } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputString";
import { FieldValueInputTimestamp } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputTimestamp";
import { FieldValueInputUserId } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputUserId";
import { FieldValueInputRecordId } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputRecordId";
import { FieldValueInputEnum } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputEnum";

export function InlineFieldValue({
  size,
  editable,
  object,
  record,
  fieldDefinition,
  onSubmit,
  noValueAppearance,
}: {
  size: "xs" | "sm";
  fieldValue?: FieldValue;
  editable?: boolean;
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit?: (_: PartialMessage<UpdateRecordRequest>) => void;
  noValueAppearance: "blank" | "italic";
}) {
  const [hover, setHover] = useState(false);

  const arrowRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [autoPlacement(), arrow({ element: arrowRef }), offset(10)],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const handleSubmit = (req: PartialMessage<UpdateRecordRequest>) => {
    if (onSubmit) {
      onSubmit(req);
    }
    setOpen(false);
  };

  return (
    <div
      className={clsx(
        "h-full flex items-center justify-between leading-none text-xs",
        editable && "hover:bg-gray-100 p-1 rounded",
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FieldValueDisplayContent
        size={size}
        fieldDefinition={fieldDefinition}
        fieldValue={record.fields[fieldDefinition.apiName]}
        noValueAppearance={noValueAppearance}
      />

      {editable && !hover && <div className="h-4 w-4"></div>}

      {editable && hover && (
        <button>
          <PencilSquareIcon
            className="h-4 w-4 text-gray-500"
            ref={refs.setReference}
            {...getReferenceProps()}
            tabIndex={0}
          />
        </button>
      )}

      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className="z-50"
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                className="fill-white [&>path:first-of-type]:drop-shadow-lg"
              />
              <div className="w-96 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <SingleFieldEditForm
                  object={object}
                  record={record}
                  fieldDefinition={fieldDefinition}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}

function FieldValueDisplayContent({
  fieldDefinition,
  fieldValue,
  noValueAppearance,
}: {
  size: "xs" | "sm";
  fieldDefinition: FieldDefinition;
  fieldValue?: FieldValue;
  noValueAppearance: "blank" | "italic";
}) {
  if (fieldValue === undefined) {
    if (noValueAppearance === "blank") {
      return <span />;
    }
    return <span className="italic">No value</span>;
  }

  switch (fieldValue.value.case) {
    case "boolean":
      return (
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-stone-600 focus:ring-transparent focus:ring-offset-transparent"
          checked={fieldValue.value.value}
          readOnly
        />
      );
    case "number":
      return <span className="">{fieldValue.value.value}</span>;
    case "string":
      return (
        <div>
          {fieldValue.value.value.split("\n").map((line, i) => (
            <p key={i} className="mt-1">
              {line}
            </p>
          ))}
        </div>
      );
    case "timestamp":
      return (
        <span>
          <span>
            {moment(fieldValue.value.value.toDate()).format(
              "MMM Do YYYY hh:mm a",
            )}
          </span>
          <span className="ml-2 text-gray-500">
            ({moment(fieldValue.value.value.toDate()).fromNow()})
          </span>
        </span>
      );
    case "userId":
      return <UserCell userId={fieldValue.value.value} />;
    case "recordId":
      return <RecordCell recordId={fieldValue.value.value} />;
    case "enumValue":
      return (
        <span>
          {
            fieldDefinition.enumValues!.find(
              (v) => v.apiName === fieldValue.value.value,
            )!.displayName
          }
        </span>
      );
  }
}

function UserCell({ userId }: { userId: string }) {
  const { data: user } = useQuery(getUser, {
    id: userId,
  });

  return (
    <div className="flex items-center gap-x-1">
      <img
        className="h-4 w-4 rounded-full"
        alt={user?.displayName}
        src={user?.pictureUrl}
      />
      <span className="text-xs ml-1">{user?.displayName}</span>
    </div>
  );
}

export function RecordCell({ recordId }: { recordId: string }) {
  // todo icon fields

  const { data: record } = useQuery(getRecord, {
    id: recordId,
  });
  const { data: object } = useQuery(
    getObject,
    record ? { id: record.objectId } : disableQuery,
  );

  const primaryDisplayName =
    record &&
    object &&
    object.primaryDisplayFieldDefinitionApiName in record.fields
      ? (record.fields[object.primaryDisplayFieldDefinitionApiName].value
          .value as string)
      : record?.id;

  return (
    <Link to={`/records/${recordId}`} className="flex items-center gap-x-1">
      <span className="text-xs text-stone-700 underline decoration-stone-300">
        {primaryDisplayName}
      </span>
    </Link>
  );
}

export function SingleFieldEditForm({
  object,
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  return (
    <div className="p-4">
      {fieldDefinition.type === FieldDefinitionType.BOOLEAN && (
        <SingleFieldEditFormBoolean
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.NUMBER && (
        <SingleFieldEditFormNumber
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.STRING && (
        <SingleFieldEditFormString
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.TIMESTAMP && (
        <SingleFieldEditFormTimestamp
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.USER_ID && (
        <SingleFieldEditFormUserId
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.RECORD_ID && (
        <SingleFieldEditFormRecordId
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
      {fieldDefinition.type === FieldDefinitionType.ENUM && (
        <SingleFieldEditFormEnum
          object={object}
          record={record}
          fieldDefinition={fieldDefinition}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
}

function SingleFieldEditFormBoolean({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as boolean) || false,
  );

  return (
    <>
      <FieldValueInputBoolean
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: { case: "boolean", value },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function SingleFieldEditFormNumber({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as number) ?? 0,
  );

  return (
    <>
      <FieldValueInputNumber
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: { case: "number", value },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function SingleFieldEditFormString({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as string) || "",
  );

  return (
    <form
      onSubmit={() => {
        onSubmit(
          updateFieldOnRecord(record, fieldDefinition, {
            value: { case: "string", value },
          }),
        );
      }}
    >
      <FieldValueInputString
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="submit"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}

function SingleFieldEditFormTimestamp({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (
      record.fields[fieldDefinition.apiName]?.value.value as Timestamp
    )?.toDate() || new Date(),
  );

  return (
    <>
      <FieldValueInputTimestamp
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: {
                  case: "timestamp",
                  value: Timestamp.fromDate(new Date(value)),
                },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function SingleFieldEditFormUserId({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as string) || "",
  );

  return (
    <>
      <FieldValueInputUserId
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: {
                  case: "userId",
                  value,
                },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function SingleFieldEditFormRecordId({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as string) || "",
  );

  return (
    <>
      <FieldValueInputRecordId
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: {
                  case: "recordId",
                  value,
                },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function SingleFieldEditFormEnum({
  record,
  fieldDefinition,
  onSubmit,
}: {
  object: Object$;
  record: Record;
  fieldDefinition: FieldDefinition;
  onSubmit: (_: PartialMessage<UpdateRecordRequest>) => void;
}) {
  const [value, setValue] = useState(
    (record.fields[fieldDefinition.apiName]?.value.value as string) || "",
  );

  return (
    <>
      <FieldValueInputEnum
        fieldDefinition={fieldDefinition}
        value={value}
        onChange={setValue}
      />

      <div className="mt-4 flex justify-end gap-x-2">
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() =>
            onSubmit(updateFieldOnRecord(record, fieldDefinition, undefined))
          }
        >
          Clear Field
        </button>

        <button
          type="button"
          className="rounded-md bg-stone-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={() =>
            onSubmit(
              updateFieldOnRecord(record, fieldDefinition, {
                value: {
                  case: "enumValue",
                  value,
                },
              }),
            )
          }
        >
          Save
        </button>
      </div>
    </>
  );
}

function updateFieldOnRecord(
  record: Record,
  fieldDefinition: FieldDefinition,
  value?: PartialMessage<FieldValue>,
): PartialMessage<UpdateRecordRequest> {
  const fields: { [_: string]: PartialMessage<FieldValue> } = {};
  for (const [k, v] of Object.entries(record.fields)) {
    fields[k] = v;
  }

  if (value) {
    fields[fieldDefinition.apiName] = value;
  } else {
    delete fields[fieldDefinition.apiName];
  }

  return {
    record: { ...record, fields },
    updateFields: [fieldDefinition.apiName],
  };
}
