import React from "react";
import { PageLayout } from "../../components/ds1/PageLayout";
import { useParams } from "react-router";
import { getObject } from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useQuery } from "@connectrpc/connect-query";
import { Card } from "../../components/Card";
import {
  TextGrid,
  TextGridCell,
  TextGridHeading,
  TextGridKeyValue,
  TextGridValue,
} from "../../components/ds1/TextGrid";
import { FieldType } from "../ViewObjectPage/FieldType";
import { FieldDefinitionType } from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { Link } from "react-router-dom";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import { ENUM_COLOR_DISPLAY_NAMES } from "./components/FieldDefinitionForm";

export function ViewFieldDefinitionPage() {
  const { objectApiName, fieldDefinitionApiName } = useParams();
  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const fieldDefinition = object?.fieldDefinitions?.find(
    (fd) => fd.apiName === fieldDefinitionApiName,
  );

  return (
    <PageLayout
      title={fieldDefinition?.displayName ?? ""}
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNameSingular ?? "",
          href: `/objects/${object?.apiName}`,
        },
        {
          name: "Fields",
        },
        {
          name: fieldDefinition?.displayName ?? "",
          href: `/objects/${object?.apiName}/fields/${fieldDefinition?.apiName}`,
        },
      ]}
      actions={
        <Link
          to={`/objects/${object?.apiName}/fields/${fieldDefinition?.apiName}/edit`}
          type="button"
          className="ml-3 inline-flex items-center rounded-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
        >
          Edit
        </Link>
      }
    >
      <Card>
        <h2 className="text-xl font-semibold text-gray-900">
          General settings
        </h2>

        <TextGrid columns={3}>
          <TextGridCell columns={3} index={0}>
            <TextGridKeyValue>
              <TextGridHeading>Status</TextGridHeading>
              <TextGridValue>
                {fieldDefinition?.archived ? (
                  <span className="flex items-center gap-1 text-gray-900">
                    <MinusCircleIcon className="h-4 w-4" />
                    <span>Archived</span>
                  </span>
                ) : (
                  <span className="flex items-center gap-1 text-green-900">
                    <CheckCircleIcon className="h-4 w-4" />
                    <span>Active</span>
                  </span>
                )}
              </TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>API Name</TextGridHeading>
              <TextGridValue>{fieldDefinition?.apiName}</TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>UUID</TextGridHeading>
              <TextGridValue>{fieldDefinition?.id}</TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>

          <TextGridCell columns={3} index={1}>
            <TextGridKeyValue>
              <TextGridHeading>Display Name</TextGridHeading>
              <TextGridValue>{fieldDefinition?.displayName}</TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>

          <TextGridCell columns={3} index={2}>
            <TextGridKeyValue>
              <TextGridHeading>Data Type</TextGridHeading>
              <TextGridValue>
                {fieldDefinition && <FieldType type={fieldDefinition.type} />}
              </TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>Unique</TextGridHeading>
              <TextGridValue>
                {fieldDefinition?.unique ? "Yes" : "No"}
              </TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>
        </TextGrid>
      </Card>

      {fieldDefinition?.formula !== "" && (
        <Card className="mt-8">
          <h2 className="text-xl font-semibold text-gray-900">Formula</h2>

          <p className="mt-2 text-sm text-gray-700">
            The formula this field is automatically calculated from, based on
            other fields on the record.
          </p>

          <div className="mt-4 font-mono text-sm bg-gray-100 text-gray-900 p-4 rounded">
            {fieldDefinition?.formula}
          </div>
        </Card>
      )}

      {fieldDefinition?.type === FieldDefinitionType.RECORD_ID && (
        <Card className="mt-8">
          <h2 className="text-xl font-semibold text-gray-900">
            Reference Object
          </h2>

          <p className="mt-2 text-sm text-gray-700">
            This field refers to an object.
          </p>

          <ul className="divide-y divide-gray-100">
            <ReferenceObjectRow
              objectId={fieldDefinition.refObjectId}
            ></ReferenceObjectRow>
          </ul>
        </Card>
      )}

      {fieldDefinition?.type === FieldDefinitionType.ENUM && (
        <Card className="mt-8">
          <h2 className="text-xl font-semibold text-gray-900">
            Dropdown Options
            <span className="text-gray-500">
              {" "}
              ({fieldDefinition.enumValues.length})
            </span>
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            A list of the dropdown's options.
          </p>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Display Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        API Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Display Color
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {fieldDefinition.enumValues.map((enumValue) => (
                      <tr key={enumValue.apiName}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {enumValue.displayName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {enumValue.apiName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {ENUM_COLOR_DISPLAY_NAMES[enumValue.displayColor]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      )}
    </PageLayout>
  );
}

function ReferenceObjectRow({ objectId }: { objectId: string }) {
  const { data: object } = useQuery(getObject, { id: objectId });

  return (
    <li className="flex justify-between py-5">
      <div className="flex items-center">
        <div>
          <div className="text-sm font-semibold text-gray-900">
            {object?.displayNameSingular}
          </div>
          <div className="text-xs text-gray-500">{object?.apiName}</div>
        </div>
      </div>

      <div>
        <Link
          to={`/objects/${object?.apiName}`}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
        >
          View object
        </Link>
      </div>
    </li>
  );
}
