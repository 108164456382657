import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import { Listbox } from "@headlessui/react";
import {
  ListboxButton,
  ListboxContents,
  ListboxLabel,
  ListboxOption,
  ListboxOptionCheckIcon,
  ListboxOptions,
  ListboxOptionTextDisplay,
} from "../../ds1/Listbox";
import React from "react";

export function FieldValueInputEnum({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: string;
  onChange: (_: string) => void;
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div>
          <ListboxLabel>{fieldDefinition.displayName}</ListboxLabel>
          <ListboxContents>
            <ListboxButton>
              {value ? (
                <span>
                  {
                    fieldDefinition.enumValues!.find(
                      (v) => v.apiName === value,
                    )!.displayName
                  }
                </span>
              ) : (
                <span>-</span>
              )}
            </ListboxButton>

            <ListboxOptions show={open}>
              {fieldDefinition.enumValues.map((enumValue) => (
                <ListboxOption
                  key={enumValue.apiName}
                  value={enumValue.apiName}
                >
                  {({ selected, active }) => (
                    <>
                      <ListboxOptionTextDisplay selected={selected}>
                        {enumValue.displayName}
                      </ListboxOptionTextDisplay>
                      {selected && <ListboxOptionCheckIcon active={active} />}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </ListboxContents>
        </div>
      )}
    </Listbox>
  );
}
