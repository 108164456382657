import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React from "react";
import { Listbox } from "@headlessui/react";
import {
  ListboxButton,
  ListboxContents,
  ListboxLabel,
  ListboxOption,
  ListboxOptionCheckIcon,
  ListboxOptions,
  ListboxOptionTextDisplay,
} from "../../ds1/Listbox";
import { UserCell } from "../UserCell";
import { useQuery } from "@connectrpc/connect-query";
import { listUsers } from "../../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";

export function FieldValueInputUserId({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: string;
  onChange: (_: string) => void;
}) {
  const { data: listUsersResponse } = useQuery(listUsers);

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div>
          <ListboxLabel>{fieldDefinition.displayName}</ListboxLabel>
          <ListboxContents>
            <ListboxButton>
              {value ? (
                <span>
                  <UserCell userId={value} />
                </span>
              ) : (
                <span>-</span>
              )}
            </ListboxButton>

            <ListboxOptions show={open}>
              {listUsersResponse?.users.map((user) => (
                <ListboxOption key={user.id} value={user.id}>
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={user.pictureUrl}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full mr-3"
                        />
                        <ListboxOptionTextDisplay selected={selected}>
                          {user.displayName}
                        </ListboxOptionTextDisplay>
                        {selected && <ListboxOptionCheckIcon active={active} />}
                      </div>
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </ListboxContents>
        </div>
      )}
    </Listbox>
  );
}
