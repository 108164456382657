import React, { Fragment, useMemo, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useHotkeys } from "react-hotkeys-hook";
import { disableQuery, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  getRecord,
  searchRecords,
} from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";
import { Object$, RecordHit } from "../gen/proto/okapicrm/v1/okapicrm_pb";
import { useDebounce } from "@uidotdev/usehooks";

export function SearchCommandPalette({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
}) {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 100);
  const navigate = useNavigate();
  const object = useCurrentObject();

  useHotkeys("mod+/", () => {
    setOpen(true);
  });

  const { data: searchResponse } = useQuery(
    searchRecords,
    debouncedQuery === "" || !object
      ? disableQuery
      : {
          query: debouncedQuery,
          objectIds: [object.id],
        },
  );

  const recordHits = useMemo(() => {
    if (!searchResponse || !object) {
      return [];
    }

    return searchResponse.recordHits.map((recordHit) => {
      const displayName =
        recordHit.record!.fields[object.primaryDisplayFieldDefinitionApiName]
          ?.value?.value;
      return {
        displayName: (displayName as string) || recordHit.record!.id,
        highlight: parseHighlights(recordHit.highlightSnippet),
        recordHit,
      };
    });
  }, [object, searchResponse]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
              <Combobox
                onChange={(recordHit: RecordHit) => {
                  setOpen(false);
                  navigate(`/records/${recordHit.record!.id}`);
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {query === "" && (
                  <div className="px-6 py-14 text-center sm:px-14">
                    <CubeTransparentIcon
                      className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                      aria-hidden="true"
                    />
                    <p className="mt-4 text-sm text-gray-900">
                      Start typing to search for records.
                    </p>
                  </div>
                )}

                {query !== "" && (
                  <Combobox.Options
                    static
                    className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
                  >
                    {recordHits.map(({ recordHit, displayName, highlight }) => (
                      <Combobox.Option
                        value={recordHit}
                        key={recordHit.record!.id}
                        className={({ active }) =>
                          clsx(
                            "flex cursor-default select-none items-center rounded-md px-3 py-2",
                            active && "bg-gray-900 bg-opacity-5 text-gray-900",
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <CubeTransparentIcon
                              className={clsx(
                                "h-6 w-6 flex-none text-gray-900 text-opacity-40",
                                active && "text-opacity-100",
                              )}
                              aria-hidden="true"
                            />
                            <span className="ml-3 flex-auto truncate">
                              <span>{displayName}</span>
                              <span className="ml-3 text-sm">
                                {highlight.map(
                                  ({ part, highlighted }, index) => (
                                    <span
                                      key={index}
                                      className={clsx(
                                        highlighted && "font-semibold",
                                      )}
                                    >
                                      {part}
                                    </span>
                                  ),
                                )}
                              </span>
                            </span>
                            {active && (
                              <span className="ml-3 flex-none text-gray-500">
                                Jump to...
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && searchResponse?.recordHits?.length === 0 && (
                  <div className="px-6 py-14 text-center sm:px-14">
                    <CubeTransparentIcon
                      className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                      aria-hidden="true"
                    />
                    <p className="mt-4 text-sm text-gray-900">
                      We couldn't find any records with that term. Please try
                      again.
                    </p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function useCurrentObject(): Object$ | undefined {
  const location = useLocation();

  const objectApiName = useMemo(() => {
    if (location.pathname.startsWith("/objects/")) {
      return location.pathname.split("/")[2];
    }
  }, [location]);

  const recordId = useMemo(() => {
    if (location.pathname.startsWith("/records/")) {
      return location.pathname.split("/")[2];
    }
  }, [location]);

  const { data: record } = useQuery(
    getRecord,
    recordId ? { id: recordId } : disableQuery,
  );
  const { data: object } = useQuery(
    getObject,
    record ? { id: record.objectId } : { apiName: objectApiName },
  );

  return object;
}

function parseHighlights(s: string): { highlighted: boolean; part: string }[] {
  const parts = s.split(/<\/?mark>/);
  return parts.map((part, i) => ({ highlighted: i % 2 == 1, part }));
}
