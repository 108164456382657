import React from "react";
import { PageLayout } from "../../components/ds1/PageLayout";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  updateObject,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { FieldDefinitionForm } from "./components/FieldDefinitionForm";
import { PartialMessage } from "@bufbuild/protobuf";
import { FieldDefinition } from "../../gen/proto/okapicrm/v1/okapicrm_pb";

export function EditFieldDefinitionPage() {
  const { objectApiName, fieldDefinitionApiName } = useParams();
  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const fieldDefinition = object?.fieldDefinitions?.find(
    (fd) => fd.apiName === fieldDefinitionApiName,
  );

  const updateObjectMutation = useMutation(updateObject);
  const navigate = useNavigate();
  const handleSubmit = async (
    fieldDefinition: PartialMessage<FieldDefinition>,
  ) => {
    await updateObjectMutation.mutateAsync({
      object: {
        ...object,
        fieldDefinitions: object!.fieldDefinitions.map((fd) =>
          fd.apiName === fieldDefinition.apiName ? fieldDefinition : fd,
        ),
      },
    });

    navigate(`/objects/${object?.apiName}/fields/${fieldDefinition.apiName}`);
  };

  return (
    <PageLayout
      title={`Edit ${fieldDefinition?.displayName ?? ""}`}
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNameSingular ?? "",
          href: `/objects/${object?.apiName}`,
        },
        {
          name: "Fields",
        },
        {
          name: fieldDefinition?.displayName ?? "",
          href: `/objects/${object?.apiName}/fields/${fieldDefinition?.apiName}`,
        },
        {
          name: "Edit",
        },
      ]}
    >
      {object && (
        <FieldDefinitionForm
          object={object}
          fieldDefinition={fieldDefinition}
          cancelUrl={`/objects/${object?.apiName}/fields/${fieldDefinition?.apiName}`}
          onSubmit={handleSubmit}
        />
      )}
    </PageLayout>
  );
}
