import React, { useMemo } from "react";
import { PageLayout } from "../components/ds1/PageLayout";
import { Card } from "../components/Card";
import { disableQuery, useQuery } from "@connectrpc/connect-query";
import {
  getGmailSyncSettings,
  getObject,
  getRecord,
  listRecentContacts,
} from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { RecentContact } from "../gen/proto/okapicrm/v1/okapicrm_pb";
import { InlineFieldValue } from "./ViewRecordPage/InlineFieldValue";
import moment from "moment/moment";
import { Link } from "react-router-dom";

export function RecentContactsPage() {
  const { data: recentContactsResponse } = useQuery(listRecentContacts, {});
  const { data: gmailSettings } = useQuery(getGmailSyncSettings, {});

  return (
    <PageLayout
      title={"Recent Contacts"}
      breadcrumbs={[{ name: "Recent Contacts", href: "/recent-contacts" }]}
    >
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Person
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last Emailed
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {gmailSettings &&
                    recentContactsResponse?.recentContacts.map(
                      (recentContact) => (
                        <RecentContactRow
                          key={recentContact.person!.id}
                          recentContact={recentContact}
                        />
                      ),
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

function RecentContactRow({ recentContact }: { recentContact: RecentContact }) {
  const { data: gmailSettings } = useQuery(getGmailSyncSettings, {});
  const personObjectId = gmailSettings?.personObjectId;

  const { data: personObject } = useQuery(
    getObject,
    personObjectId ? { id: personObjectId } : disableQuery,
  );

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="max-w-sm w-full truncate">
          <Link to={`/records/${recentContact.person!.id}`}>
            {gmailSettings && personObject && (
              <InlineFieldValue
                size="sm"
                object={personObject}
                record={recentContact.person!}
                fieldDefinition={
                  personObject.fieldDefinitions.find(
                    (fd) =>
                      fd.id === gmailSettings.personEmailFieldDefinitionId,
                  )!
                }
                noValueAppearance={"blank"}
              />
            )}
          </Link>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {gmailSettings && personObject && (
          <InlineFieldValue
            size="sm"
            object={personObject}
            record={recentContact.person!}
            fieldDefinition={
              personObject.fieldDefinitions.find(
                (fd) => fd.id === gmailSettings.personCompanyFieldDefinitionId,
              )!
            }
            noValueAppearance={"blank"}
          />
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        {recentContact.lastEmailTime && (
          <TimestampDisplay timestamp={recentContact.lastEmailTime.toDate()} />
        )}
      </td>
    </tr>
  );
}

function TimestampDisplay({ timestamp }: { timestamp: Date }) {
  return (
    <span className="text-xs">
      <span>{moment(timestamp).format("MMM Do YYYY hh:mm a")}</span>
      <span className="ml-2 text-gray-500">
        ({moment(timestamp).fromNow()})
      </span>
    </span>
  );
}
