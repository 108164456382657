import React, { useMemo, useState } from "react";
import { PageLayout } from "../components/ds1/PageLayout";
import {
  createConnectQueryKey,
  disableQuery,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  getGmailSyncSettings,
  getObject,
  getOutlookSyncSettings,
  getRecord,
  listEmailFollowupTasks,
  markEmailFollowupTaskDone,
  starEmailFollowupTask,
} from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useAuthInfo } from "@propelauth/react";
import { Card } from "../components/Card";
import { EmailFollowupTask } from "../gen/proto/okapicrm/v1/okapicrm_pb";
import {
  InlineFieldValue,
  RecordCell,
} from "./ViewRecordPage/InlineFieldValue";
import { useQueryClient } from "@tanstack/react-query";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/ds1/Button";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/20/solid";
import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInboxIn,
  faInboxOut,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { Timestamp } from "@bufbuild/protobuf";
import moment from "moment";
import { Link } from "react-router-dom";
import { ArrowTopRightOnSquareIcon, LinkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

export function ListTasksPage() {
  const { user } = useAuthInfo();
  const [includeSent, setIncludeSent] = useState(true);
  const [includeReceived, setIncludeReceived] = useState(false);

  const { data: listEmailFollowupTasksResponse, isLoading } = useQuery(
    listEmailFollowupTasks,
    {
      userId: user!.userId,
      sent: includeSent,
      received: includeReceived,
    },
  );

  return (
    <PageLayout
      title={"Reminders"}
      breadcrumbs={[{ name: "Reminders", href: "/tasks" }]}
    >
      <Card>
        <h2 className="text-xl font-semibold text-gray-900">
          Email followup reminders
        </h2>

        <div className="mt-4 flex gap-x-2">
          <Button
            variant="soft"
            size="sm"
            iconBefore={
              includeSent && (
                <FontAwesomeIcon icon={faCheck} className="-ml-0.5 h-4 w-4" />
              )
            }
            onClick={() => {
              if (includeSent && !includeReceived) {
                setIncludeReceived(true);
              }
              setIncludeSent(!includeSent);
            }}
          >
            Needs bump
          </Button>

          <Button
            variant="soft"
            size="sm"
            iconBefore={
              includeReceived && (
                <FontAwesomeIcon icon={faCheck} className="-ml-0.5 h-4 w-4" />
              )
            }
            onClick={() => {
              if (includeReceived && !includeSent) {
                setIncludeSent(true);
              }
              setIncludeReceived(!includeReceived);
            }}
          >
            Needs reply
          </Button>
        </div>

        {/*{!isLoading &&*/}
        {/*  listEmailFollowupTasksResponse?.emailFollowupTasks.length === 0 && (*/}
        {/*    <div className="text-center">*/}
        {/*      <SparklesIcon className="mx-auto h-12 w-12 text-gray-400" />*/}
        {/*      /!*<svg*!/*/}
        {/*      /!*  className="mx-auto h-12 w-12 text-gray-400"*!/*/}
        {/*      /!*  fill="none"*!/*/}
        {/*      /!*  viewBox="0 0 24 24"*!/*/}
        {/*      /!*  stroke="currentColor"*!/*/}
        {/*      /!*  aria-hidden="true"*!/*/}
        {/*      /!*>*!/*/}
        {/*      /!*  <path*!/*/}
        {/*      /!*    vectorEffect="non-scaling-stroke"*!/*/}
        {/*      /!*    strokeLinecap="round"*!/*/}
        {/*      /!*    strokeLinejoin="round"*!/*/}
        {/*      /!*    strokeWidth={2}*!/*/}
        {/*      /!*    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"*!/*/}
        {/*      /!*  />*!/*/}
        {/*      /!*</svg>*!/*/}
        {/*      <h3 className="mt-2 text-sm font-semibold text-gray-900">*/}
        {/*        No emails to follow up on*/}
        {/*      </h3>*/}
        {/*      <p className="mt-1 text-sm text-gray-500">*/}
        {/*        You're on top of things!*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  )}*/}

        {(listEmailFollowupTasksResponse?.emailFollowupTasks?.length ?? 0) >
          0 && (
          <div className="divide-y divide-gray-100">
            {listEmailFollowupTasksResponse?.emailFollowupTasks.map((task) => (
              <EmailFollowupTaskRow key={task.id} task={task} />
            ))}
          </div>
        )}
      </Card>
    </PageLayout>
  );
}

function EmailFollowupTaskRow({ task }: { task: EmailFollowupTask }) {
  const { user } = useAuthInfo();
  const { data: gmailSyncSettings } = useQuery(getGmailSyncSettings, {});
  const { data: outlookSyncSettings } = useQuery(getOutlookSyncSettings, {});

  const syncSettings = gmailSyncSettings ?? outlookSyncSettings;

  const emailObjectId = syncSettings?.emailObjectId;

  const { data: emailObject } = useQuery(
    getObject,
    emailObjectId ? { id: emailObjectId } : disableQuery,
  );
  const { data: emailRecord } = useQuery(getRecord, { id: task.emailRecordId });

  const from = useMemo(() => {
    if (!emailObject || !emailRecord || !syncSettings) {
      return undefined;
    }

    const fd = emailObject.fieldDefinitions.find(
      (fd) => fd.id === syncSettings.emailFromFieldDefinitionId,
    )!;
    return emailRecord.fields[fd.apiName].value.value as string;
  }, [emailObject, emailRecord, syncSettings]);

  const to = useMemo(() => {
    if (!emailObject || !emailRecord || !syncSettings) {
      return undefined;
    }

    const fd = emailObject.fieldDefinitions.find(
      (fd) => fd.id === syncSettings.emailToFieldDefinitionId,
    )!;
    return emailRecord.fields[fd.apiName].value.value as string;
  }, [emailObject, emailRecord, syncSettings]);

  const subject = useMemo(() => {
    if (!emailObject || !emailRecord || !syncSettings) {
      return undefined;
    }

    const fd = emailObject.fieldDefinitions.find(
      (fd) => fd.id === syncSettings.emailSubjectFieldDefinitionId,
    )!;
    return emailRecord.fields[fd.apiName].value.value as string;
  }, [emailObject, emailRecord, syncSettings]);

  const body = useMemo(() => {
    if (!emailObject || !emailRecord || !syncSettings) {
      return undefined;
    }

    const fd = emailObject.fieldDefinitions.find(
      (fd) => fd.id === syncSettings.emailBodyFieldDefinitionId,
    )!;
    return emailRecord.fields[fd.apiName].value.value as string;
  }, [emailObject, emailRecord, syncSettings]);

  const [isShowing, setIsShowing] = useState(true);

  const client = useQueryClient();

  const markDoneMutation = useMutation(markEmailFollowupTaskDone);
  const handleMarkDone = async () => {
    setIsShowing(false);

    await new Promise((r) => setTimeout(r, 200));

    await markDoneMutation.mutateAsync({
      emailFollowupTaskId: task.id,
    });

    await client.invalidateQueries({
      queryKey: createConnectQueryKey(listEmailFollowupTasks, {}),
    });
  };

  const starMutation = useMutation(starEmailFollowupTask);
  const handleStar = async () => {
    await starMutation.mutateAsync({
      emailFollowupTaskId: task.id,
      star: !task.starred,
    });

    await client.invalidateQueries({
      queryKey: createConnectQueryKey(listEmailFollowupTasks, {}),
    });
  };

  return (
    <Transition
      show={isShowing}
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex items-center py-5 gap-x-4">
        <div className="flex-0 flex gap-x-2">
          {task.starred ? (
            <SolidStarIcon
              className="h-4 w-4 cursor-pointer text-yellow-500"
              onClick={handleStar}
            />
          ) : (
            <OutlineStarIcon
              className="h-4 w-4 cursor-pointer"
              onClick={handleStar}
            />
          )}

          <FontAwesomeIcon
            icon={faCheck}
            className="h-4 w-4 cursor-pointer"
            onClick={handleMarkDone}
          />
        </div>

        <div className="flex-1">
          <div className="text-sm font-semibold leading-6 text-gray-900 max-w-20">
            <Link
              to={`/records/${task.emailRecordId}`}
              className="hover:underline"
            >
              <div className="w-96 truncate">{subject}</div>
            </Link>
          </div>

          <div className="text-xs text-gray-500 flex items-center gap-x-2">
            <div>
              <span>
                <FontAwesomeIcon
                  icon={task.sent ? faInboxOut : faInboxIn}
                  className="h-3 w-3 mr-1"
                />
              </span>
              <span>
                {task.timestamp && moment(task.timestamp.toDate()).fromNow()}
              </span>
            </div>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <div className="w-64 truncate">{task.sent ? to : from}</div>
          </div>
        </div>

        <div className="flex-1 truncate text-xs text-gray-700">{body}</div>

        <div className="flex-0">
          <a
            href={`https://mail.google.com/mail/u/${user?.email}/#all/${task.threadId}`}
            target="_blank"
          >
            <Button
              variant="secondary"
              size="md"
              iconAfter={<ArrowTopRightOnSquareIcon className="h-4 w-4" />}
            >
              GMail
            </Button>
          </a>
        </div>
      </div>
    </Transition>
  );

  //
  //
  // const markDoneMutation = useMutation(markEmailFollowupTaskDone);
  // const client = useQueryClient();
  // const handleMarkDone = async () => {
  //   console.log("handle mark done");
  //   await markDoneMutation.mutateAsync({
  //     emailFollowupTaskId: task.id,
  //   });
  //
  //   await client.invalidateQueries({
  //     queryKey: createConnectQueryKey(listEmailFollowupTasks, {}),
  //   });
  // };
  //
  // return (
  //   <tr>
  //     <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
  //       {emailRecord && syncSettings && emailObject && (
  //         <InlineFieldValue
  //           size="sm"
  //           object={emailObject}
  //           record={emailRecord}
  //           fieldDefinition={
  //             emailObject.fieldDefinitions.find(
  //               (fd) => fd.id === syncSettings.emailPersonFieldDefinitionId,
  //             )!
  //           }
  //           noValueAppearance={"italic"}
  //         />
  //       )}
  //     </td>
  //     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  //       {" "}
  //       {emailRecord && syncSettings && emailObject && (
  //         <RecordCell recordId={task.emailRecordId} />
  //       )}
  //     </td>
  //     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  //       {" "}
  //       {emailRecord && syncSettings && emailObject && (
  //         <InlineFieldValue
  //           size="sm"
  //           object={emailObject}
  //           record={emailRecord}
  //           fieldDefinition={
  //             emailObject.fieldDefinitions.find(
  //               (fd) => fd.id === syncSettings.emailTimestampFieldDefinitionId,
  //             )!
  //           }
  //           noValueAppearance={"italic"}
  //         />
  //       )}
  //     </td>
  //     <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
  //       <button
  //         onClick={handleMarkDone}
  //         className="text-stone-600 hover:text-stone-900"
  //       >
  //         Mark Done
  //       </button>
  //     </td>
  //   </tr>
}
