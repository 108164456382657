import React from "react";
import clsx from "clsx";

export function Tabs({
  tabs,
  current,
  onChange,
}: {
  tabs: string[];
  current: number;
  onChange: (_: number) => void;
}) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
          value={tabs[current]}
          onChange={(e) => onChange(tabs.indexOf(e.target.value))}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={clsx(
                  index === current
                    ? "border-stone-500 text-stone-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
                )}
                aria-current={index === current ? "page" : undefined}
                onClick={() => onChange(index)}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
