import React, { Fragment } from "react";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  updateObject,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useParams } from "react-router";
import { Card } from "../../components/Card";
import { PageLayout } from "../../components/ds1/PageLayout";
import {
  TextGrid,
  TextGridCell,
  TextGridHeading,
  TextGridKeyValue,
  TextGridValue,
} from "../../components/ds1/TextGrid";
import { FieldsTable } from "./FieldsTable";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";

export function ViewObjectPage() {
  const { objectApiName } = useParams();
  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const updateObjectMutation = useMutation(updateObject);
  const handleToggleArchived = async () => {
    await updateObjectMutation.mutateAsync({
      object: {
        ...object,
        archived: !object!.archived,
      },
    });
  };

  return (
    <PageLayout
      title={object?.displayNameSingular ?? ""}
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNameSingular ?? "",
          href: `/objects/${object?.apiName}`,
        },
      ]}
      actions={
        <>
          <Link
            to={`/objects/${object?.apiName}/records`}
            type="button"
            className="rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-stone-500 hover:bg-stone-700"
          >
            View Records
          </Link>

          <div className="inline-flex rounded-md shadow-sm ml-3">
            <Link
              to={`/objects/${object?.apiName}/edit`}
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500 focus:z-10"
            >
              Edit
            </Link>
            <Menu as="div" className="relative -ml-px block">
              <Menu.Button className="relative inline-flex items-center rounded-r-md bg-stone-500 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500 focus:z-10 focus:z-10">
                <span className="sr-only">Open options</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/objects/${object?.apiName}/fields/new`}
                          className={clsx(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm",
                          )}
                        >
                          Add Field
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={clsx(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm w-full text-start",
                          )}
                          onClick={handleToggleArchived}
                        >
                          {object?.archived ? "Unarchive" : "Archive"}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </>
      }
    >
      <Card>
        <h2 className="text-xl font-semibold text-gray-900">
          General settings
        </h2>

        <TextGrid columns={3}>
          <TextGridCell columns={3} index={0}>
            <TextGridKeyValue>
              <TextGridHeading>Status</TextGridHeading>
              <TextGridValue>
                {object?.archived ? (
                  <span className="flex items-center gap-1 text-gray-900">
                    <MinusCircleIcon className="h-4 w-4" />
                    <span>Archived</span>
                  </span>
                ) : (
                  <span className="flex items-center gap-1 text-green-900">
                    <CheckCircleIcon className="h-4 w-4" />
                    <span>Active</span>
                  </span>
                )}
              </TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>API Name</TextGridHeading>
              <TextGridValue>{object?.apiName}</TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>UUID</TextGridHeading>
              <TextGridValue>{object?.id}</TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>

          <TextGridCell columns={3} index={1}>
            <TextGridKeyValue>
              <TextGridHeading>Display Name (Singular)</TextGridHeading>
              <TextGridValue>{object?.displayNameSingular}</TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>Display Name (Plural)</TextGridHeading>
              <TextGridValue>{object?.displayNamePlural}</TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>

          <TextGridCell columns={3} index={2}>
            <TextGridKeyValue>
              <TextGridHeading>Primary Display Name Field</TextGridHeading>
              <TextGridValue>
                {object?.primaryDisplayFieldDefinitionApiName
                  ? object.fieldDefinitions!.find(
                      (fd) =>
                        fd.apiName ===
                        object.primaryDisplayFieldDefinitionApiName,
                    )!.displayName
                  : "-"}
              </TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>Secondary Display Name Field</TextGridHeading>
              <TextGridValue>
                {object?.secondaryDisplayFieldDefinitionApiName
                  ? object.fieldDefinitions!.find(
                      (fd) =>
                        fd.apiName ===
                        object.secondaryDisplayFieldDefinitionApiName,
                    )!.displayName
                  : "-"}
              </TextGridValue>
            </TextGridKeyValue>

            <TextGridKeyValue>
              <TextGridHeading>Icon Field</TextGridHeading>
              <TextGridValue>
                {object?.iconDisplayFieldDefinitionApiName
                  ? object.fieldDefinitions!.find(
                      (fd) =>
                        fd.apiName === object.iconDisplayFieldDefinitionApiName,
                    )!.displayName
                  : "-"}
              </TextGridValue>
            </TextGridKeyValue>
          </TextGridCell>
        </TextGrid>
      </Card>

      <FieldsTable object={object} />
    </PageLayout>
  );
}
