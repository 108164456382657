import { Combobox, Listbox } from "@headlessui/react";
import {
  ListboxButton,
  ListboxContents,
  ListboxLabel,
  ListboxOption,
  ListboxOptionCheckIcon,
  ListboxOptions,
  ListboxOptionTextDisplay,
} from "../../../components/ds1/Listbox";
import { FieldType } from "../../ViewObjectPage/FieldType";
import {
  EnumValueDisplayColor,
  FieldDefinition,
  FieldDefinitionType,
  Object$,
} from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Card } from "../../../components/Card";
import React, { useEffect, useState } from "react";
import { useQuery } from "@connectrpc/connect-query";
import { listObjects } from "../../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { PartialMessage } from "@bufbuild/protobuf";
import { Input } from "../../../components/ds1/Input";
import clsx from "clsx";
import { Toggle } from "../../../components/Toggle";
import posthog from "posthog-js";

const FIELD_DEFINITION_TYPES = [
  FieldDefinitionType.BOOLEAN,
  FieldDefinitionType.NUMBER,
  FieldDefinitionType.STRING,
  FieldDefinitionType.TIMESTAMP,
  FieldDefinitionType.USER_ID,
  FieldDefinitionType.RECORD_ID,
  FieldDefinitionType.ENUM,
];

export function FieldDefinitionForm({
  fieldDefinition,
  cancelUrl,
  onSubmit,
}: {
  object: Object$;
  fieldDefinition?: FieldDefinition;
  cancelUrl: string;
  onSubmit: (_: PartialMessage<FieldDefinition>) => void;
}) {
  const { data: listObjectsResponse } = useQuery(listObjects, {});

  const [displayName, setDisplayName] = useState("");
  const [apiName, setApiName] = useState("");
  const [archived, setArchived] = useState(false);
  const [type, setType] = useState(FieldDefinitionType.BOOLEAN);
  const [unique, setUnique] = useState(false);
  const [refObject, setRefObject] = useState<Object$ | undefined>(undefined);
  const [enumValues, setEnumValues] = useState<
    {
      apiName: string;
      displayName: string;
      displayColor: EnumValueDisplayColor;
    }[]
  >([
    {
      apiName: "",
      displayName: "",
      displayColor: EnumValueDisplayColor.UNSPECIFIED,
    },
  ]);
  const [formula, setFormula] = useState("");

  useEffect(() => {
    setDisplayName(fieldDefinition?.displayName ?? "");
    setApiName(fieldDefinition?.apiName ?? "");
    setArchived(fieldDefinition?.archived ?? false);
    setType(fieldDefinition?.type ?? FieldDefinitionType.BOOLEAN);
    setUnique(fieldDefinition?.unique ?? false);
    setEnumValues(
      fieldDefinition?.enumValues ?? [
        {
          apiName: "",
          displayName: "",
          displayColor: EnumValueDisplayColor.UNSPECIFIED,
        },
      ],
    );
    setFormula(fieldDefinition?.formula ?? "");
  }, [fieldDefinition]);

  useEffect(() => {
    if (listObjectsResponse && fieldDefinition) {
      setRefObject(
        listObjectsResponse.objects.find(
          (o) => o.id === fieldDefinition.refObjectId,
        )!,
      );
    }
  }, [listObjectsResponse, fieldDefinition]);

  const handleSubmit = () => {
    onSubmit({
      displayName,
      apiName,
      archived,
      type,
      refObjectId: refObject?.id,
      enumValues: type === FieldDefinitionType.ENUM ? enumValues : [], // default value fails validation
      unique,
      formula,
    });
  };

  return (
    <Card>
      <h2 className="text-xl font-semibold text-gray-900">General settings</h2>

      <div className="mt-4 grid grid-cols-1 gap-y-8">
        <div>
          <label
            htmlFor="displayName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Display Name
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="displayName"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
              placeholder="My Cool Field"
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
                if (!fieldDefinition) {
                  setApiName(
                    e.target.value.toLowerCase().replace(/[^a-z]+/g, "_"),
                  );
                }
              }}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            A human-friendly name for your field.
          </p>
        </div>

        <div>
          <label
            htmlFor="apiName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            API Name
          </label>
          <div className="mt-2">
            <Input
              type="text"
              id="apiName"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
              placeholder="my_cool_field"
              value={apiName}
              onChange={(e) => setApiName(e.target.value)}
              disabled={!!fieldDefinition}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            A name that automations can use to refer to your field. You can't
            change this after creation.
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Archived
          </label>
          <div className="mt-2">
            <Toggle
              checked={archived}
              onChange={() => setArchived(!archived)}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Archived fields on records aren't displayed by default. Archiving a
            field does not delete data, and can always be reversed.
          </p>
        </div>

        <div>
          {fieldDefinition ? (
            <span className="px-3 cursor-not-allowed block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 disabled:cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200 sm:text-sm sm:leading-6">
              <FieldType type={type} />
            </span>
          ) : (
            <Listbox value={type} onChange={setType}>
              {({ open }) => (
                <>
                  <ListboxLabel>Type</ListboxLabel>
                  <ListboxContents>
                    <ListboxButton className="text-gray-500">
                      <FieldType type={type} />
                    </ListboxButton>
                    <ListboxOptions show={open}>
                      {FIELD_DEFINITION_TYPES.map((t, i) => (
                        <ListboxOption key={i} value={t}>
                          {({ selected, active }) => (
                            <>
                              <ListboxOptionTextDisplay selected={selected}>
                                <FieldType type={t} />
                              </ListboxOptionTextDisplay>

                              {selected && (
                                <ListboxOptionCheckIcon active={active} />
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </ListboxContents>
                </>
              )}
            </Listbox>
          )}

          <p className="mt-2 text-sm text-gray-500">
            The type of data that will go in the field. You can't change this
            after creation.
          </p>
        </div>

        {type === FieldDefinitionType.STRING && (
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Unique
            </label>
            <div className="mt-2">
              <Toggle
                checked={unique}
                onChange={() => {
                  if (!fieldDefinition) {
                    setUnique(!unique);
                  }
                }}
                className={clsx(fieldDefinition && "!cursor-not-allowed")} // needs !important because of a cursor-pointer in toggle
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              If a field is unique, then you can't have two records with the
              same value for the field.
            </p>
          </div>
        )}

        {type === FieldDefinitionType.RECORD_ID && (
          <div>
            <Listbox value={refObject} onChange={setRefObject}>
              {({ open }) => (
                <>
                  <ListboxLabel>Reference Object</ListboxLabel>
                  <ListboxContents>
                    <ListboxButton>
                      {refObject ? refObject.displayNameSingular : "-"}
                    </ListboxButton>
                    <ListboxOptions show={open}>
                      {listObjectsResponse?.objects?.map((object) => (
                        <ListboxOption key={object.id} value={object}>
                          {({ selected, active }) => (
                            <>
                              <ListboxOptionTextDisplay selected={selected}>
                                {object?.displayNameSingular}
                              </ListboxOptionTextDisplay>

                              {selected && (
                                <ListboxOptionCheckIcon active={active} />
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </ListboxContents>
                </>
              )}
            </Listbox>
            <p className="mt-2 text-sm text-gray-500">
              Records referred to by this field must be from this object. You
              can't change this value later.
            </p>
          </div>
        )}

        {type === FieldDefinitionType.ENUM && (
          <div>
            <span className="block text-sm font-medium leading-6 text-gray-900">
              Dropdown Options
            </span>
            <div className="mt-2">
              <div className="flex gap-4">
                <div className="flex-auto grid grid-cols-3 gap-4">
                  <span className="block text-xs font-medium leading-6 text-gray-900">
                    Display Name
                  </span>
                  <span className="block text-xs font-medium leading-6 text-gray-900">
                    API Name
                  </span>
                  <span className="block text-xs font-medium leading-6 text-gray-900">
                    Display Color
                  </span>
                </div>

                <div className="flex-none">
                  <div className="w-5"></div>
                </div>
              </div>

              {enumValues.map((value, i) => (
                <div key={i} className="flex gap-4 mt-2">
                  <div className="flex-auto grid grid-cols-3 gap-4">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                      placeholder="My Cool Option"
                      value={value.displayName}
                      onChange={(e) => {
                        const newApiName = fieldDefinition
                          ? value.apiName
                          : e.target.value
                              .toLowerCase()
                              .replace(/[^a-z]+/g, "_");

                        setEnumValues([
                          ...enumValues.slice(0, i),
                          {
                            displayName: e.target.value,
                            apiName: newApiName,
                            displayColor: value.displayColor,
                          },
                          ...enumValues.slice(i + 1),
                        ]);
                      }}
                    />

                    <Input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                      placeholder="my_cool_option"
                      disabled={!!fieldDefinition}
                      value={value.apiName}
                      onChange={(e) => {
                        setEnumValues([
                          ...enumValues.slice(0, i),
                          {
                            displayName: value.displayName,
                            apiName: e.target.value,
                            displayColor: value.displayColor,
                          },
                          ...enumValues.slice(i + 1),
                        ]);
                      }}
                    />

                    <EnumColorSelector
                      value={enumValues[i].displayColor}
                      onChange={(displayColor) => {
                        setEnumValues([
                          ...enumValues.slice(0, i),
                          {
                            displayName: value.displayName,
                            apiName: value.apiName,
                            displayColor,
                          },
                          ...enumValues.slice(i + 1),
                        ]);
                      }}
                    />
                  </div>
                  <div className="flex-none flex items-center">
                    <div>
                      <button
                        type="button"
                        className={clsx(
                          "rounded-full bg-stone-600 p-1 text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600",
                          fieldDefinition && "cursor-not-allowed",
                        )}
                        disabled={!!fieldDefinition}
                        onClick={() =>
                          setEnumValues([
                            ...enumValues.slice(0, i),
                            ...enumValues.slice(i + 1),
                          ])
                        }
                      >
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div className="relative mt-4">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() =>
                      setEnumValues([
                        ...enumValues,
                        {
                          displayName: "",
                          apiName: "",
                          displayColor: EnumValueDisplayColor.UNSPECIFIED,
                        },
                      ])
                    }
                  >
                    <PlusIcon
                      className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Add option
                  </button>
                </div>
              </div>

              <p className="mt-2 text-sm text-gray-500">
                The options that can go in the dropdown. You can add to this or
                change display names later. You can't delete values or change
                API names after creation.
              </p>
            </div>
          </div>
        )}

        {posthog.isFeatureEnabled("formula-fields") && (
          <div>
            <label
              htmlFor="formula"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Formula
            </label>
            <div className="mt-2">
              <Input
                type="text"
                id="formula"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                placeholder="record.fields.unit_price * record.fields.quantity"
                value={formula}
                onChange={(e) => setFormula(e.target.value)}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              A formula this field is automatically calculated from, based on
              other fields on the record. Leave this blank if you don't want to
              use a formula.
            </p>
          </div>
        )}
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link
          to={cancelUrl}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </Link>
        <button
          type="submit"
          className="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </div>
    </Card>
  );
}

export const ENUM_COLOR_DISPLAY_NAMES = {
  [EnumValueDisplayColor.UNSPECIFIED]: "Default",
  [EnumValueDisplayColor.RED]: "Red",
  [EnumValueDisplayColor.ORANGE]: "Orange",
  [EnumValueDisplayColor.YELLOW]: "Yellow",
  [EnumValueDisplayColor.GREEN]: "Green",
  [EnumValueDisplayColor.BLUE]: "Blue",
  [EnumValueDisplayColor.VIOLET]: "Violet",
};

const enumColors = [
  EnumValueDisplayColor.UNSPECIFIED,
  EnumValueDisplayColor.RED,
  EnumValueDisplayColor.ORANGE,
  EnumValueDisplayColor.YELLOW,
  EnumValueDisplayColor.GREEN,
  EnumValueDisplayColor.BLUE,
  EnumValueDisplayColor.VIOLET,
];

function EnumColorSelector({
  value,
  onChange,
}: {
  value: EnumValueDisplayColor;
  onChange: (_: EnumValueDisplayColor) => void;
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div>
          <ListboxContents>
            <ListboxButton>{ENUM_COLOR_DISPLAY_NAMES[value]}</ListboxButton>

            <ListboxOptions show={open}>
              {enumColors.map((color, i) => (
                <ListboxOption value={color} key={i}>
                  {({ selected, active }) => (
                    <>
                      <ListboxOptionTextDisplay selected={selected}>
                        {ENUM_COLOR_DISPLAY_NAMES[color]}
                      </ListboxOptionTextDisplay>
                      {selected && <ListboxOptionCheckIcon active={active} />}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </ListboxContents>
        </div>
      )}
    </Listbox>
  );
}
