import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../components/ds1/PageLayout";
import {
  getObject,
  updateObject,
} from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import { Card } from "../components/Card";
import { Link } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import {
  FieldDefinition,
  FieldDefinitionType,
} from "../gen/proto/okapicrm/v1/okapicrm_pb";
import {
  ListboxButton,
  ListboxContents,
  ListboxLabel,
  ListboxOption,
  ListboxOptionCheckIcon,
  ListboxOptions,
  ListboxOptionTextDisplay,
} from "../components/ds1/Listbox";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FieldType } from "./ViewObjectPage/FieldType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

export function EditObjectPage() {
  const { objectApiName } = useParams();
  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const stringFieldDefinitions = object?.fieldDefinitions?.filter(
    (fd) => fd.type === FieldDefinitionType.STRING,
  );

  const [displayNameSingular, setDisplayNameSingular] = useState("");
  const [displayNamePlural, setDisplayNamePlural] = useState("");
  const [
    primaryDisplayFieldDefinitionApiName,
    setPrimaryDisplayFieldDefinitionApiName,
  ] = useState("");

  const primaryDisplayFieldDefinition = object?.fieldDefinitions?.find(
    (fd) => fd.apiName === primaryDisplayFieldDefinitionApiName,
  );

  const [
    secondaryDisplayFieldDefinitionApiName,
    setSecondaryDisplayFieldDefinitionApiName,
  ] = useState("");

  const secondaryDisplayFieldDefinition = object?.fieldDefinitions?.find(
    (fd) => fd.apiName === secondaryDisplayFieldDefinitionApiName,
  );

  const [
    iconDisplayFieldDefinitionApiName,
    seticonDisplayFieldDefinitionApiName,
  ] = useState("");

  const iconDisplayFieldDefinition = object?.fieldDefinitions?.find(
    (fd) => fd.apiName === iconDisplayFieldDefinitionApiName,
  );

  const [fieldDefinitions, setFieldDefinitions] = useState<FieldDefinition[]>(
    [],
  );

  useEffect(() => {
    if (object) {
      setDisplayNameSingular(object?.displayNameSingular);
      setDisplayNamePlural(object?.displayNamePlural);
      setPrimaryDisplayFieldDefinitionApiName(
        object?.primaryDisplayFieldDefinitionApiName,
      );
      setSecondaryDisplayFieldDefinitionApiName(
        object?.secondaryDisplayFieldDefinitionApiName,
      );
      seticonDisplayFieldDefinitionApiName(
        object?.iconDisplayFieldDefinitionApiName,
      );
      setFieldDefinitions(object?.fieldDefinitions ?? []);
    }
  }, [object]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setFieldDefinitions((items) => {
        const oldIndex = items.findIndex((fd) => fd.id === active.id);
        const newIndex = items.findIndex((fd) => fd.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const updateObjectMutation = useMutation(updateObject);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    await updateObjectMutation.mutateAsync({
      object: {
        ...object,
        displayNameSingular,
        displayNamePlural,
        primaryDisplayFieldDefinitionApiName,
        secondaryDisplayFieldDefinitionApiName,
        iconDisplayFieldDefinitionApiName,
        fieldDefinitions,
      },
    });

    navigate(`/objects/${object?.apiName}`);
  };

  return (
    <PageLayout
      title={`Edit ${object?.displayNameSingular ?? ""}`}
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNameSingular ?? "",
          href: `/objects/${object?.apiName}`,
        },
        {
          name: "Edit",
        },
      ]}
    >
      <Card>
        <h2 className="text-xl font-semibold text-gray-900">
          General settings
        </h2>

        <div className="mt-4 grid grid-cols-1 gap-y-8">
          <div>
            <label
              htmlFor="displayNameSingular"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Display Name (Singular)
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="displayNameSingular"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                placeholder="My Cool Object"
                value={displayNameSingular}
                onChange={(e) => setDisplayNameSingular(e.target.value)}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              A human-friendly name for your object in the singular.
            </p>
          </div>

          <div>
            <label
              htmlFor="displayNamePlural"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Display Name (Plural)
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="displayNamePlural"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                placeholder="My Cool Objects"
                value={displayNamePlural}
                onChange={(e) => setDisplayNamePlural(e.target.value)}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              A human-friendly name for your object in the plural.
            </p>
          </div>

          <div>
            <label
              htmlFor="apiName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              API Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="apiName"
                defaultValue={object?.apiName}
                disabled
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
              />
            </div>

            <p className="mt-2 text-sm text-gray-500">
              The name automations use to refer to your object. Cannot be
              modified.
            </p>
          </div>

          <div>
            <Listbox
              value={primaryDisplayFieldDefinitionApiName}
              onChange={setPrimaryDisplayFieldDefinitionApiName}
            >
              {({ open }) => (
                <>
                  <ListboxLabel>Primary Display Field</ListboxLabel>
                  <ListboxContents>
                    <ListboxButton>
                      {primaryDisplayFieldDefinition ? (
                        <span>{primaryDisplayFieldDefinition.displayName}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </ListboxButton>

                    <ListboxOptions show={open}>
                      <ListboxOption value="">
                        {({ selected, active }) => (
                          <>
                            <ListboxOptionTextDisplay selected={selected}>
                              -
                            </ListboxOptionTextDisplay>

                            {selected && (
                              <ListboxOptionCheckIcon active={active} />
                            )}
                          </>
                        )}
                      </ListboxOption>

                      {stringFieldDefinitions?.map((fieldDefinition) => (
                        <ListboxOption
                          key={fieldDefinition.id}
                          value={fieldDefinition.apiName}
                        >
                          {({ selected, active }) => (
                            <>
                              <ListboxOptionTextDisplay selected={selected}>
                                {fieldDefinition.displayName}
                              </ListboxOptionTextDisplay>

                              {selected && (
                                <ListboxOptionCheckIcon active={active} />
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>

                    <p className="mt-2 text-sm text-gray-500">
                      A "name" or "title" field for records of this object.
                    </p>
                  </ListboxContents>
                </>
              )}
            </Listbox>
          </div>

          <div>
            <Listbox
              value={secondaryDisplayFieldDefinitionApiName}
              onChange={setSecondaryDisplayFieldDefinitionApiName}
            >
              {({ open }) => (
                <>
                  <ListboxLabel>Secondary Display Field</ListboxLabel>
                  <ListboxContents>
                    <ListboxButton>
                      {secondaryDisplayFieldDefinition ? (
                        <span>
                          {secondaryDisplayFieldDefinition.displayName}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </ListboxButton>

                    <ListboxOptions show={open}>
                      <ListboxOption value="">
                        {({ selected, active }) => (
                          <>
                            <ListboxOptionTextDisplay selected={selected}>
                              -
                            </ListboxOptionTextDisplay>

                            {selected && (
                              <ListboxOptionCheckIcon active={active} />
                            )}
                          </>
                        )}
                      </ListboxOption>

                      {stringFieldDefinitions?.map((fieldDefinition) => (
                        <ListboxOption
                          key={fieldDefinition.id}
                          value={fieldDefinition.apiName}
                        >
                          {({ selected, active }) => (
                            <>
                              <ListboxOptionTextDisplay selected={selected}>
                                {fieldDefinition.displayName}
                              </ListboxOptionTextDisplay>

                              {selected && (
                                <ListboxOptionCheckIcon active={active} />
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>

                    <p className="mt-2 text-sm text-gray-500">
                      A "name" or "title" field for records of this object.
                    </p>
                  </ListboxContents>
                </>
              )}
            </Listbox>
          </div>

          <div>
            <Listbox
              value={iconDisplayFieldDefinitionApiName}
              onChange={seticonDisplayFieldDefinitionApiName}
            >
              {({ open }) => (
                <>
                  <ListboxLabel>Icon Display Field</ListboxLabel>
                  <ListboxContents>
                    <ListboxButton>
                      {iconDisplayFieldDefinition ? (
                        <span>{iconDisplayFieldDefinition.displayName}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </ListboxButton>

                    <ListboxOptions show={open}>
                      <ListboxOption value="">
                        {({ selected, active }) => (
                          <>
                            <ListboxOptionTextDisplay selected={selected}>
                              -
                            </ListboxOptionTextDisplay>

                            {selected && (
                              <ListboxOptionCheckIcon active={active} />
                            )}
                          </>
                        )}
                      </ListboxOption>

                      {stringFieldDefinitions?.map((fieldDefinition) => (
                        <ListboxOption
                          key={fieldDefinition.id}
                          value={fieldDefinition.apiName}
                        >
                          {({ selected, active }) => (
                            <>
                              <ListboxOptionTextDisplay selected={selected}>
                                {fieldDefinition.displayName}
                              </ListboxOptionTextDisplay>

                              {selected && (
                                <ListboxOptionCheckIcon active={active} />
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>

                    <p className="mt-2 text-sm text-gray-500">
                      A "icon URL" field for records of this object.
                    </p>
                  </ListboxContents>
                </>
              )}
            </Listbox>
          </div>
        </div>
      </Card>

      <Card className="mt-8">
        <h2 className="text-xl font-semibold text-gray-900">Field settings</h2>
        <p className="mt-2 text-sm text-gray-700">
          Configure the order that fields are displayed.
        </p>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={fieldDefinitions}
            strategy={verticalListSortingStrategy}
          >
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Display Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    API Name
                  </th>
                  {/*<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">*/}
                  {/*  <span className="sr-only">Details</span>*/}
                  {/*</th>*/}
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {fieldDefinitions.map((fieldDefinition) => (
                  <SortableFieldDefinition
                    key={fieldDefinition.id}
                    fieldDefinition={fieldDefinition}
                  />
                ))}
              </tbody>
            </table>
          </SortableContext>
        </DndContext>
      </Card>

      <div className="mt-8 flex items-center justify-end gap-x-6">
        <Link
          to={`/objects/${object?.apiName}`}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </Link>
        <button
          type="submit"
          className="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </div>
    </PageLayout>
  );
}

function SortableFieldDefinition({
  fieldDefinition,
}: {
  fieldDefinition: FieldDefinition;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: fieldDefinition.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faGripVertical} />
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
        {fieldDefinition.displayName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <FieldType type={fieldDefinition.type} />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {fieldDefinition.apiName}
      </td>
    </tr>
  );
}
