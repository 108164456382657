import React from "react";
import { listObjects } from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { PageLayout } from "../components/ds1/PageLayout";
import { Link } from "react-router-dom";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "../components/ds1/Button";
import { useInfiniteQuery } from "@connectrpc/connect-query";

export function ListObjectsPage() {
  const {
    data: listObjectsResponses,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    listObjects,
    {
      includeArchived: true,
      pageToken: "",
    },
    {
      getNextPageParam: (res) => res.nextPageToken || undefined,
      pageParamKey: "pageToken",
    },
  );

  return (
    <PageLayout
      title="Objects"
      breadcrumbs={[{ name: "Objects", href: "/objects" }]}
      actions={
        <Link
          to={`/objects/new`}
          type="button"
          className="inline-flex items-center rounded-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
        >
          Create object
        </Link>
      }
    >
      <div className="mt-8 rounded-lg overflow-hidden shadow ring-1 ring-black ring-opacity-5">
        <div className="grid grid-cols-[repeat(4,minmax(0,1fr))_auto_auto] bg-white">
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300">
            Display Name (Singular)
          </div>
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300">
            Display Name (Plural)
          </div>
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300">
            API Name
          </div>
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300">
            UUID
          </div>
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300">
            Status
          </div>
          <div className="bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 border-b border-gray-300"></div>
          {listObjectsResponses?.pages
            .flatMap((page) => page.objects)
            .map((object) => (
              <>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-900 font-medium truncate border-b border-gray-300">
                  {object.displayNamePlural}
                </div>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-500 truncate border-b border-gray-300">
                  {object.displayNameSingular}
                </div>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-500 truncate border-b border-gray-300">
                  {object.apiName}
                </div>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-500 truncate border-b border-gray-300">
                  {object.id}
                </div>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-500 border-b border-gray-300">
                  {object.archived ? (
                    <span className="flex items-center gap-1">
                      <MinusCircleIcon className="h-4 w-4" />
                      <span>Archived</span>
                    </span>
                  ) : (
                    <span className="flex items-center gap-1 text-green-700">
                      <CheckCircleIcon className="h-4 w-4" />
                      <span>Active</span>
                    </span>
                  )}
                </div>
                <div className="py-4 pl-4 pr-3 text-sm text-gray-500 border-b border-gray-300">
                  <Link
                    to={`/objects/${object.apiName}`}
                    className="font-medium text-stone-600 hover:text-stone-900"
                  >
                    Details
                  </Link>
                </div>
              </>
            ))}
        </div>
      </div>

      {hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          variant="secondary"
          size="md"
          className="mt-4"
        >
          Load more
        </Button>
      )}
    </PageLayout>
  );
}
