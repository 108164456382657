import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TransportProvider } from "@connectrpc/connect-query";
import { createConnectTransport } from "@connectrpc/connect-web";
import { RequiredAuthProvider, useAuthInfo } from "@propelauth/react";
import { ListRecordsPage } from "./pages/ListRecordsPage/ListRecordsPage";
import { ListObjectsPage } from "./pages/ListObjectsPage";
import { CreateObjectPage } from "./pages/CreateObjectPage";
import { ViewObjectPage } from "./pages/ViewObjectPage/ViewObjectPage";
import { API_URL, PROPELAUTH_URL } from "./config";
import { ViewRecordPage } from "./pages/ViewRecordPage/ViewRecordPage";
import { Page } from "./components/ds1/Page";
import { ViewFieldDefinitionPage } from "./pages/FieldDefinitions/ViewFieldDefinitionPage";
import { EditObjectPage } from "./pages/EditObjectPage";
import { CreateFieldDefinitionPage } from "./pages/FieldDefinitions/CreateFieldDefinitionPage";
import { EditFieldDefinitionPage } from "./pages/FieldDefinitions/EditFieldDefinitionPage";
import { GoogleOAuthCallbackPage } from "./pages/GoogleOAuthCallbackPage";
import { Toaster } from "react-hot-toast";
import { DryMergeChatBubble } from "drymerge-chat";
import { SearchCommandPalette } from "./components/SearchCommandPalette";
import { ListTasksPage } from "./pages/ListTasksPage";
import { GoogleConnectPage } from "./pages/GoogleConnectPage";
import { MicrosoftOAuthCallbackPage } from "./pages/MicrosoftOAuthCallbackPage";
import { MicrosoftConnectPage } from "./pages/MicrosoftConnectPage";
import posthog from "posthog-js";
import { RecentContactsPage } from "./pages/RecentContactsPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReportsPage } from "./pages/ReportsPage";

export function App() {
  return (
    <RequiredAuthProvider authUrl={PROPELAUTH_URL}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppWithQuery />
      </LocalizationProvider>
      {posthog.isFeatureEnabled("drymerge") && <DryMerge />}
    </RequiredAuthProvider>
  );
}

function DryMerge() {
  return (
    <DryMergeChatBubble public_key="2af64770-78c9-4baf-9d5c-64085d38c10b-1d988106-4c0e-4c6f-81d9-06fe677fd220" />
  );
}

const queryClient = new QueryClient();

function AppWithQuery() {
  const { accessToken, user, orgHelper } = useAuthInfo();
  const org = orgHelper?.getOrgs()[0];

  posthog.identify(user?.userId, {
    email: user?.email,
    orgId: org?.orgId,
    orgName: org?.orgName,
  });

  const transport = createConnectTransport({
    baseUrl: API_URL,
    interceptors: [
      (next) => async (req) => {
        req.header.set("Authorization", `Bearer ${accessToken}`);
        return next(req);
      },
    ],
  });

  return (
    <TransportProvider transport={transport}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Toaster />
          <RouteTree />
        </BrowserRouter>
      </QueryClientProvider>
    </TransportProvider>
  );
}

function RouteTree() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/objects" replace />} />
      <Route
        path="/microsoft/callback"
        element={<MicrosoftOAuthCallbackPage />}
      />
      <Route path="/microsoft/connect" element={<MicrosoftConnectPage />} />

      <Route path="/" element={<Page />}>
        <Route path="/google/connect" element={<GoogleConnectPage />} />
        <Route path="/google/callback" element={<GoogleOAuthCallbackPage />} />

        <Route path="/reminders" element={<ListTasksPage />} />
        <Route path="/recent-contacts" element={<RecentContactsPage />} />
        <Route path="/reports" element={<ReportsPage />} />

        <Route path="/objects" element={<ListObjectsPage />} />
        <Route path="/objects/new" element={<CreateObjectPage />} />

        <Route path="/objects/:objectApiName" element={<ViewObjectPage />} />
        <Route
          path="/objects/:objectApiName/edit"
          element={<EditObjectPage />}
        />

        <Route
          path="/objects/:objectApiName/fields/new"
          element={<CreateFieldDefinitionPage />}
        />

        <Route
          path="/objects/:objectApiName/fields/:fieldDefinitionApiName"
          element={<ViewFieldDefinitionPage />}
        />

        <Route
          path="/objects/:objectApiName/fields/:fieldDefinitionApiName/edit"
          element={<EditFieldDefinitionPage />}
        />

        <Route
          path="/objects/:objectApiName/records"
          element={<ListRecordsPage />}
        />

        <Route path="/records/:id" element={<ViewRecordPage />} />
      </Route>
    </Routes>
  );
}
