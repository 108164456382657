import { FieldDefinitionType } from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faInputNumeric,
  faInputText,
  faLinkHorizontal,
  faListDropdown,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export function FieldType({ type }: { type: FieldDefinitionType }) {
  const { icon, displayName } = {
    [FieldDefinitionType.UNSPECIFIED]: {
      // to appease tsc
      icon: faSquareCheck,
      displayName: "",
    },
    [FieldDefinitionType.BOOLEAN]: {
      icon: faSquareCheck,
      displayName: "Checkbox",
    },
    [FieldDefinitionType.NUMBER]: {
      icon: faInputNumeric,
      displayName: "Number",
    },
    [FieldDefinitionType.STRING]: {
      icon: faInputText,
      displayName: "Text",
    },
    [FieldDefinitionType.TIMESTAMP]: {
      icon: faClock,
      displayName: "Timestamp",
    },
    [FieldDefinitionType.USER_ID]: {
      icon: faUser,
      displayName: "User",
    },
    [FieldDefinitionType.RECORD_ID]: {
      icon: faLinkHorizontal,
      displayName: "Reference",
    },
    [FieldDefinitionType.ENUM]: {
      icon: faListDropdown,
      displayName: "Dropdown",
    },
  }[type];

  return (
    <div className="flex items-center gap-x-2">
      <FontAwesomeIcon icon={icon} fixedWidth />
      <span>{displayName}</span>
    </div>
  );
}
