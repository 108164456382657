import React from "react";
import { Button } from "../components/ds1/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { PageLayout } from "../components/ds1/PageLayout";
import { Card } from "../components/Card";

export function GoogleConnectPage() {
  return (
    <PageLayout
      title="Connect Google"
      breadcrumbs={[{ name: "Connect Google", href: "/google/connect" }]}
    >
      <Card>
        <p className="text-sm text-gray-700 mb-4">
          Click the button below to connect your Google account with Okapi.
        </p>

        <a href="https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/gmail.readonly&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https://app.okapicrm.com/google/callback&client_id=131000613553-k4t8n5o8p46fmoqusull2k7rglgq26c4.apps.googleusercontent.com&access_type=offline&prompt=consent">
          <Button
            variant="primary"
            size="md"
            iconBefore={<FontAwesomeIcon icon={faGoogle} className="h-4 w-4" />}
          >
            Connect your Google account
          </Button>
        </a>
      </Card>
    </PageLayout>
  );
}
