import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createConnectQueryKey,
  disableQuery,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  archiveRecord,
  exportRecords,
  getObject,
  getRecord,
  getUser,
  listRecords,
  updateRecord,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useParams } from "react-router";
import {
  EnumValueDisplayColor,
  FieldDefinition,
  FieldDefinitionType,
  FieldValue,
  Object$,
  Record,
  UpdateRecordRequest,
} from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { PartialMessage } from "@bufbuild/protobuf";
import { Link } from "react-router-dom";
import { PageLayout } from "../../components/ds1/PageLayout";
import { useQueryClient } from "@tanstack/react-query";
import { CreateRecordSlideover } from "./CreateRecordSlideover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { useHotkeys } from "react-hotkeys-hook";
import moment from "moment/moment";
import {
  arrow,
  autoPlacement,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { SingleFieldEditForm } from "../ViewRecordPage/InlineFieldValue";
import { twJoin, twMerge } from "tailwind-merge";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/16/solid";

export function ListRecordsPage() {
  const { objectApiName } = useParams();
  const [showCreateRecord, setShowCreateRecord] = useState(false);

  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const [sortBy, setSortBy] = useState("create_time");
  const [sortAsc, setSortAsc] = useState(false);

  // reset sort params when changing objects
  useEffect(() => {
    setSortBy("create_time");
    setSortAsc(false);
  }, [objectApiName]);

  const {
    data: listRecordsResponses,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    listRecords,
    {
      objectApiName,
      pageToken: "",
      sortBy,
      sortDirection: sortAsc ? "asc" : "desc",
    },
    {
      getNextPageParam: (res) => res.nextPageToken || undefined,
      pageParamKey: "pageToken",
    },
  );

  const exportRecordsMutation = useMutation(exportRecords);
  const handleExportRecords = useCallback(async () => {
    const { downloadUrl } = await exportRecordsMutation.mutateAsync({
      objectId: object!.id,
    });

    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [object, exportRecordsMutation]);

  const client = useQueryClient();
  const updateRecordMutation = useMutation(updateRecord);
  const handleFieldEdit = async (req: PartialMessage<UpdateRecordRequest>) => {
    setOpenedCell(undefined);
    await updateRecordMutation.mutateAsync(req);
    await client.invalidateQueries({
      queryKey: createConnectQueryKey(listRecords, { objectApiName }),
    });
  };

  const records = listRecordsResponses?.pages.flatMap((page) => page.records);

  const columns = useMemo(() => {
    if (!object) {
      return [];
    }

    const columns = [];

    if (object.primaryDisplayFieldDefinitionApiName !== "") {
      columns.push({
        type: "primary",
        primaryDisplayFieldDefinition: object.fieldDefinitions.find(
          (fd) => fd.apiName === object.primaryDisplayFieldDefinitionApiName,
        ),
      });
    }

    if (object.secondaryDisplayFieldDefinitionApiName !== "") {
      columns.push({
        type: "field",
        fieldDefinition: object.fieldDefinitions.find(
          (fd) => fd.apiName === object.secondaryDisplayFieldDefinitionApiName,
        ),
      });
    }

    columns.push({ type: "create_time" });

    for (const fieldDefinition of object.fieldDefinitions) {
      if (fieldDefinition.archived) {
        continue;
      }
      if (
        fieldDefinition.apiName === object.primaryDisplayFieldDefinitionApiName
      ) {
        continue;
      }
      if (
        fieldDefinition.apiName ===
        object.secondaryDisplayFieldDefinitionApiName
      ) {
        continue;
      }
      if (
        fieldDefinition.apiName === object.iconDisplayFieldDefinitionApiName
      ) {
        continue;
      }

      columns.push({
        type: "field",
        fieldDefinition,
      });
    }

    return columns;
  }, [object]);

  const gridTemplateColumns = useMemo(() => {
    return columns
      .map((column) => {
        switch (column.type) {
          case "primary":
            return "300px";
          case "create_time":
            return "200px";
          case "field":
            return "250px";
        }
      })
      .join(" ");
  }, [columns]);

  const tableRef = useRef<HTMLDivElement>(null);
  const [isTableScrolledHorizontally, setIsTableScrolledHorizontally] =
    useState(false);
  const handleTableScroll = () => {
    if (tableRef.current) {
      setIsTableScrolledHorizontally(tableRef.current.scrollLeft !== 0);
    }
  };

  const [focusedCell, setFocusedCell] = useState<[number, number] | undefined>(
    undefined,
  );

  const [batchSelectCell, setBatchSelectCell] = useState<
    [number, number] | undefined
  >(undefined);

  const [openedCell, setOpenedCell] = useState<[number, number] | undefined>(
    undefined,
  );

  const handleMove = (
    cell: [number, number] | undefined,
    offsetX: number,
    offsetY: number,
  ): [number, number] | undefined => {
    if (!cell || !records) {
      return;
    }

    const [x, y] = cell;
    return [
      Math.max(0, Math.min(columns.length - 1, x + offsetX)),
      Math.max(0, Math.min(records.length, y + offsetY)),
    ];
  };

  const handleFocusMove = (offsetX: number, offsetY: number) => {
    let nextFocusedCell = focusedCell;
    if (!nextFocusedCell) {
      nextFocusedCell = [0, 0];
    } else {
      nextFocusedCell = handleMove(nextFocusedCell, offsetX, offsetY);
    }
    setFocusedCell(nextFocusedCell);
    setBatchSelectCell(undefined);
    setOpenedCell(undefined);
  };

  const handleBatchSelectMove = (offsetY: number) => {
    let nextBatchSelectCell = batchSelectCell;
    if (!nextBatchSelectCell) {
      nextBatchSelectCell = focusedCell;
    }
    if (!nextBatchSelectCell) {
      return;
    }

    nextBatchSelectCell = handleMove(nextBatchSelectCell, 0, offsetY);

    const a = focusedCell![1];
    const b = nextBatchSelectCell![1];

    // need to select all between a and b, just do it in both directions
    const rows = new Set<number>();
    for (let i = a; i <= b; i++) {
      rows.add(i);
    }
    for (let i = b; i <= a; i++) {
      rows.add(i);
    }

    setBatchSelectCell(nextBatchSelectCell);
    setSelectedRows(rows);
  };

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const handleSetRowSelected = (row: number, selected: boolean) => {
    const s = new Set(selectedRows);
    if (selected) {
      s.add(row);
    } else {
      s.delete(row);
    }
    setSelectedRows(s);
  };

  const archiveMutation = useMutation(archiveRecord);
  const handleArchive = async () => {
    await Promise.all(
      [...selectedRows].map((index) =>
        archiveMutation.mutateAsync({ id: records![index].id }),
      ),
    );

    setSelectedRows(new Set());
    setFocusedCell(undefined);
    setBatchSelectCell(undefined);

    await client.invalidateQueries({
      queryKey: createConnectQueryKey(listRecords, { objectApiName }),
    });
  };

  useHotkeys("up", (e) => {
    e.preventDefault();
    handleFocusMove(0, -1);
  });
  useHotkeys("down", (e) => {
    e.preventDefault();
    handleFocusMove(0, 1);
  });
  useHotkeys("left", (e) => {
    e.preventDefault();
    handleFocusMove(-1, 0);
  });
  useHotkeys("right", (e) => {
    e.preventDefault();
    handleFocusMove(1, 0);
  });

  useHotkeys("shift+up", (e) => {
    e.preventDefault();
    handleBatchSelectMove(-1);
  });
  useHotkeys("shift+down", (e) => {
    e.preventDefault();
    handleBatchSelectMove(1);
  });

  useHotkeys(
    "space, enter, f2",
    (e) => {
      e.preventDefault();
      setOpenedCell(focusedCell);
    },
    {
      enabled: !openedCell && !showCreateRecord, // don't listen for space/enter when a cell is being edited, else form submit blocked
    },
  );

  useHotkeys("shift+space", () => {
    if (focusedCell) {
      handleSetRowSelected(focusedCell[1], !selectedRows.has(focusedCell[1]));
    }
  });

  return (
    <PageLayout
      title={object?.displayNamePlural ?? ""}
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNamePlural ?? "",
          href: `/objects/${object?.apiName}`,
        },
        { name: "Records", href: `/objects/${object?.apiName}/records` },
      ]}
      actions={
        <>
          <button
            type="button"
            className={clsx(
              "inline-flex items-center gap-x-1.5",
              "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-stone-500 hover:bg-stone-700",
              selectedRows.size === 0 &&
                "cursor-not-allowed !text-stone-500 hover:ring-stone-500",
            )}
            onClick={handleArchive}
          >
            Archive
            {archiveMutation.isPending && (
              <FontAwesomeIcon
                icon={faSpinnerThird}
                spin
                className="-mr-0.5 h-4 w-4"
              />
            )}
          </button>

          <button
            type="button"
            className="ml-3 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-stone-500 hover:bg-stone-700"
            onClick={handleExportRecords}
          >
            Export to CSV
          </button>

          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
            onClick={() => setShowCreateRecord(true)}
          >
            Create {object?.displayNameSingular}
          </button>
        </>
      }
    >
      {object && (
        <CreateRecordSlideover
          open={showCreateRecord}
          onClose={() => setShowCreateRecord(false)}
          showCreateAnother
          object={object}
        />
      )}

      <div
        className="grid bg-white rounded-lg overflow-x-scroll divide-x divide-y divide-gray-100"
        style={{ gridTemplateColumns }}
        onScroll={handleTableScroll}
        ref={tableRef}
      >
        {columns.map((column, i) => (
          <HeaderCell
            key={i}
            sticky={column.type === "primary"}
            shadowRight={
              column.type === "primary" && isTableScrolledHorizontally
            }
          >
            {column.type === "primary" && (
              <div className="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  checked={selectedRows.size === records?.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (records) {
                        const ids = [];
                        for (let i = 0; i < records.length; i++) {
                          ids.push(i);
                        }
                        setSelectedRows(new Set(ids));
                      }
                    } else {
                      setSelectedRows(new Set());
                    }
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-stone-600 focus:ring-stone-600"
                />

                <div className="flex gap-x-2 items-center">
                  <span>{object?.displayNameSingular}</span>
                  {[
                    FieldDefinitionType.NUMBER,
                    FieldDefinitionType.STRING,
                    FieldDefinitionType.TIMESTAMP,
                    FieldDefinitionType.ENUM,
                  ].includes(column.primaryDisplayFieldDefinition!.type) && (
                    <>
                      {sortBy !==
                        column.primaryDisplayFieldDefinition!.apiName && (
                        <FontAwesomeIcon
                          icon={faSort}
                          className="text-gray-500 cursor-pointer h-3 w-3"
                          onClick={() =>
                            setSortBy(
                              column.primaryDisplayFieldDefinition!.apiName,
                            )
                          }
                        />
                      )}
                      {sortBy ===
                        column.primaryDisplayFieldDefinition!.apiName &&
                        sortAsc && (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="text-gray-500 cursor-pointer h-3 w-3"
                            onClick={() => setSortAsc(false)}
                          />
                        )}
                      {sortBy ===
                        column.primaryDisplayFieldDefinition!.apiName &&
                        !sortAsc && (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="text-gray-500 cursor-pointer h-3 w-3"
                            onClick={() => setSortAsc(true)}
                          />
                        )}
                    </>
                  )}
                </div>
              </div>
            )}
            {column.type === "create_time" && (
              <div className="flex gap-x-2 items-center">
                <span>Created</span>
                {sortBy !== "create_time" && (
                  <FontAwesomeIcon
                    icon={faSort}
                    className="text-gray-500 cursor-pointer h-3 w-3"
                    onClick={() => setSortBy("create_time")}
                  />
                )}
                {sortBy === "create_time" && sortAsc && (
                  <FontAwesomeIcon
                    icon={faSortUp}
                    className="text-gray-500 cursor-pointer h-3 w-3"
                    onClick={() => setSortAsc(false)}
                  />
                )}
                {sortBy === "create_time" && !sortAsc && (
                  <FontAwesomeIcon
                    icon={faSortDown}
                    className="text-gray-500 cursor-pointer h-3 w-3"
                    onClick={() => setSortAsc(true)}
                  />
                )}
              </div>
            )}
            {column.type === "field" && (
              <div className="flex gap-x-2 items-center">
                <span>{column.fieldDefinition!.displayName}</span>
                {[
                  FieldDefinitionType.NUMBER,
                  FieldDefinitionType.STRING,
                  FieldDefinitionType.TIMESTAMP,
                  FieldDefinitionType.ENUM,
                ].includes(column.fieldDefinition!.type) && (
                  <>
                    {sortBy !== `fields.${column.fieldDefinition!.apiName}` && (
                      <FontAwesomeIcon
                        icon={faSort}
                        className="text-gray-500 cursor-pointer h-3 w-3"
                        onClick={() =>
                          setSortBy(`fields.${column.fieldDefinition!.apiName}`)
                        }
                      />
                    )}
                    {sortBy === `fields.${column.fieldDefinition!.apiName}` &&
                      sortAsc && (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="text-gray-500 cursor-pointer h-3 w-3"
                          onClick={() => setSortAsc(false)}
                        />
                      )}
                    {sortBy === `fields.${column.fieldDefinition!.apiName}` &&
                      !sortAsc && (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="text-gray-500 cursor-pointer h-3 w-3"
                          onClick={() => setSortAsc(true)}
                        />
                      )}
                  </>
                )}
              </div>
            )}
          </HeaderCell>
        ))}

        {records?.map((record, j) => (
          <>
            {columns.map((column, i) => (
              <Cell
                key={i}
                sticky={column.type === "primary"}
                shadowRight={
                  column.type === "primary" && isTableScrolledHorizontally
                }
                onClick={(e) => {
                  setFocusedCell([i, j]);
                  if (e.detail === 1) {
                    setOpenedCell(undefined);
                  } else {
                    setOpenedCell([i, j]);
                  }
                }}
                focused={
                  focusedCell && focusedCell[0] === i && focusedCell[1] === j
                }
                selected={selectedRows.has(j)}
                danger={
                  column.fieldDefinition &&
                  !!record.formulaErrors[column.fieldDefinition!.apiName]
                }
              >
                {column.type === "primary" && (
                  <PopoverContainer
                    open={
                      !!openedCell && openedCell[0] === i && openedCell[1] === j
                    }
                    setOpen={(open) => {
                      if (open) {
                        setOpenedCell([i, j]);
                      } else if (
                        openedCell &&
                        openedCell[0] === i &&
                        openedCell[1] === j
                      ) {
                        setOpenedCell(undefined);
                      }
                    }}
                    popoverContent={
                      <div
                        className="w-96 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {object && (
                          <SingleFieldEditForm
                            object={object}
                            record={record}
                            fieldDefinition={
                              column.primaryDisplayFieldDefinition!
                            }
                            onSubmit={handleFieldEdit}
                          />
                        )}
                      </div>
                    }
                  >
                    <div className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-stone-600 focus:ring-stone-600"
                        checked={selectedRows.has(j)}
                        onChange={(e) =>
                          handleSetRowSelected(j, e.target.checked)
                        }
                      />
                      {object && <RecordCell record={record} object={object} />}
                    </div>
                  </PopoverContainer>
                )}

                {column.type === "create_time" && (
                  <Timestamp timestamp={record.createTime!.toDate()} />
                )}

                {column.type === "field" && (
                  <MaybePopoverContainer
                    popover={column.fieldDefinition!.formula === ""}
                    open={
                      !!openedCell && openedCell[0] === i && openedCell[1] === j
                    }
                    setOpen={(open) => {
                      if (open) {
                        setOpenedCell([i, j]);
                      } else if (
                        openedCell &&
                        openedCell[0] === i &&
                        openedCell[1] === j
                      ) {
                        setOpenedCell(undefined);
                      }
                    }}
                    popoverContent={
                      <div
                        className="w-96 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {object && (
                          <SingleFieldEditForm
                            object={object}
                            record={record}
                            fieldDefinition={column.fieldDefinition!}
                            onSubmit={handleFieldEdit}
                          />
                        )}
                      </div>
                    }
                  >
                    {record.formulaErrors[column.fieldDefinition!.apiName] ? (
                      <FormulaError recordId={record.id} />
                    ) : (
                      <CellFieldValue
                        fieldValue={
                          record.fields[column.fieldDefinition!.apiName]
                        }
                        fieldDefinition={column.fieldDefinition!}
                      />
                    )}
                  </MaybePopoverContainer>
                )}
              </Cell>
            ))}
          </>
        ))}
      </div>

      {hasNextPage && (
        <button
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => fetchNextPage()}
        >
          Load more
        </button>
      )}
    </PageLayout>
  );
}

function HeaderCell({
  children,
  sticky,
  shadowRight,
}: {
  children?: ReactNode;
  sticky?: boolean;
  shadowRight?: boolean;
}) {
  return (
    <div
      className={clsx(
        "inline-block bg-gray-50 py-1.5 px-2 text-sm font-semibold",
        sticky && "sticky left-0",
        shadowRight && "shadow-[0_20px_25px_-5px_rgba(0,0,0,0.3)]",
      )}
    >
      {children}
    </div>
  );
}

function Cell({
  children,
  sticky,
  shadowRight,
  onClick,
  focused,
  selected,
  danger,
}: {
  children?: ReactNode;
  sticky?: boolean;
  shadowRight?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  focused?: boolean;
  selected?: boolean;
  danger?: boolean;
}) {
  return (
    <div
      className={clsx(
        twMerge(
          "inline-block text-sm py-1.5 px-2 truncate",
          sticky && "sticky left-0",
          shadowRight && "shadow-[0_20px_25px_-5px_rgba(0,0,0,0.3)]",
          focused && "ring-1 ring-inset ring-stone-500 rounded",
          selected && focused && "bg-stone-200",
          selected && !focused && "bg-stone-100",
          !selected && focused && "bg-stone-50",
          !selected && !focused && "bg-white",
          danger && "ring-1 ring-inset ring-red-200 bg-red-50",
        ),
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function CellFieldValue({
  fieldValue,
  fieldDefinition,
}: {
  fieldValue?: FieldValue;
  fieldDefinition: FieldDefinition;
}) {
  if (fieldValue?.value.case === "string") {
    return <span>{fieldValue.value.value}</span>;
  }

  if (fieldValue?.value.case === "number") {
    return <span className="block text-right">{fieldValue.value.value}</span>;
  }

  if (fieldValue?.value.case === "userId") {
    return <UserCell userId={fieldValue.value.value} />;
  }

  if (fieldValue?.value.case === "boolean") {
    return (
      <div className="flex w-full justify-center">
        <input
          type="checkbox"
          readOnly
          className="h-4 w-4 rounded border-gray-300 text-stone-600"
          checked={fieldValue.value.value}
          disabled
        />
      </div>
    );
  }

  if (fieldValue?.value.case === "timestamp") {
    return <Timestamp timestamp={fieldValue.value.value.toDate()} />;
  }

  if (fieldValue?.value.case === "recordId") {
    return <RecordRefCell recordId={fieldValue.value.value} />;
  }

  if (fieldValue?.value.case === "enumValue") {
    return (
      <EnumValueDisplay
        enumValue={fieldValue.value.value}
        fieldDefinition={fieldDefinition}
      />
    );
  }
}

function RecordRefCell({ recordId }: { recordId: string }) {
  const { data: record } = useQuery(getRecord, { id: recordId });
  const { data: object } = useQuery(
    getObject,
    record ? { id: record.objectId } : disableQuery,
  );

  return record && object && <RecordCell record={record} object={object} />;
}

function RecordCell({ record, object }: { record: Record; object: Object$ }) {
  const primaryDisplay = useMemo(() => {
    if (!object || !record || !object.primaryDisplayFieldDefinitionApiName) {
      return "";
    }

    if (!record.fields[object.primaryDisplayFieldDefinitionApiName]) {
      return "";
    }

    return record.fields[object.primaryDisplayFieldDefinitionApiName].value
      .value as string;
  }, [object, record]);

  const icon = useMemo(() => {
    if (!object || !record || !object.iconDisplayFieldDefinitionApiName) {
      return "";
    }

    if (!record.fields[object.iconDisplayFieldDefinitionApiName]) {
      return "";
    }

    return record.fields[object.iconDisplayFieldDefinitionApiName].value
      .value as string;
  }, [object, record]);

  return (
    <Link
      to={`/records/${record.id}`}
      className="inline-flex gap-x-2 items-center"
    >
      {icon && (
        <img src={icon} className="h-4 w-4 rounded-sm border border-gray-100" />
      )}

      <span className="underline decoration-gray-200 underline-offset-2">
        {primaryDisplay || (
          <span className="italic">
            An unnamed {object.displayNameSingular}
          </span>
        )}
      </span>
    </Link>
  );
}

function Timestamp({ timestamp }: { timestamp: Date }) {
  return (
    <div className="flex gap-x-2">
      <span className="w-24">{moment(timestamp).format("DD MMM YYYY")}</span>
      <span>{moment(timestamp).format("hh:mma")}</span>
    </div>
  );
}

function UserCell({ userId }: { userId: string }) {
  const { data: user } = useQuery(getUser, {
    id: userId,
  });

  return (
    <div className="h-full flex items-center gap-x-2">
      <img
        className="h-4 w-4 rounded-full"
        alt={user?.displayName}
        src={user?.pictureUrl}
      />
      <span className="text-sm">{user?.displayName}</span>
    </div>
  );
}

function EnumValueDisplay({
  enumValue,
  fieldDefinition,
}: {
  enumValue: string;
  fieldDefinition: FieldDefinition;
}) {
  const { displayName, displayColor } = fieldDefinition.enumValues.find(
    (v) => v.apiName === enumValue,
  )!;

  return (
    <span
      className={clsx(
        "inline-flex items-center rounded-md px-2 py-px text-xs font-medium ring-1 ring-inset",
        displayColor === EnumValueDisplayColor.UNSPECIFIED &&
          "bg-gray-50 text-gray-600 ring-gray-500/10",
        displayColor === EnumValueDisplayColor.RED &&
          "bg-red-50 text-red-600 ring-red-500/10",
        displayColor === EnumValueDisplayColor.ORANGE &&
          "bg-orange-50 text-orange-600 ring-orange-500/10",
        displayColor === EnumValueDisplayColor.YELLOW &&
          "bg-yellow-50 text-yellow-600 ring-yellow-500/10",
        displayColor === EnumValueDisplayColor.GREEN &&
          "bg-green-50 text-green-600 ring-green-500/10",
        displayColor === EnumValueDisplayColor.BLUE &&
          "bg-blue-50 text-blue-600 ring-blue-500/10",
        displayColor === EnumValueDisplayColor.VIOLET &&
          "bg-violet-50 text-violet-600 ring-violet-500/10",
      )}
    >
      {displayName}
    </span>
  );
}

function FormulaError({ recordId }: { recordId: string }) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(12), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    // If your reference element does not have its own label,
    // e.g. an icon.
    role: "label",
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className="flex items-center h-full gap-x-2"
      >
        <ExclamationCircleIcon className="text-red-800 h-4 w-4" />
        <span className="text-red-800 text-xs">Formula error</span>
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="bg-black text-white p-1 rounded text-xs">
              Formula field errored.{" "}
              <Link to={`/records/${recordId}`} className="underline">
                See record
              </Link>{" "}
              for details.
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

function MaybePopoverContainer({
  popover,
  children,
  popoverContent,
  open,
  setOpen,
}: {
  popover?: boolean;
  children?: ReactNode;
  popoverContent?: ReactNode;
  open: boolean;
  setOpen: (_: boolean) => void;
}) {
  if (popover) {
    return (
      <PopoverContainer
        popoverContent={popoverContent}
        open={open}
        setOpen={setOpen}
      >
        {children}
      </PopoverContainer>
    );
  }
  return children;
}

function PopoverContainer({
  children,
  popoverContent,
  open,
  setOpen,
}: {
  children?: ReactNode;
  popoverContent?: ReactNode;
  open: boolean;
  setOpen: (_: boolean) => void;
}) {
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [autoPlacement(), arrow({ element: arrowRef }), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <div>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={true}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className="z-50"
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                className="fill-white [&>path:first-of-type]:drop-shadow-lg"
              />
              {popoverContent}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}
