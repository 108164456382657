import React from "react";
import clsx from "clsx";

export function Card({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={clsx("rounded-lg bg-white shadow", className)}>
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
}
