import { Object$ } from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { Card } from "../../components/Card";
import React from "react";
import { FieldType } from "./FieldType";
import { Link } from "react-router-dom";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";

export function FieldsTable({ object }: { object?: Object$ }) {
  return (
    <Card className="mt-8">
      <h2 className="text-xl font-semibold text-gray-900">
        Fields
        <span className="text-gray-500">
          {" "}
          ({object?.fieldDefinitions?.length})
        </span>
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        A list of all the fields on {object?.displayNameSingular}, including
        their name, type, and ID. You can edit individual fields from their
        details page.
      </p>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Display Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    API Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Unique
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    UUID
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Details</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {object?.fieldDefinitions?.map((fieldDefinition) => (
                  <tr key={fieldDefinition.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {fieldDefinition.displayName}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {fieldDefinition?.archived ? (
                        <span className="flex items-center gap-1 text-gray-900">
                          <MinusCircleIcon className="h-4 w-4" />
                          <span>Archived</span>
                        </span>
                      ) : (
                        <span className="flex items-center gap-1 text-green-900">
                          <CheckCircleIcon className="h-4 w-4" />
                          <span>Active</span>
                        </span>
                      )}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <FieldType type={fieldDefinition.type} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {fieldDefinition.apiName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {fieldDefinition.unique ? "Yes" : "No"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {fieldDefinition.id}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link
                        className="text-stone-600 hover:text-stone-900"
                        to={`/objects/${object.apiName}/fields/${fieldDefinition.apiName}`}
                      >
                        Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
}
