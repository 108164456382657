import React, { ReactNode } from "react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { Helmet } from "react-helmet";

export function PageLayout({
  title,
  titleDisplay,
  breadcrumbs,
  children,
  actions,
}: {
  title: string;
  titleDisplay?: ReactNode;
  breadcrumbs: { name: string; href?: string }[];
  children?: ReactNode;
  actions?: ReactNode;
}) {
  return (
    <>
      <Helmet>
        <title>{`${title} - Okapi CRM`}</title>
      </Helmet>

      <header className="py-10 lg:pl-72 bg-stone-800 pb-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={breadcrumbs} />

          <div className="mt-2 sm:flex">
            <h1 className="sm:flex-auto text-3xl font-bold tracking-tight text-white">
              {titleDisplay || title}
            </h1>
            <div className="sm:flex-none">{actions}</div>
          </div>
        </div>
      </header>

      <main className="py-10 lg:pl-72 -mt-32">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </>
  );
}

function Breadcrumbs({ pages }: { pages: { name: string; href?: string }[] }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href="/" className="text-stone-400 hover:text-stone-200">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-stone-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {page.href ? (
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-stone-400 hover:text-stone-200"
                >
                  {page.name}
                </a>
              ) : (
                <span className="ml-4 text-sm font-medium text-stone-400">
                  {page.name}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
