import React from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@connectrpc/connect-query";
import { createMicrosoftConnection } from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";

export function MicrosoftOAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const { data } = useQuery(createMicrosoftConnection, {
    code: searchParams.get("code") ?? undefined,
  });

  return <div>{JSON.stringify(data)}</div>;
}
