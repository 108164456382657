import React, { Fragment, useState } from "react";
import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query";
import {
  createRecord,
  listRecords,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import {
  FieldDefinitionType,
  FieldValue,
  Object$,
  Record,
} from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { Timestamp } from "@bufbuild/protobuf";
import { SlideOver } from "../../components/SlideOver";
import { useForm } from "@tanstack/react-form";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FieldValueInputBoolean } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputBoolean";
import { FieldValueInputNumber } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputNumber";
import { FieldValueInputString } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputString";
import { FieldValueInputTimestamp } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputTimestamp";
import { FieldValueInputUserId } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputUserId";
import { FieldValueInputRecordId } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputRecordId";
import { FieldValueInputEnum } from "../../components/InlineFieldValue/FieldValueInput/FieldValueInputEnum";
import { Button } from "../../components/ds1/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import toast from "react-hot-toast";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Transition } from "@headlessui/react";

export function CreateRecordSlideover({
  open,
  onClose,
  onRecordCreated,
  showCreateAnother,
  object,
}: {
  open: boolean;
  onClose: () => void;
  onRecordCreated?: (_: Record) => void;
  showCreateAnother?: boolean;
  object: Object$;
}) {
  const createRecordMutation = useMutation(createRecord);
  const [createAnother, setCreateAnother] = useState(false);
  const client = useQueryClient();

  const form = useForm({
    defaultValues: {} as any,
    onSubmit: async (values) => {
      const fields: { [x: string]: Partial<FieldValue> } = {};
      for (const field of object.fieldDefinitions) {
        if (!(field.apiName in values)) {
          continue;
        }

        switch (field.type) {
          case FieldDefinitionType.BOOLEAN:
            fields[field.apiName] = {
              value: { case: "boolean", value: values[field.apiName] },
            };
            break;
          case FieldDefinitionType.NUMBER:
            fields[field.apiName] = {
              value: { case: "number", value: parseInt(values[field.apiName]) },
            };
            break;
          case FieldDefinitionType.STRING:
            fields[field.apiName] = {
              value: { case: "string", value: values[field.apiName] },
            };
            break;
          case FieldDefinitionType.TIMESTAMP:
            fields[field.apiName] = {
              value: {
                case: "timestamp",
                value: Timestamp.fromDate(values[field.apiName]),
              },
            };
            break;
          case FieldDefinitionType.USER_ID:
            fields[field.apiName] = {
              value: {
                case: "userId",
                value: values[field.apiName],
              },
            };
            break;
          case FieldDefinitionType.RECORD_ID:
            fields[field.apiName] = {
              value: {
                case: "recordId",
                value: values[field.apiName],
              },
            };
            break;
          case FieldDefinitionType.ENUM:
            fields[field.apiName] = {
              value: {
                case: "enumValue",
                value: values[field.apiName],
              },
            };
        }
      }

      const record = await createRecordMutation.mutateAsync({
        record: {
          objectApiName: object.apiName,
          fields,
        },
      });

      await client.invalidateQueries({
        queryKey: createConnectQueryKey(listRecords, {
          objectApiName: object.apiName,
        }),
      });

      if (onRecordCreated) {
        onRecordCreated(record);
      }

      if (createAnother) {
        form.reset();
      } else {
        toast.custom((t) => (
          <Transition
            show={t.visible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5",
              )}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between">
                    <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                      {object.displayNameSingular} created
                    </p>
                    <Link
                      to={`/records/${record.id}`}
                      onClick={() => toast.dismiss(t.id)}
                      className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-stone-600 hover:text-stone-500 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      View
                    </Link>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        ));

        onClose();
      }
    },
  });

  return (
    <SlideOver
      title={`Create ${object.displayNameSingular}`}
      open={open}
      onClose={onClose}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <form.Provider>
            <form>
              <div className="space-y-8">
                {object?.fieldDefinitions
                  ?.filter((fd) => !fd.archived && fd.formula === "")
                  .map((fieldDefinition) => (
                    <form.Field
                      key={fieldDefinition.apiName}
                      name={fieldDefinition.apiName}
                    >
                      {(field) => {
                        switch (fieldDefinition.type) {
                          case FieldDefinitionType.BOOLEAN:
                            return (
                              <FieldValueInputBoolean
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? false}
                                onChange={(e) => field.handleChange(e)}
                              />
                            );
                          case FieldDefinitionType.NUMBER:
                            return (
                              <FieldValueInputNumber
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? 0}
                                onChange={field.handleChange}
                              />
                            );
                          case FieldDefinitionType.STRING:
                            return (
                              <FieldValueInputString
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? ""}
                                onChange={field.handleChange}
                              />
                            );
                          case FieldDefinitionType.TIMESTAMP:
                            return (
                              <FieldValueInputTimestamp
                                fieldDefinition={fieldDefinition}
                                value={field.state.value}
                                onChange={field.handleChange}
                              />
                            );
                          case FieldDefinitionType.USER_ID:
                            return (
                              <FieldValueInputUserId
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? ""}
                                onChange={field.handleChange}
                              />
                            );
                          case FieldDefinitionType.RECORD_ID:
                            return (
                              <FieldValueInputRecordId
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? ""}
                                onChange={field.handleChange}
                              />
                            );
                          case FieldDefinitionType.ENUM:
                            return (
                              <FieldValueInputEnum
                                fieldDefinition={fieldDefinition}
                                value={field.state.value ?? ""}
                                onChange={field.handleChange}
                              />
                            );
                        }
                      }}
                    </form.Field>
                  ))}
              </div>
            </form>
          </form.Provider>
        </div>

        <div className="flex space-x-2 mt-8">
          {showCreateAnother && (
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                setCreateAnother(true);
                form.handleSubmit();
              }}
              iconAfter={
                createAnother &&
                createRecordMutation.isPending && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    className="-mr-0.5 h-4 w-4"
                  />
                )
              }
            >
              Create and add another
            </Button>
          )}

          <Button
            variant="secondary"
            size="md"
            onClick={() => {
              setCreateAnother(false);
              form.handleSubmit();
            }}
            iconAfter={
              !createAnother &&
              createRecordMutation.isPending && (
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  spin
                  className="-mr-0.5 h-4 w-4"
                />
              )
            }
          >
            Create
          </Button>

          <Button variant="secondary" size="md" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </SlideOver>
  );
}
