import React from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

export function Toggle({
  checked,
  onChange,
  className,
}: {
  checked: boolean;
  onChange: (_: boolean) => void;
  className?: string;
}) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={clsx(
        checked ? "bg-stone-600" : "bg-gray-200",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-stone-600 focus:ring-offset-2",
        className,
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={clsx(
          checked ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      />
    </Switch>
  );
}
