import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useMemo, useState } from "react";
import {
  Bars3Icon,
  ChatBubbleLeftEllipsisIcon,
  CubeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@connectrpc/connect-query";
import {
  getUser,
  listObjects,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useHostedPageUrls } from "@propelauth/react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { SearchCommandPalette } from "../SearchCommandPalette";
import posthog from "posthog-js";

export function SideNav() {
  const { data: user } = useQuery(getUser, { id: "me" });
  const { getAccountPageUrl } = useHostedPageUrls();
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const location = useLocation();
  const navigation = [
    ...(posthog.isFeatureEnabled("recent-contacts")
      ? [
          {
            name: "Recent contacts",
            href: "/recent-contacts",
            icon: ChatBubbleLeftEllipsisIcon,
            current: location.pathname === "/recent-contacts",
          },
        ]
      : []),

    {
      name: "Reminders",
      href: "/reminders",
      icon: CheckCircleIcon,
      current: location.pathname === "/reminders",
    },
  ];

  const { data: listObjectsResponse } = useQuery(listObjects, {});

  const objects = useMemo(() => {
    if (!listObjectsResponse?.objects) {
      return [];
    }

    return listObjectsResponse.objects
      .slice(0, 10)
      .map(({ id, apiName, displayNamePlural }) => {
        return {
          id,
          apiName,
          displayNamePlural,
          current: location.pathname === `/objects/${apiName}/records`,
        };
      });
  }, [location, listObjectsResponse]);

  return (
    <>
      <SearchCommandPalette open={searchOpen} setOpen={setSearchOpen} />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="/img/logo-wordmark.svg"
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={clsx(
                                  item.current
                                    ? "bg-gray-50 text-stone-600"
                                    : "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                )}
                              >
                                <item.icon
                                  className={clsx(
                                    item.current
                                      ? "text-stone-600"
                                      : "text-gray-400 group-hover:text-stone-600",
                                    "h-6 w-6 shrink-0",
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Records
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {objects.map((object) => (
                            <li key={object.id}>
                              <Link
                                to={`/objects/${object.apiName}/records`}
                                className={clsx(
                                  object.current
                                    ? "bg-gray-50 text-stone-600"
                                    : "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                )}
                              >
                                <span
                                  className={clsx(
                                    object.current
                                      ? "text-stone-600 border-stone-600"
                                      : "text-gray-400 border-gray-200 group-hover:border-stone-600 group-hover:text-stone-600",
                                    "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white",
                                  )}
                                >
                                  {object.displayNamePlural[0]}
                                </span>
                                <span className="truncate">
                                  {object.displayNamePlural}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="/img/logo-wordmark.svg"
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  <li>
                    <div
                      className={clsx(
                        "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer",
                      )}
                      onClick={() => setSearchOpen(true)}
                    >
                      <MagnifyingGlassIcon
                        className={clsx(
                          "text-gray-400 group-hover:text-stone-600",
                          "h-6 w-6 shrink-0",
                        )}
                        aria-hidden="true"
                      />
                      <span>Search</span>
                      <span className="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                        {navigator.platform === "MacIntel"
                          ? "⌘ + /"
                          : "Ctrl + /"}
                      </span>
                    </div>
                  </li>

                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={clsx(
                          item.current
                            ? "bg-gray-50 text-stone-600"
                            : "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                        )}
                      >
                        <item.icon
                          className={clsx(
                            item.current
                              ? "text-stone-600"
                              : "text-gray-400 group-hover:text-stone-600",
                            "h-6 w-6 shrink-0",
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Records
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {objects.map((object) => (
                    <li key={object.id}>
                      <Link
                        to={`/objects/${object.apiName}/records`}
                        className={clsx(
                          object.current
                            ? "bg-gray-50 text-stone-600"
                            : "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                        )}
                      >
                        <span
                          className={clsx(
                            object.current
                              ? "text-stone-600 border-stone-600"
                              : "text-gray-400 border-gray-200 group-hover:border-stone-600 group-hover:text-stone-600",
                            "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white",
                          )}
                        >
                          {object.displayNamePlural[0]}
                        </span>
                        <span className="truncate">
                          {object.displayNamePlural}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Settings
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <li>
                    <Link
                      to={`/objects`}
                      className={clsx(
                        location.pathname === "/objects"
                          ? "bg-gray-50 text-stone-600"
                          : "text-gray-700 hover:text-stone-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                      )}
                    >
                      <CubeIcon
                        className={clsx(
                          location.pathname === "/objects"
                            ? "text-stone-600"
                            : "text-gray-400 group-hover:text-stone-600",
                          "h-6 w-6 shrink-0",
                        )}
                        aria-hidden="true"
                      />
                      Objects
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <a
                  href={
                    posthog.isFeatureEnabled("disable-user-settings-link")
                      ? "#"
                      : getAccountPageUrl()
                  }
                  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                >
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src={user?.pictureUrl}
                    alt=""
                  />
                  <span className="sr-only">Your profile</span>
                  <div className="flex flex-col overflow-hidden">
                    <span aria-hidden="true">{user?.displayName}</span>
                    <span
                      className="text-gray-500 font-normal truncate"
                      aria-hidden="true"
                    >
                      {user?.email}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900"></div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={user?.pictureUrl}
            alt=""
          />
        </a>
      </div>
    </>
  );
}
