import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { useId } from "react";

export function FieldValueInputBoolean({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: boolean;
  onChange: (_: boolean) => void;
}) {
  const id = useId();
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {fieldDefinition.displayName}
      </label>
      <div className="mt-2">
        <input
          id={id}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-stone-600 focus:ring-stone-600"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
    </div>
  );
}
