import React from "react";
import clsx from "clsx";

export function TextGrid({
  className,
  children,
  columns,
}: {
  className?: string;
  children?: React.ReactNode;
  columns: 3 | 4;
}) {
  return (
    <div
      className={clsx(
        "mt-2 mx-auto grid grid-cols-1 gap-px bg-gray-200",
        columns === 3 && "lg:grid-cols-3",
        columns === 4 && "sm:grid-cols-2 lg:grid-cols-4",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function TextGridCell({
  index,
  className,
  children,
  columns,
}: {
  index: number;
  className?: string;
  children?: React.ReactNode;
  columns: 3 | 4;
}) {
  return (
    <div
      className={clsx(
        "py-2 flex flex-col gap-x-4 gap-y-4 bg-white",
        columns === 3 && index !== 0 && "lg:px-4",
        columns === 4 && (index === 1 || index === 3) && "sm:px-4",
        columns === 4 && index === 2 && "lg:px-4",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function TextGridKeyValue({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return <div className={clsx("", className)}>{children}</div>;
}

export function TextGridHeading({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={clsx("text-sm font-semibold leading-6", className)}>
      {children}
    </div>
  );
}

export function TextGridValue({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={clsx(
        "text-sm leading-none tracking-tight text-gray-900",
        className,
      )}
    >
      {children}
    </div>
  );
}
