import React from "react";
import { PageLayout } from "../../components/ds1/PageLayout";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  updateObject,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { FieldDefinition } from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import { FieldDefinitionForm } from "./components/FieldDefinitionForm";
import { PartialMessage } from "@bufbuild/protobuf";

export function CreateFieldDefinitionPage() {
  const { objectApiName } = useParams();
  const { data: object } = useQuery(getObject, {
    apiName: objectApiName,
  });

  const updateObjectMutation = useMutation(updateObject);
  const navigate = useNavigate();
  const handleSubmit = async (
    fieldDefinition: PartialMessage<FieldDefinition>,
  ) => {
    await updateObjectMutation.mutateAsync({
      object: {
        ...object,
        fieldDefinitions: [...object!.fieldDefinitions, fieldDefinition],
      },
    });

    navigate(`/objects/${object?.apiName}/fields/${fieldDefinition.apiName}`);
  };

  return (
    <PageLayout
      title="Create field"
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: object?.displayNameSingular ?? "",
          href: `/objects/${object?.apiName}`,
        },
        {
          name: "Fields",
        },
        {
          name: "New",
          href: `/objects/${object?.apiName}/fields/new`,
        },
      ]}
    >
      {object && (
        <FieldDefinitionForm
          object={object}
          cancelUrl={`/objects/${object?.apiName}`}
          onSubmit={handleSubmit}
        />
      )}
    </PageLayout>
  );
}
