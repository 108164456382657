import {
  FieldDefinition,
  Record,
} from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { disableQuery, useQuery } from "@connectrpc/connect-query";
import {
  getObject,
  getRecord,
  searchRecords,
} from "../../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { ListboxOptionTextDisplay } from "../../ds1/Listbox";
import { Link } from "react-router-dom";
import { CreateRecordSlideover } from "../../../pages/ListRecordsPage/CreateRecordSlideover";

export function FieldValueInputRecordId({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: string;
  onChange: (_: string) => void;
}) {
  const [query, setQuery] = useState("");

  const { data: searchRecordsResponse } = useQuery(searchRecords, {
    query,
    objectIds: [fieldDefinition.refObjectId],
  });

  const { data: selectedRecord } = useQuery(
    getRecord,
    value ? { id: value } : disableQuery,
  );
  const { data: selectedObject } = useQuery(
    getObject,
    selectedRecord ? { id: selectedRecord.objectId } : disableQuery,
  );
  const selectedRecordDisplay =
    selectedRecord &&
    selectedObject &&
    selectedObject.primaryDisplayFieldDefinitionApiName in selectedRecord.fields
      ? (selectedRecord.fields[
          selectedObject.primaryDisplayFieldDefinitionApiName
        ].value.value as string)
      : selectedRecord?.id;

  const handleRecordCreated = (record: Record) => {
    onChange(record.id);
  };

  return (
    <div>
      <Combobox value={value} onChange={onChange}>
        {({ open }) => (
          <div>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {fieldDefinition.displayName}
            </Combobox.Label>

            <div className="relative mt-2">
              <Combobox.Input
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                onChange={(event) => setQuery(event.target.value)}
                value={open ? query : selectedRecordDisplay}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {searchRecordsResponse?.records.map((record) => (
                    <Combobox.Option
                      key={record.id}
                      value={record.id}
                      className={({ active }) =>
                        clsx(
                          active && "bg-stone-600",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <SingleFieldEditFormRecordIdComboboxTextDisplay
                            active={active}
                            selected={selected}
                            record={record}
                          />
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Combobox>

      <div className="mt-2 text-sm text-gray-500">
        Or create a new{" "}
        <CreateNewLink
          objectId={fieldDefinition.refObjectId}
          onRecordCreated={handleRecordCreated}
        />
      </div>
    </div>
  );
}

function CreateNewLink({
  objectId,
  onRecordCreated,
}: {
  objectId: string;
  onRecordCreated: (_: Record) => void;
}) {
  const [open, setOpen] = useState(false);
  const { data: object } = useQuery(getObject, { id: objectId });

  return (
    <>
      {object && (
        <CreateRecordSlideover
          open={open}
          onClose={() => setOpen(false)}
          onRecordCreated={onRecordCreated}
          object={object}
        />
      )}
      <span
        className="cursor-pointer font-semibold text-stone-600 hover:text-stone-500"
        onClick={() => setOpen(true)}
      >
        {object?.displayNameSingular}
      </span>
    </>
  );
}

function SingleFieldEditFormRecordIdComboboxTextDisplay({
  active,
  selected,
  record,
}: {
  selected: boolean;
  active: boolean;
  record: Record;
}) {
  const { data: object } = useQuery(getObject, { id: record.objectId });
  return (
    <>
      <span
        className={clsx(
          selected ? "font-semibold" : "font-normal",
          active ? "text-white" : "text-gray-900",
          "block truncate",
        )}
      >
        {object && object.primaryDisplayFieldDefinitionApiName in record.fields
          ? (record.fields[object.primaryDisplayFieldDefinitionApiName].value
              .value as string)
          : record.id}
      </span>

      <span>
        <span
          className={clsx(
            selected ? "font-semibold" : "font-normal",
            active ? "text-gray-300" : "text-gray-700",
            "block truncate text-xs",
          )}
        >
          {object &&
          object.secondaryDisplayFieldDefinitionApiName in record.fields
            ? (record.fields[object.secondaryDisplayFieldDefinitionApiName]
                .value.value as string)
            : ""}
        </span>
      </span>
    </>
  );
}
