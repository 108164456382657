import React, { useCallback } from "react";
import { Card } from "../components/Card";
import { useForm } from "@tanstack/react-form";
import pluralize from "pluralize";
import { useMutation } from "@connectrpc/connect-query";
import { createObject } from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { PageLayout } from "../components/ds1/PageLayout";
import { useNavigate } from "react-router";

export function CreateObjectPage() {
  const createObjectMutation = useMutation(createObject);

  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      displayNameSingular: "",
      displayNamePlural: "",
      apiName: "",
    },
    onSubmit: async (values) => {
      const object = await createObjectMutation.mutateAsync({
        object: {
          displayNameSingular: values.displayNameSingular,
          displayNamePlural: values.displayNamePlural,
          apiName: values.apiName,
        },
      });

      navigate(`/objects/${object.apiName}`);
    },
  });

  const handleDisplayNameChange = useCallback(() => {
    const name = form.getFieldValue("displayNameSingular");

    form.setFieldValue("displayNamePlural", pluralize(name));
    form.setFieldValue(
      "apiName",
      pluralize(name)
        .toLowerCase()
        .replace(/[^a-z]+/g, "_"),
    );
  }, [form]);

  return (
    <PageLayout
      title="Create object"
      breadcrumbs={[
        { name: "Objects", href: "/objects" },
        {
          name: "New",
          href: `/objects/new`,
        },
      ]}
    >
      <form.Provider>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <Card>
            <div className="space-y-8">
              <form.Field name="displayNameSingular">
                {(field) => (
                  <div>
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Display Name (Singular)
                    </label>
                    <div className="mt-2">
                      <input
                        name={field.name}
                        id={field.name}
                        value={field.state.value}
                        onChange={(e) => {
                          field.handleChange(e.target.value);
                          handleDisplayNameChange();
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                        placeholder="Widget"
                      />
                    </div>
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="email-description"
                    >
                      A human-friendly name. You can change this at any time.
                    </p>
                  </div>
                )}
              </form.Field>

              <form.Field name="displayNamePlural">
                {(field) => (
                  <div>
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Display Name (Plural)
                    </label>
                    <div className="mt-2">
                      <input
                        name={field.name}
                        id={field.name}
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                        placeholder="Widgets"
                      />
                    </div>
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="email-description"
                    >
                      The plural version of the human-friendly name. You can
                      change this at any time.
                    </p>
                  </div>
                )}
              </form.Field>

              <form.Field name="apiName">
                {(field) => (
                  <div>
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      API Name
                    </label>
                    <div className="mt-2">
                      <input
                        name={field.name}
                        id={field.name}
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                        placeholder="Widgets"
                      />
                    </div>
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="email-description"
                    >
                      A name that automations can use to refer to the object.
                      You can't change this after creation.
                    </p>
                  </div>
                )}
              </form.Field>
            </div>
          </Card>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <a
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              href="/objects"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
            >
              Save
            </button>
          </div>
        </form>
      </form.Provider>
    </PageLayout>
  );
}
