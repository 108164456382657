import { useQuery } from "@connectrpc/connect-query";
import { getUser } from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import React from "react";

export function UserCell({ userId }: { userId: string }) {
  const { data: user } = useQuery(getUser, {
    id: userId,
  });

  return (
    <div className="flex items-center gap-x-1">
      <img
        className="h-4 w-4 rounded-full"
        alt={user?.displayName}
        src={user?.pictureUrl}
      />
      <span className="text-xs ml-1">{user?.displayName}</span>
    </div>
  );
}
