// @generated by protoc-gen-connect-query v1.0.0 with parameter "target=ts"
// @generated from file proto/okapicrm/v1/okapicrm.proto (package okapicrm.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_DIRECTORY_MATCH

import { MethodKind } from "@bufbuild/protobuf";
import { ArchiveRecordRequest, CreateGoogleConnectionRequest, CreateGoogleConnectionResponse, CreateMicrosoftConnectionRequest, CreateMicrosoftConnectionResponse, CreateObjectRequest, CreateRecordRequest, DeleteRecordRequest, EnableWebhookSettingsRequest, EnableWebhookSettingsResponse, ExportRecordsRequest, ExportRecordsResponse, GetGmailSyncSettingsRequest, GetObjectRequest, GetOutlookSyncSettingsRequest, GetRecordRequest, GetUserRequest, GetVizzlyIdentityConfigRequest, GetWebhookSettingsRequest, GmailSyncSettings, ListEmailFollowupTasksRequest, ListEmailFollowupTasksResponse, ListObjectsRequest, ListObjectsResponse, ListRecentContactsRequest, ListRecentContactsResponse, ListRecordRevisionsRequest, ListRecordRevisionsResponse, ListRecordsRequest, ListRecordsResponse, ListReferringFieldDefinitionsRequest, ListReferringFieldDefinitionsResponse, ListReferringRecordsRequest, ListReferringRecordsResponse, ListUsersRequest, ListUsersResponse, MarkEmailFollowupTaskDoneRequest, MarkEmailFollowupTaskDoneResponse, Object$, OutlookSyncSettings, Record, SearchRecordsRequest, SearchRecordsResponse, StarEmailFollowupTaskRequest, StarEmailFollowupTaskResponse, UnarchiveRecordRequest, UpdateObjectRequest, UpdateRecordRequest, UpsertRecordRequest, User, VizzlyIdentityConfig, WebhookSettings } from "./okapicrm_pb.js";

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListUsers
 */
export const listUsers = { 
  localName: "listUsers",
  name: "ListUsers",
  kind: MethodKind.Unary,
  I: ListUsersRequest,
  O: ListUsersResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetUser
 */
export const getUser = { 
  localName: "getUser",
  name: "GetUser",
  kind: MethodKind.Unary,
  I: GetUserRequest,
  O: User,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListObjects
 */
export const listObjects = { 
  localName: "listObjects",
  name: "ListObjects",
  kind: MethodKind.Unary,
  I: ListObjectsRequest,
  O: ListObjectsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetObject
 */
export const getObject = { 
  localName: "getObject",
  name: "GetObject",
  kind: MethodKind.Unary,
  I: GetObjectRequest,
  O: Object$,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.CreateObject
 */
export const createObject = { 
  localName: "createObject",
  name: "CreateObject",
  kind: MethodKind.Unary,
  I: CreateObjectRequest,
  O: Object$,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.UpdateObject
 */
export const updateObject = { 
  localName: "updateObject",
  name: "UpdateObject",
  kind: MethodKind.Unary,
  I: UpdateObjectRequest,
  O: Object$,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListReferringFieldDefinitions
 */
export const listReferringFieldDefinitions = { 
  localName: "listReferringFieldDefinitions",
  name: "ListReferringFieldDefinitions",
  kind: MethodKind.Unary,
  I: ListReferringFieldDefinitionsRequest,
  O: ListReferringFieldDefinitionsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListRecords
 */
export const listRecords = { 
  localName: "listRecords",
  name: "ListRecords",
  kind: MethodKind.Unary,
  I: ListRecordsRequest,
  O: ListRecordsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.SearchRecords
 */
export const searchRecords = { 
  localName: "searchRecords",
  name: "SearchRecords",
  kind: MethodKind.Unary,
  I: SearchRecordsRequest,
  O: SearchRecordsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetRecord
 */
export const getRecord = { 
  localName: "getRecord",
  name: "GetRecord",
  kind: MethodKind.Unary,
  I: GetRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.CreateRecord
 */
export const createRecord = { 
  localName: "createRecord",
  name: "CreateRecord",
  kind: MethodKind.Unary,
  I: CreateRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.UpdateRecord
 */
export const updateRecord = { 
  localName: "updateRecord",
  name: "UpdateRecord",
  kind: MethodKind.Unary,
  I: UpdateRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.DeleteRecord
 */
export const deleteRecord = { 
  localName: "deleteRecord",
  name: "DeleteRecord",
  kind: MethodKind.Unary,
  I: DeleteRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.UpsertRecord
 */
export const upsertRecord = { 
  localName: "upsertRecord",
  name: "UpsertRecord",
  kind: MethodKind.Unary,
  I: UpsertRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ArchiveRecord
 */
export const archiveRecord = { 
  localName: "archiveRecord",
  name: "ArchiveRecord",
  kind: MethodKind.Unary,
  I: ArchiveRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.UnarchiveRecord
 */
export const unarchiveRecord = { 
  localName: "unarchiveRecord",
  name: "UnarchiveRecord",
  kind: MethodKind.Unary,
  I: UnarchiveRecordRequest,
  O: Record,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ExportRecords
 */
export const exportRecords = { 
  localName: "exportRecords",
  name: "ExportRecords",
  kind: MethodKind.Unary,
  I: ExportRecordsRequest,
  O: ExportRecordsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListRecordRevisions
 */
export const listRecordRevisions = { 
  localName: "listRecordRevisions",
  name: "ListRecordRevisions",
  kind: MethodKind.Unary,
  I: ListRecordRevisionsRequest,
  O: ListRecordRevisionsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListReferringRecords
 */
export const listReferringRecords = { 
  localName: "listReferringRecords",
  name: "ListReferringRecords",
  kind: MethodKind.Unary,
  I: ListReferringRecordsRequest,
  O: ListReferringRecordsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.CreateGoogleConnection
 */
export const createGoogleConnection = { 
  localName: "createGoogleConnection",
  name: "CreateGoogleConnection",
  kind: MethodKind.Unary,
  I: CreateGoogleConnectionRequest,
  O: CreateGoogleConnectionResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.CreateMicrosoftConnection
 */
export const createMicrosoftConnection = { 
  localName: "createMicrosoftConnection",
  name: "CreateMicrosoftConnection",
  kind: MethodKind.Unary,
  I: CreateMicrosoftConnectionRequest,
  O: CreateMicrosoftConnectionResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetGmailSyncSettings
 */
export const getGmailSyncSettings = { 
  localName: "getGmailSyncSettings",
  name: "GetGmailSyncSettings",
  kind: MethodKind.Unary,
  I: GetGmailSyncSettingsRequest,
  O: GmailSyncSettings,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetOutlookSyncSettings
 */
export const getOutlookSyncSettings = { 
  localName: "getOutlookSyncSettings",
  name: "GetOutlookSyncSettings",
  kind: MethodKind.Unary,
  I: GetOutlookSyncSettingsRequest,
  O: OutlookSyncSettings,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListEmailFollowupTasks
 */
export const listEmailFollowupTasks = { 
  localName: "listEmailFollowupTasks",
  name: "ListEmailFollowupTasks",
  kind: MethodKind.Unary,
  I: ListEmailFollowupTasksRequest,
  O: ListEmailFollowupTasksResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.MarkEmailFollowupTaskDone
 */
export const markEmailFollowupTaskDone = { 
  localName: "markEmailFollowupTaskDone",
  name: "MarkEmailFollowupTaskDone",
  kind: MethodKind.Unary,
  I: MarkEmailFollowupTaskDoneRequest,
  O: MarkEmailFollowupTaskDoneResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.StarEmailFollowupTask
 */
export const starEmailFollowupTask = { 
  localName: "starEmailFollowupTask",
  name: "StarEmailFollowupTask",
  kind: MethodKind.Unary,
  I: StarEmailFollowupTaskRequest,
  O: StarEmailFollowupTaskResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.ListRecentContacts
 */
export const listRecentContacts = { 
  localName: "listRecentContacts",
  name: "ListRecentContacts",
  kind: MethodKind.Unary,
  I: ListRecentContactsRequest,
  O: ListRecentContactsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetWebhooksSettings
 */
export const getWebhooksSettings = { 
  localName: "getWebhooksSettings",
  name: "GetWebhooksSettings",
  kind: MethodKind.Unary,
  I: GetWebhookSettingsRequest,
  O: WebhookSettings,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.EnableWebhooksSettings
 */
export const enableWebhooksSettings = { 
  localName: "enableWebhooksSettings",
  name: "EnableWebhooksSettings",
  kind: MethodKind.Unary,
  I: EnableWebhookSettingsRequest,
  O: EnableWebhookSettingsResponse,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;

/**
 * @generated from rpc okapicrm.v1.OkapiCRMService.GetVizzlyIdentityConfig
 */
export const getVizzlyIdentityConfig = { 
  localName: "getVizzlyIdentityConfig",
  name: "GetVizzlyIdentityConfig",
  kind: MethodKind.Unary,
  I: GetVizzlyIdentityConfigRequest,
  O: VizzlyIdentityConfig,
  service: {
    typeName: "okapicrm.v1.OkapiCRMService"
  }
} as const;
