import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Card } from "../../components/Card";
import {
  createConnectQueryKey,
  disableQuery,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  archiveRecord,
  deleteRecord,
  getObject,
  getRecord,
  getUser,
  listRecordRevisions,
  listReferringFieldDefinitions,
  listReferringRecords,
  unarchiveRecord,
  updateRecord,
} from "../../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { useParams } from "react-router";
import {
  ArrowRightIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { PageLayout } from "../../components/ds1/PageLayout";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  BuiltinIntegration,
  FieldDefinition,
  Object$,
  Record,
  RecordRevision,
  UpdateRecordRequest,
} from "../../gen/proto/okapicrm/v1/okapicrm_pb";
import clsx from "clsx";
import { Tabs } from "../../components/ds1/Tabs";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { InlineFieldValue } from "./InlineFieldValue";
import { PartialMessage } from "@bufbuild/protobuf";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleEnvelope, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { ExclamationCircleIcon } from "@heroicons/react/16/solid";

export function ViewRecordPage() {
  const { id } = useParams();
  const { data: record } = useQuery(getRecord, {
    id,
  });
  const { data: object } = useQuery(
    getObject,
    {
      id: record?.objectId,
    },
    {
      enabled: !!record,
    },
  );

  const unarchiveRecordMutation = useMutation(unarchiveRecord);

  const client = useQueryClient();
  const handleUnarchiveRecord = useCallback(async () => {
    await unarchiveRecordMutation.mutateAsync({
      id,
    });
    await client.invalidateQueries({
      queryKey: createConnectQueryKey(getRecord, { id: record!.id }),
    });
  }, [id, record, client, unarchiveRecordMutation]);

  const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
  const [showArchiveRecordModal, setShowArchiveRecordModal] = useState(false);

  const { data: listReferringFieldDefinitionsResponse } = useQuery(
    listReferringFieldDefinitions,
    record
      ? {
          objectId: record.objectId,
        }
      : disableQuery,
  );

  const primaryDisplayName = useMemo(() => {
    if (object?.primaryDisplayFieldDefinitionApiName) {
      return (
        (record?.fields?.[object.primaryDisplayFieldDefinitionApiName]?.value
          ?.value as string) ??
        record?.id ??
        ""
      );
    }
    return record?.id ?? "";
  }, [object, record]);

  const icon = useMemo(() => {
    if (object?.iconDisplayFieldDefinitionApiName) {
      return (
        (record?.fields?.[object.iconDisplayFieldDefinitionApiName]?.value
          ?.value as string) ?? ""
      );
    }
    return "";
  }, [object, record]);

  const referringFieldDefinitions =
    listReferringFieldDefinitionsResponse?.referringFieldDefinitions?.filter(
      (rfd) => !rfd.object!.archived,
    ) ?? [];

  const relatedRecordsTabNames = useMemo(() => {
    return referringFieldDefinitions.map((referringFieldDefinition, i) => {
      // is there another referring field definition from the same object?
      if (
        referringFieldDefinitions.some(
          (rfd, j) =>
            rfd.object!.id === referringFieldDefinition.object!.id && i !== j,
        )
      ) {
        // include the field definition name
        return `${referringFieldDefinition.object!.displayNamePlural} (${
          referringFieldDefinition.fieldDefinition!.displayName
        })`;
      } else {
        // object name is enough
        return referringFieldDefinition.object!.displayNamePlural;
      }
    });
  }, [listReferringFieldDefinitionsResponse]);

  const [
    currentReferringFieldDefinitionTab,
    setCurrentReferringFieldDefinitionTab,
  ] = useState(0);

  const { data: listReferringRecordsPages } = useInfiniteQuery(
    listReferringRecords,
    referringFieldDefinitions[currentReferringFieldDefinitionTab]
      ?.fieldDefinition?.id
      ? {
          recordId: id,
          fieldDefinitionId:
            referringFieldDefinitions[currentReferringFieldDefinitionTab]
              .fieldDefinition!.id,
          pageToken: "",
        }
      : disableQuery,
    {
      getNextPageParam: (res) => res.nextPageToken || undefined,
      pageParamKey: "pageToken",
    },
  );

  const updateRecordMutation = useMutation(updateRecord);
  const handleFieldEdit = async (req: PartialMessage<UpdateRecordRequest>) => {
    await updateRecordMutation.mutateAsync(req);
    await client.invalidateQueries({
      queryKey: createConnectQueryKey(getRecord, { id }),
    });
  };

  return (
    <PageLayout
      title={primaryDisplayName}
      titleDisplay={
        <div className="inline-flex gap-x-4 items-center">
          {icon && (
            <img
              src={icon}
              className="h-6 w-6 rounded-sm border border-gray-100"
            />
          )}
          {primaryDisplayName}
        </div>
      }
      breadcrumbs={[
        {
          name: object?.displayNamePlural ?? "",
          href: `/objects/${object?.apiName}/records`,
        },
        {
          name: primaryDisplayName,
          href: `/records/${record?.id}`,
        },
      ]}
      actions={
        record?.archived ? (
          <>
            <button
              type="button"
              className="rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-stone-500 hover:bg-stone-700"
              onClick={() => setShowDeleteRecordModal(true)}
            >
              Delete
            </button>

            <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
              onClick={handleUnarchiveRecord}
            >
              Unarchive
            </button>
          </>
        ) : (
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-stone-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
            onClick={() => setShowArchiveRecordModal(true)}
          >
            Archive
          </button>
        )
      }
    >
      {record && object && (
        <DeleteRecordModal
          open={showDeleteRecordModal}
          object={object}
          record={record}
          onClose={() => setShowDeleteRecordModal(false)}
        />
      )}

      {record && object && (
        <ArchiveRecordModal
          open={showArchiveRecordModal}
          object={object}
          record={record}
          onClose={() => setShowArchiveRecordModal(false)}
        />
      )}

      {object && record?.deleted ? (
        <RecordDeletedAlert object={object} record={record} />
      ) : (
        <>
          {record?.archived && (
            <div className="rounded-md bg-gray-50 p-4 border-gray-200 border-2 mb-8">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-gray-700">
                    This {object?.displayNameSingular} is archived. You can
                    permanently delete it, but you cannot otherwise modify it
                    without unarchiving it first.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
              <h2 className="text-xl font-semibold text-gray-900">
                {object?.displayNameSingular} details
                <span className="text-gray-500">
                  {" "}
                  ({object?.fieldDefinitions?.length})
                </span>
              </h2>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Fields on this {object?.displayNameSingular} and their values.
              </p>
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                {object?.fieldDefinitions
                  .filter((fd) => !fd.archived)
                  .map((fieldDefinition) => (
                    <div
                      key={fieldDefinition.id}
                      className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                    >
                      <dt className="text-sm leading-6 font-medium text-gray-900">
                        {fieldDefinition.displayName}
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {record &&
                          (record.formulaErrors[fieldDefinition.apiName] ? (
                            <div className="flex items-center gap-x-2">
                              <ExclamationCircleIcon className="text-red-800 h-4 w-4" />
                              <span>
                                <span className="text-red-800 text-xs font-semibold">
                                  Formula error:{" "}
                                </span>
                                <span className="text-red-800 text-xs">
                                  {
                                    record.formulaErrors[
                                      fieldDefinition.apiName
                                    ].message
                                  }
                                </span>
                              </span>
                            </div>
                          ) : (
                            <InlineFieldValue
                              editable
                              size="sm"
                              object={object}
                              record={record}
                              fieldDefinition={fieldDefinition}
                              onSubmit={handleFieldEdit}
                              noValueAppearance="italic"
                            />
                          ))}
                      </dd>
                    </div>
                  ))}
              </dl>
            </div>
          </div>
        </>
      )}

      {referringFieldDefinitions[currentReferringFieldDefinitionTab]
        ?.fieldDefinition?.id && (
        <Card className="mt-8">
          <h2 className="text-xl font-semibold text-gray-900">
            Related records
          </h2>

          <Tabs
            tabs={relatedRecordsTabNames}
            current={currentReferringFieldDefinitionTab}
            onChange={setCurrentReferringFieldDefinitionTab}
          />

          <div className="overflow-x-scroll">
            <table className="w-full table-fixed divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th className="w-20"></th>
                  {referringFieldDefinitions[currentReferringFieldDefinitionTab]
                    .object!.fieldDefinitions.filter((fd) => !fd.archived)
                    .map((fieldDefinition) => (
                      <th key={fieldDefinition.id} className="h-12 w-72">
                        {fieldDefinition.displayName}
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {listReferringRecordsPages?.pages.flatMap((page) =>
                  page.records.map((record) => (
                    <tr key={record.id} className="divide-x divide-gray-200">
                      <td className="h-8 text-center">
                        <Link
                          className="text-sm font-medium text-stone-600 hover:text-stone-900"
                          to={`/records/${record.id}`}
                        >
                          View
                        </Link>
                      </td>
                      {referringFieldDefinitions[
                        currentReferringFieldDefinitionTab
                      ]
                        .object!.fieldDefinitions.filter((fd) => !fd.archived)
                        .map((fieldDefinition) => (
                          <td key={fieldDefinition.id}>
                            <div className="h-8 max-h-8 px-2 overflow-hidden">
                              <InlineFieldValue
                                size="sm"
                                object={
                                  referringFieldDefinitions[
                                    currentReferringFieldDefinitionTab
                                  ].object!
                                }
                                record={record}
                                fieldDefinition={fieldDefinition}
                                noValueAppearance="blank"
                                editable
                                onSubmit={handleFieldEdit}
                              />
                            </div>
                          </td>
                        ))}
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          </div>
        </Card>
      )}

      {object && <RevisionHistoryCard object={object} recordId={id!} />}
    </PageLayout>
  );
}

function RecordDeletedAlert({
  object,
  record,
}: {
  object: Object$;
  record: Record;
}) {
  return (
    <div className="rounded-md bg-gray-50 p-4 border-gray-200 border-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-gray-700">
            This {object.displayNameSingular} is deleted. Its UUID was{" "}
            {record.id}. No further information is available.
          </p>
        </div>
      </div>
    </div>
  );
}

function DeleteRecordModal({
  open,
  object,
  record,
  onClose,
}: {
  open: boolean;
  object: Object$;
  record: Record;
  onClose: () => void;
}) {
  const cancelButtonRef = useRef(null);
  const deleteRecordMutation = useMutation(deleteRecord);
  const handleDeleteRecord = useCallback(async () => {
    await deleteRecordMutation.mutateAsync({ id: record.id });
    onClose();
  }, [record, onClose, deleteRecordMutation]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Permamently delete {object?.displayNameSingular}?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this{" "}
                        {object?.displayNameSingular}? This cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={handleDeleteRecord}
                  >
                    Delete Record
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function ArchiveRecordModal({
  open,
  object,
  record,
  onClose,
}: {
  open: boolean;
  object: Object$;
  record: Record;
  onClose: () => void;
}) {
  const cancelButtonRef = useRef(null);
  const archiveRecordMutation = useMutation(archiveRecord);
  const client = useQueryClient();
  const handleArchiveRecord = useCallback(async () => {
    await archiveRecordMutation.mutateAsync({ id: record.id });
    await client.invalidateQueries({
      queryKey: createConnectQueryKey(getRecord, { id: record.id }),
    });
    onClose();
  }, [record, archiveRecordMutation, client, onClose]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Archive {object?.displayNameSingular}?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to archive this{" "}
                        {object?.displayNameSingular}?
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Any fields on other records that point to this{" "}
                        {object?.displayNameSingular} will be cleared. You can
                        unarchive this {object?.displayNameSingular} later, but
                        those cleared fields will not automatically be restored.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={handleArchiveRecord}
                  >
                    Archive Record
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function RevisionHistoryCard({
  object,
  recordId,
}: {
  object: Object$;
  recordId: string;
}) {
  const {
    data: listRecordRevisionsResponse,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    listRecordRevisions,
    {
      recordId,
      pageToken: "",
    },
    {
      getNextPageParam: (res) => res.nextPageToken || undefined,
      pageParamKey: "pageToken",
    },
  );

  const recordRevisions = listRecordRevisionsResponse?.pages.flatMap(
    (p) => p.recordRevisions,
  );

  // We show a revision without a next as "created", but that isn't correct if it's just the last on the page. So we hide the last revision unless we've finished paginating.
  const displayRecordRevisions = hasNextPage
    ? recordRevisions?.slice(0, recordRevisions?.length - 1)
    : recordRevisions;

  return (
    <Card className="mt-8">
      <h2 className="text-xl font-semibold text-gray-900">Revision history</h2>
      <ul role="list" className="space-y-6 mt-4">
        {recordRevisions &&
          displayRecordRevisions?.map((revision, index) => (
            <RecordRevisionActivityFeedItem
              key={revision.id}
              object={object}
              recordRevision={revision}
              previousRecordRevision={recordRevisions[index + 1]}
              isLastShown={index === displayRecordRevisions.length - 1}
            />
          ))}
      </ul>

      {hasNextPage && (
        <button
          type="button"
          className="mt-4 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => fetchNextPage()}
        >
          Show older revisions
        </button>
      )}
    </Card>
  );
}

function RecordRevisionActivityFeedItem({
  object,
  recordRevision,
  previousRecordRevision,
  isLastShown,
}: {
  object: Object$;
  recordRevision: RecordRevision;
  previousRecordRevision?: RecordRevision;
  isLastShown: boolean;
}) {
  const { data: user } = useQuery(
    getUser,
    recordRevision.createdBy?.actor?.case === "userId"
      ? {
          id: recordRevision.createdBy!.actor.value,
        }
      : disableQuery,
  );

  const actorDisplayName = useMemo(() => {
    if (recordRevision?.createdBy?.actor?.case === "userId") {
      return user?.displayName;
    }
    if (
      recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
      recordRevision?.createdBy?.actor?.value === BuiltinIntegration.GMAIL_V1
    ) {
      return "Okapi GMail Sync";
    }
    if (
      recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
      recordRevision?.createdBy?.actor?.value === BuiltinIntegration.OUTLOOK_V1
    ) {
      return "Okapi Outlook Sync";
    }
    if (
      recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
      recordRevision?.createdBy?.actor?.value === BuiltinIntegration.LINKEDIN_V1
    ) {
      return "Okapi LinkedIn Sync";
    }
  }, [recordRevision, user]);

  const modifiedFields = useMemo(() => {
    if (!previousRecordRevision || !object) {
      return [];
    }

    const res: FieldDefinition[] = [];
    for (const k of Object.keys(recordRevision.record!.fields)) {
      // todo is a less jank solution appropriate here?
      if (
        JSON.stringify(recordRevision.record!.fields[k]) !==
        JSON.stringify(previousRecordRevision.record!.fields[k])
      ) {
        res.push(object.fieldDefinitions!.find((fd) => fd.apiName === k)!);
      }
    }

    return res;
  }, [object, recordRevision, previousRecordRevision]);

  const activityKind = useMemo(() => {
    if (!previousRecordRevision) {
      return "created" as const;
    }
    if (
      recordRevision.record!.archived &&
      !previousRecordRevision.record!.archived
    ) {
      return "archived" as const;
    }
    if (
      !recordRevision.record!.archived &&
      previousRecordRevision.record!.archived
    ) {
      return "unarchived" as const;
    }
    return "edited" as const;
  }, [recordRevision, previousRecordRevision]);

  return (
    <li className="relative flex gap-x-4">
      <div
        className={clsx(
          isLastShown ? "h-6" : "-bottom-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        {recordRevision?.createdBy?.actor?.case === "userId" && (
          <img alt="" className="h-4 w-4 rounded-full" src={user?.pictureUrl} />
        )}

        {recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
          recordRevision?.createdBy?.actor?.value ===
            BuiltinIntegration.GMAIL_V1 && (
            <FontAwesomeIcon
              icon={faCircleEnvelope}
              className="h-4 w-4 rounded-full text-stone-500"
            />
          )}

        {recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
          recordRevision?.createdBy?.actor?.value ===
            BuiltinIntegration.OUTLOOK_V1 && (
            <FontAwesomeIcon
              icon={faCircleEnvelope}
              className="h-4 w-4 rounded-full text-stone-500"
            />
          )}

        {recordRevision?.createdBy?.actor?.case === "builtinIntegration" &&
          recordRevision?.createdBy?.actor?.value ===
            BuiltinIntegration.LINKEDIN_V1 && (
            <FontAwesomeIcon
              icon={faLinkedin}
              className="h-4 w-4 rounded-full text-stone-500"
            />
          )}

        {/*<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />*/}
      </div>

      <div className="flex-auto py-0.5">
        <Disclosure as="div">
          {({ open }) => (
            <>
              <div className="text-xs leading-5 text-gray-500 flex items-center">
                <p>
                  {activityKind === "created" && (
                    <>
                      <span className="font-medium text-gray-900">
                        {actorDisplayName}
                      </span>{" "}
                      created the record
                    </>
                  )}

                  {activityKind === "archived" && (
                    <>
                      <span className="font-medium text-gray-900">
                        {actorDisplayName}
                      </span>{" "}
                      archived the record
                    </>
                  )}

                  {activityKind === "unarchived" && (
                    <>
                      <span className="font-medium text-gray-900">
                        {actorDisplayName}
                      </span>{" "}
                      unarchived the record
                    </>
                  )}

                  {activityKind === "edited" && (
                    <>
                      <span className="font-medium text-gray-900">
                        {actorDisplayName}
                      </span>{" "}
                      edited{" "}
                      <span className="font-medium text-gray-700">
                        {modifiedFields.map((fd) => fd.displayName).join(", ")}
                      </span>
                    </>
                  )}
                </p>

                {(activityKind === "created" || activityKind === "edited") && (
                  <Disclosure.Button className="ml-1">
                    <ChevronRightIcon
                      className={clsx(
                        "text-gray-500 h-4 w-4",
                        open && "rotate-90",
                      )}
                    />
                  </Disclosure.Button>
                )}
              </div>

              {open && (
                <div className="mt-2">
                  {previousRecordRevision
                    ? modifiedFields.map((fd) => (
                        <div key={fd.id} className="flex items-center gap-4">
                          <div className="flex-1">
                            <InlineFieldValue
                              size="xs"
                              object={object}
                              record={previousRecordRevision.record!}
                              fieldDefinition={fd}
                              noValueAppearance="italic"
                            />
                          </div>

                          <ArrowRightIcon className="h-4 w-4 text-gray-500 flex-none" />

                          <div className="flex-1">
                            <InlineFieldValue
                              size="xs"
                              object={object}
                              record={recordRevision.record!}
                              fieldDefinition={fd}
                              noValueAppearance="italic"
                            />
                          </div>
                        </div>
                      ))
                    : Object.entries(recordRevision.record!.fields).map(
                        ([apiName]) => (
                          <div
                            key={apiName}
                            className="flex items-center gap-4"
                          >
                            <div className="text-xs font-medium">
                              {
                                object.fieldDefinitions!.find(
                                  (fd) => fd.apiName === apiName,
                                )!.displayName
                              }
                              :
                            </div>
                            <InlineFieldValue
                              size="xs"
                              object={object}
                              record={recordRevision.record!}
                              fieldDefinition={
                                object.fieldDefinitions!.find(
                                  (fd) => fd.apiName === apiName,
                                )!
                              }
                              noValueAppearance="italic"
                            />
                          </div>
                        ),
                      )}
                </div>
              )}
            </>
          )}
        </Disclosure>
      </div>

      <span className="flex-none py-0.5 text-xs leading-5 text-gray-500">
        {recordRevision?.createTime &&
          moment(recordRevision.createTime.toDate()).fromNow()}
      </span>
    </li>
  );
}
