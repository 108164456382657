import { SideNav } from "./SideNav";
import { Outlet } from "react-router";
import React from "react";

export function Page() {
  return (
    <>
      <SideNav />
      <Outlet />
    </>
  );
}
