import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import posthog from "posthog-js";

posthog.init("phc_MSMcP3c9ZGxX16MXn3k4QbJEqdh9c55NXKk8pviqc6P", {
  api_host: "https://app.posthog.com",
});

const root = createRoot(document.getElementById("react-root")!);
root.render(<App />);
