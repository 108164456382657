import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { useId } from "react";

export function FieldValueInputNumber({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: number;
  onChange: (_: number) => void;
}) {
  const id = useId();
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {fieldDefinition.displayName}
      </label>
      <div className="mt-2">
        <input
          id={id}
          type="number"
          step="any"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      </div>
    </div>
  );
}
