import { PageLayout } from "../components/ds1/PageLayout";
import React from "react";
import Vizzly from "@vizzly/dashboard";
import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@connectrpc/connect-query";
import { getVizzlyIdentityConfig } from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { VIZZLY_QUERY_ENGINE_ENDPOINT } from "../config";

export function ReportsPage() {
  const { accessToken } = useAuthInfo();
  const { data: vizzlyIdentityConfig } = useQuery(getVizzlyIdentityConfig, {});

  return (
    <PageLayout
      title="Reports"
      breadcrumbs={[{ name: "Reports", href: "/reports" }]}
    >
      {vizzlyIdentityConfig && (
        <Vizzly.Dashboard
          queryEngineEndpoint={{
            endpoint: VIZZLY_QUERY_ENGINE_ENDPOINT,
            customHeaders: () => ({
              "Auth-Remote-Config-Token": accessToken!,
            }),
          }}
          identity={async () => {
            return {
              dataAccessToken: vizzlyIdentityConfig.dataAccessToken,
              dashboardAccessToken: vizzlyIdentityConfig.dashboardAccessToken,
            };
          }}
        />
      )}
    </PageLayout>
  );
}
