import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { useId } from "react";
import ReactDatePicker from "react-datepicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export function FieldValueInputTimestamp({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: Date;
  onChange: (_: Date) => void;
}) {
  const id = useId();
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {fieldDefinition.displayName}
      </label>
      <div className="mt-2">
        <DateTimePicker
          value={value ? dayjs(value) : undefined}
          onChange={(newValue) => {
            if (newValue) {
              onChange(newValue.toDate());
            }
          }}
        />
      </div>
    </div>
  );
}
