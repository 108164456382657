import React, { MouseEventHandler, ReactNode } from "react";
import clsx from "clsx";

export function Button({
  variant,
  size,
  className,
  iconBefore,
  iconAfter,
  onClick,
  children,
}: {
  variant: "primary" | "secondary" | "soft";
  size: "sm" | "md" | "lg";
  className?: string;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}) {
  return (
    <button
      type="button"
      className={clsx(
        "inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
        variant === "primary" &&
          "bg-stone-600 text-white hover:bg-stone-500 focus-visible:outline-stone-600",
        variant === "secondary" &&
          "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
        variant === "soft" &&
          "bg-stone-50 text-stone-600 shadow-sm hover:bg-stone-100",
        className,
      )}
      onClick={onClick}
    >
      {iconBefore}
      {children}
      {iconAfter}
    </button>
  );
}

//        className="rounded-md  px-2.5 py-1.5 text-sm font-semibold  shadow-sm "
