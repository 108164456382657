import { FieldDefinition } from "../../../gen/proto/okapicrm/v1/okapicrm_pb";
import React, { useEffect, useId, useRef } from "react";

export function FieldValueInputString({
  fieldDefinition,
  value,
  onChange,
}: {
  fieldDefinition: FieldDefinition;
  value: string;
  onChange: (_: string) => void;
}) {
  const id = useId();
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.style.height = "0px";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [ref, value]);

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {fieldDefinition.displayName}
      </label>
      <div className="mt-2">
        <textarea
          ref={ref}
          id={id}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
}
