import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export function ListboxLabel({ children }: { children?: React.ReactNode }) {
  return (
    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
      {children}
    </Listbox.Label>
  );
}

export function ListboxContents({ children }: { children?: React.ReactNode }) {
  return <div className="relative mt-2">{children}</div>;
}

export function ListboxButton({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <Listbox.Button
      className={clsx(
        "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6",
        className,
      )}
    >
      <span className="block truncate">{children}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </Listbox.Button>
  );
}

export function ListboxOptions({
  show,
  children,
}: {
  show: boolean;
  children?: React.ReactNode;
}) {
  return (
    <Transition
      show={show}
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {children}
      </Listbox.Options>
    </Transition>
  );
}

export function ListboxOption({
  value,
  children,
}: {
  value: any;
  children: (_: { selected: boolean; active: boolean }) => React.ReactElement;
}) {
  return (
    <Listbox.Option
      className={({ active }) =>
        clsx(
          active ? "bg-stone-600 text-white" : "text-gray-900",
          "relative cursor-default select-none py-2 pl-3 pr-9",
        )
      }
      value={value}
    >
      {({ selected, active }) => children({ selected, active })}
    </Listbox.Option>
  );
}

export function ListboxOptionTextDisplay({
  selected,
  className,
  children,
}: {
  selected: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <span
      className={clsx(
        selected ? "font-semibold" : "font-normal",
        "block truncate",
        className,
      )}
    >
      {children}
    </span>
  );
}

export function ListboxOptionCheckIcon({ active }: { active: boolean }) {
  return (
    <span
      className={clsx(
        active ? "text-white" : "text-stone-600",
        "absolute inset-y-0 right-0 flex items-center pr-4",
      )}
    >
      <CheckIcon className="h-5 w-5" aria-hidden="true" />
    </span>
  );
}
