import React from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@connectrpc/connect-query";
import { createGoogleConnection } from "../gen/proto/okapicrm/v1/okapicrm-OkapiCRMService_connectquery";
import { PageLayout } from "../components/ds1/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Card } from "../components/Card";

const clientID =
  "332155586577-2vfn8a4bk0882vfg3o6f2tasmtuaufsl.apps.googleusercontent.com";

export function GoogleOAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const { isLoading, data } = useQuery(createGoogleConnection, {
    code: searchParams.get("code") ?? undefined,
  });

  return (
    <PageLayout title="Connecting Google" breadcrumbs={[]}>
      <Card>
        {isLoading && (
          <FontAwesomeIcon icon={faSpinnerThird} spin className="h-4 w-4" />
        )}

        {data && (
          <p className="text-sm text-gray-700 mb-4">
            Successfully connected your Google account with Okapi.
          </p>
        )}
      </Card>
    </PageLayout>
  );

  return <div>{JSON.stringify(data)}</div>;
}
