// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file proto/okapicrm/v1/okapicrm.proto (package okapicrm.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_DIRECTORY_MATCH

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum okapicrm.v1.FieldDefinitionType
 */
export enum FieldDefinitionType {
  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_BOOLEAN = 1;
   */
  BOOLEAN = 1,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_NUMBER = 2;
   */
  NUMBER = 2,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_STRING = 3;
   */
  STRING = 3,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_TIMESTAMP = 4;
   */
  TIMESTAMP = 4,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_USER_ID = 5;
   */
  USER_ID = 5,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_RECORD_ID = 6;
   */
  RECORD_ID = 6,

  /**
   * @generated from enum value: FIELD_DEFINITION_TYPE_ENUM = 7;
   */
  ENUM = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(FieldDefinitionType)
proto3.util.setEnumType(FieldDefinitionType, "okapicrm.v1.FieldDefinitionType", [
  { no: 0, name: "FIELD_DEFINITION_TYPE_UNSPECIFIED" },
  { no: 1, name: "FIELD_DEFINITION_TYPE_BOOLEAN" },
  { no: 2, name: "FIELD_DEFINITION_TYPE_NUMBER" },
  { no: 3, name: "FIELD_DEFINITION_TYPE_STRING" },
  { no: 4, name: "FIELD_DEFINITION_TYPE_TIMESTAMP" },
  { no: 5, name: "FIELD_DEFINITION_TYPE_USER_ID" },
  { no: 6, name: "FIELD_DEFINITION_TYPE_RECORD_ID" },
  { no: 7, name: "FIELD_DEFINITION_TYPE_ENUM" },
]);

/**
 * @generated from enum okapicrm.v1.EnumValueDisplayColor
 */
export enum EnumValueDisplayColor {
  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_RED = 1;
   */
  RED = 1,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_ORANGE = 2;
   */
  ORANGE = 2,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_YELLOW = 3;
   */
  YELLOW = 3,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_GREEN = 4;
   */
  GREEN = 4,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_BLUE = 5;
   */
  BLUE = 5,

  /**
   * @generated from enum value: ENUM_VALUE_DISPLAY_COLOR_VIOLET = 6;
   */
  VIOLET = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(EnumValueDisplayColor)
proto3.util.setEnumType(EnumValueDisplayColor, "okapicrm.v1.EnumValueDisplayColor", [
  { no: 0, name: "ENUM_VALUE_DISPLAY_COLOR_UNSPECIFIED" },
  { no: 1, name: "ENUM_VALUE_DISPLAY_COLOR_RED" },
  { no: 2, name: "ENUM_VALUE_DISPLAY_COLOR_ORANGE" },
  { no: 3, name: "ENUM_VALUE_DISPLAY_COLOR_YELLOW" },
  { no: 4, name: "ENUM_VALUE_DISPLAY_COLOR_GREEN" },
  { no: 5, name: "ENUM_VALUE_DISPLAY_COLOR_BLUE" },
  { no: 6, name: "ENUM_VALUE_DISPLAY_COLOR_VIOLET" },
]);

/**
 * @generated from enum okapicrm.v1.BuiltinIntegration
 */
export enum BuiltinIntegration {
  /**
   * @generated from enum value: BUILTIN_INTEGRATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: BUILTIN_INTEGRATION_GMAIL_V1 = 1;
   */
  GMAIL_V1 = 1,

  /**
   * @generated from enum value: BUILTIN_INTEGRATION_OUTLOOK_V1 = 2;
   */
  OUTLOOK_V1 = 2,

  /**
   * @generated from enum value: BUILTIN_INTEGRATION_LINKEDIN_V1 = 3;
   */
  LINKEDIN_V1 = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(BuiltinIntegration)
proto3.util.setEnumType(BuiltinIntegration, "okapicrm.v1.BuiltinIntegration", [
  { no: 0, name: "BUILTIN_INTEGRATION_UNSPECIFIED" },
  { no: 1, name: "BUILTIN_INTEGRATION_GMAIL_V1" },
  { no: 2, name: "BUILTIN_INTEGRATION_OUTLOOK_V1" },
  { no: 3, name: "BUILTIN_INTEGRATION_LINKEDIN_V1" },
]);

/**
 * @generated from message okapicrm.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * @generated from field: string picture_url = 4;
   */
  pictureUrl = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "picture_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.Object
 */
export class Object$ extends Message<Object$> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string api_name = 2;
   */
  apiName = "";

  /**
   * @generated from field: bool archived = 9;
   */
  archived = false;

  /**
   * @generated from field: string display_name_singular = 3;
   */
  displayNameSingular = "";

  /**
   * @generated from field: string display_name_plural = 4;
   */
  displayNamePlural = "";

  /**
   * @generated from field: repeated okapicrm.v1.FieldDefinition field_definitions = 5;
   */
  fieldDefinitions: FieldDefinition[] = [];

  /**
   * @generated from field: string primary_display_field_definition_api_name = 6;
   */
  primaryDisplayFieldDefinitionApiName = "";

  /**
   * @generated from field: string secondary_display_field_definition_api_name = 7;
   */
  secondaryDisplayFieldDefinitionApiName = "";

  /**
   * @generated from field: string icon_display_field_definition_api_name = 8;
   */
  iconDisplayFieldDefinitionApiName = "";

  constructor(data?: PartialMessage<Object$>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.Object";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "display_name_singular", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name_plural", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "field_definitions", kind: "message", T: FieldDefinition, repeated: true },
    { no: 6, name: "primary_display_field_definition_api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "secondary_display_field_definition_api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "icon_display_field_definition_api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Object$ {
    return new Object$().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Object$ {
    return new Object$().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Object$ {
    return new Object$().fromJsonString(jsonString, options);
  }

  static equals(a: Object$ | PlainMessage<Object$> | undefined, b: Object$ | PlainMessage<Object$> | undefined): boolean {
    return proto3.util.equals(Object$, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.FieldDefinition
 */
export class FieldDefinition extends Message<FieldDefinition> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string api_name = 2;
   */
  apiName = "";

  /**
   * @generated from field: bool archived = 7;
   */
  archived = false;

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * @generated from field: okapicrm.v1.FieldDefinitionType type = 4;
   */
  type = FieldDefinitionType.UNSPECIFIED;

  /**
   * @generated from field: string ref_object_id = 5;
   */
  refObjectId = "";

  /**
   * @generated from field: repeated okapicrm.v1.EnumValue enum_values = 6;
   */
  enumValues: EnumValue[] = [];

  /**
   * @generated from field: bool unique = 8;
   */
  unique = false;

  /**
   * @generated from field: string formula = 9;
   */
  formula = "";

  constructor(data?: PartialMessage<FieldDefinition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.FieldDefinition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(FieldDefinitionType) },
    { no: 5, name: "ref_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "enum_values", kind: "message", T: EnumValue, repeated: true },
    { no: 8, name: "unique", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "formula", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldDefinition {
    return new FieldDefinition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldDefinition {
    return new FieldDefinition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldDefinition {
    return new FieldDefinition().fromJsonString(jsonString, options);
  }

  static equals(a: FieldDefinition | PlainMessage<FieldDefinition> | undefined, b: FieldDefinition | PlainMessage<FieldDefinition> | undefined): boolean {
    return proto3.util.equals(FieldDefinition, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.EnumValue
 */
export class EnumValue extends Message<EnumValue> {
  /**
   * @generated from field: string api_name = 1;
   */
  apiName = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: okapicrm.v1.EnumValueDisplayColor display_color = 3;
   */
  displayColor = EnumValueDisplayColor.UNSPECIFIED;

  constructor(data?: PartialMessage<EnumValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.EnumValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_color", kind: "enum", T: proto3.getEnumType(EnumValueDisplayColor) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnumValue {
    return new EnumValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnumValue {
    return new EnumValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnumValue {
    return new EnumValue().fromJsonString(jsonString, options);
  }

  static equals(a: EnumValue | PlainMessage<EnumValue> | undefined, b: EnumValue | PlainMessage<EnumValue> | undefined): boolean {
    return proto3.util.equals(EnumValue, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ReferringFieldDefinition
 */
export class ReferringFieldDefinition extends Message<ReferringFieldDefinition> {
  /**
   * @generated from field: okapicrm.v1.FieldDefinition field_definition = 1;
   */
  fieldDefinition?: FieldDefinition;

  /**
   * @generated from field: okapicrm.v1.Object object = 2;
   */
  object?: Object$;

  constructor(data?: PartialMessage<ReferringFieldDefinition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ReferringFieldDefinition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_definition", kind: "message", T: FieldDefinition },
    { no: 2, name: "object", kind: "message", T: Object$ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReferringFieldDefinition {
    return new ReferringFieldDefinition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReferringFieldDefinition {
    return new ReferringFieldDefinition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReferringFieldDefinition {
    return new ReferringFieldDefinition().fromJsonString(jsonString, options);
  }

  static equals(a: ReferringFieldDefinition | PlainMessage<ReferringFieldDefinition> | undefined, b: ReferringFieldDefinition | PlainMessage<ReferringFieldDefinition> | undefined): boolean {
    return proto3.util.equals(ReferringFieldDefinition, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.Record
 */
export class Record extends Message<Record> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string object_id = 2;
   */
  objectId = "";

  /**
   * @generated from field: string object_api_name = 6;
   */
  objectApiName = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: okapicrm.v1.Actor created_by = 8;
   */
  createdBy?: Actor;

  /**
   * @generated from field: map<string, okapicrm.v1.FieldValue> fields = 3;
   */
  fields: { [key: string]: FieldValue } = {};

  /**
   * @generated from field: bool archived = 4;
   */
  archived = false;

  /**
   * @generated from field: bool deleted = 5;
   */
  deleted = false;

  /**
   * @generated from field: map<string, okapicrm.v1.FormulaError> formula_errors = 9;
   */
  formulaErrors: { [key: string]: FormulaError } = {};

  constructor(data?: PartialMessage<Record>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.Record";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "object_api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "create_time", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "message", T: Actor },
    { no: 3, name: "fields", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FieldValue} },
    { no: 4, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "formula_errors", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormulaError} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Record {
    return new Record().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Record {
    return new Record().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Record {
    return new Record().fromJsonString(jsonString, options);
  }

  static equals(a: Record | PlainMessage<Record> | undefined, b: Record | PlainMessage<Record> | undefined): boolean {
    return proto3.util.equals(Record, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.FieldValue
 */
export class FieldValue extends Message<FieldValue> {
  /**
   * @generated from oneof okapicrm.v1.FieldValue.value
   */
  value: {
    /**
     * @generated from field: bool boolean = 1;
     */
    value: boolean;
    case: "boolean";
  } | {
    /**
     * @generated from field: double number = 2;
     */
    value: number;
    case: "number";
  } | {
    /**
     * @generated from field: string string = 3;
     */
    value: string;
    case: "string";
  } | {
    /**
     * @generated from field: google.protobuf.Timestamp timestamp = 4;
     */
    value: Timestamp;
    case: "timestamp";
  } | {
    /**
     * @generated from field: string user_id = 5;
     */
    value: string;
    case: "userId";
  } | {
    /**
     * @generated from field: string record_id = 6;
     */
    value: string;
    case: "recordId";
  } | {
    /**
     * @generated from field: string enum_value = 7;
     */
    value: string;
    case: "enumValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FieldValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.FieldValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "boolean", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 2, name: "number", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 3, name: "string", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "timestamp", kind: "message", T: Timestamp, oneof: "value" },
    { no: 5, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 6, name: "record_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 7, name: "enum_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldValue {
    return new FieldValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJsonString(jsonString, options);
  }

  static equals(a: FieldValue | PlainMessage<FieldValue> | undefined, b: FieldValue | PlainMessage<FieldValue> | undefined): boolean {
    return proto3.util.equals(FieldValue, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.FormulaError
 */
export class FormulaError extends Message<FormulaError> {
  /**
   * @generated from field: string message = 1;
   */
  message = "";

  constructor(data?: PartialMessage<FormulaError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.FormulaError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormulaError {
    return new FormulaError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormulaError {
    return new FormulaError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormulaError {
    return new FormulaError().fromJsonString(jsonString, options);
  }

  static equals(a: FormulaError | PlainMessage<FormulaError> | undefined, b: FormulaError | PlainMessage<FormulaError> | undefined): boolean {
    return proto3.util.equals(FormulaError, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.RecordRevision
 */
export class RecordRevision extends Message<RecordRevision> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: okapicrm.v1.Record record = 2;
   */
  record?: Record;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: okapicrm.v1.Actor created_by = 4;
   */
  createdBy?: Actor;

  constructor(data?: PartialMessage<RecordRevision>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.RecordRevision";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "record", kind: "message", T: Record },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "created_by", kind: "message", T: Actor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecordRevision {
    return new RecordRevision().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecordRevision {
    return new RecordRevision().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecordRevision {
    return new RecordRevision().fromJsonString(jsonString, options);
  }

  static equals(a: RecordRevision | PlainMessage<RecordRevision> | undefined, b: RecordRevision | PlainMessage<RecordRevision> | undefined): boolean {
    return proto3.util.equals(RecordRevision, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.Actor
 */
export class Actor extends Message<Actor> {
  /**
   * @generated from oneof okapicrm.v1.Actor.actor
   */
  actor: {
    /**
     * @generated from field: string user_id = 1;
     */
    value: string;
    case: "userId";
  } | {
    /**
     * @generated from field: string api_key_id = 2;
     */
    value: string;
    case: "apiKeyId";
  } | {
    /**
     * @generated from field: okapicrm.v1.BuiltinIntegration builtin_integration = 3;
     */
    value: BuiltinIntegration;
    case: "builtinIntegration";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Actor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.Actor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "actor" },
    { no: 2, name: "api_key_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "actor" },
    { no: 3, name: "builtin_integration", kind: "enum", T: proto3.getEnumType(BuiltinIntegration), oneof: "actor" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Actor {
    return new Actor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Actor {
    return new Actor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Actor {
    return new Actor().fromJsonString(jsonString, options);
  }

  static equals(a: Actor | PlainMessage<Actor> | undefined, b: Actor | PlainMessage<Actor> | undefined): boolean {
    return proto3.util.equals(Actor, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GmailSyncSettings
 */
export class GmailSyncSettings extends Message<GmailSyncSettings> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: repeated string internal_domains = 19;
   */
  internalDomains: string[] = [];

  /**
   * @generated from field: repeated string blocklist_entries = 20;
   */
  blocklistEntries: string[] = [];

  /**
   * @generated from field: repeated string blocklist_subject_phrases = 23;
   */
  blocklistSubjectPhrases: string[] = [];

  /**
   * @generated from field: repeated okapicrm.v1.GmailUserAssociation gmail_user_associations = 21;
   */
  gmailUserAssociations: GmailUserAssociation[] = [];

  /**
   * @generated from field: string email_object_id = 2;
   */
  emailObjectId = "";

  /**
   * @generated from field: string email_dedupe_field_definition_id = 3;
   */
  emailDedupeFieldDefinitionId = "";

  /**
   * @generated from field: string email_user_field_definition_id = 4;
   */
  emailUserFieldDefinitionId = "";

  /**
   * @generated from field: string email_person_field_definition_id = 5;
   */
  emailPersonFieldDefinitionId = "";

  /**
   * @generated from field: string email_sent_field_definition_id = 6;
   */
  emailSentFieldDefinitionId = "";

  /**
   * @generated from field: string email_subject_field_definition_id = 7;
   */
  emailSubjectFieldDefinitionId = "";

  /**
   * @generated from field: string email_body_field_definition_id = 8;
   */
  emailBodyFieldDefinitionId = "";

  /**
   * @generated from field: string email_from_field_definition_id = 9;
   */
  emailFromFieldDefinitionId = "";

  /**
   * @generated from field: string email_to_field_definition_id = 10;
   */
  emailToFieldDefinitionId = "";

  /**
   * @generated from field: string email_cc_field_definition_id = 11;
   */
  emailCcFieldDefinitionId = "";

  /**
   * @generated from field: string email_timestamp_field_definition_id = 12;
   */
  emailTimestampFieldDefinitionId = "";

  /**
   * @generated from field: string email_thread_id_field_definition_id = 22;
   */
  emailThreadIdFieldDefinitionId = "";

  /**
   * @generated from field: string person_object_id = 13;
   */
  personObjectId = "";

  /**
   * @generated from field: string person_company_field_definition_id = 14;
   */
  personCompanyFieldDefinitionId = "";

  /**
   * @generated from field: string person_email_field_definition_id = 15;
   */
  personEmailFieldDefinitionId = "";

  /**
   * @generated from field: string person_name_field_definition_id = 16;
   */
  personNameFieldDefinitionId = "";

  /**
   * @generated from field: string company_object_id = 17;
   */
  companyObjectId = "";

  /**
   * @generated from field: string company_domain_field_definition_id = 18;
   */
  companyDomainFieldDefinitionId = "";

  constructor(data?: PartialMessage<GmailSyncSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GmailSyncSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "internal_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "blocklist_entries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 23, name: "blocklist_subject_phrases", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "gmail_user_associations", kind: "message", T: GmailUserAssociation, repeated: true },
    { no: 2, name: "email_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email_dedupe_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email_user_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email_person_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email_sent_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email_subject_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "email_body_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email_from_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email_to_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email_cc_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email_timestamp_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "email_thread_id_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "person_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "person_company_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "person_email_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "person_name_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "company_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "company_domain_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GmailSyncSettings {
    return new GmailSyncSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GmailSyncSettings {
    return new GmailSyncSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GmailSyncSettings {
    return new GmailSyncSettings().fromJsonString(jsonString, options);
  }

  static equals(a: GmailSyncSettings | PlainMessage<GmailSyncSettings> | undefined, b: GmailSyncSettings | PlainMessage<GmailSyncSettings> | undefined): boolean {
    return proto3.util.equals(GmailSyncSettings, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GmailUserAssociation
 */
export class GmailUserAssociation extends Message<GmailUserAssociation> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string gmail_address = 2;
   */
  gmailAddress = "";

  constructor(data?: PartialMessage<GmailUserAssociation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GmailUserAssociation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "gmail_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GmailUserAssociation {
    return new GmailUserAssociation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GmailUserAssociation {
    return new GmailUserAssociation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GmailUserAssociation {
    return new GmailUserAssociation().fromJsonString(jsonString, options);
  }

  static equals(a: GmailUserAssociation | PlainMessage<GmailUserAssociation> | undefined, b: GmailUserAssociation | PlainMessage<GmailUserAssociation> | undefined): boolean {
    return proto3.util.equals(GmailUserAssociation, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GmailUserSyncSettings
 */
export class GmailUserSyncSettings extends Message<GmailUserSyncSettings> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  constructor(data?: PartialMessage<GmailUserSyncSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GmailUserSyncSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GmailUserSyncSettings {
    return new GmailUserSyncSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GmailUserSyncSettings {
    return new GmailUserSyncSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GmailUserSyncSettings {
    return new GmailUserSyncSettings().fromJsonString(jsonString, options);
  }

  static equals(a: GmailUserSyncSettings | PlainMessage<GmailUserSyncSettings> | undefined, b: GmailUserSyncSettings | PlainMessage<GmailUserSyncSettings> | undefined): boolean {
    return proto3.util.equals(GmailUserSyncSettings, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.OutlookSyncSettings
 */
export class OutlookSyncSettings extends Message<OutlookSyncSettings> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: repeated string internal_domains = 19;
   */
  internalDomains: string[] = [];

  /**
   * @generated from field: repeated string blocklist_entries = 20;
   */
  blocklistEntries: string[] = [];

  /**
   * @generated from field: string email_object_id = 2;
   */
  emailObjectId = "";

  /**
   * @generated from field: string email_dedupe_field_definition_id = 3;
   */
  emailDedupeFieldDefinitionId = "";

  /**
   * @generated from field: string email_user_field_definition_id = 4;
   */
  emailUserFieldDefinitionId = "";

  /**
   * @generated from field: string email_person_field_definition_id = 5;
   */
  emailPersonFieldDefinitionId = "";

  /**
   * @generated from field: string email_sent_field_definition_id = 6;
   */
  emailSentFieldDefinitionId = "";

  /**
   * @generated from field: string email_subject_field_definition_id = 7;
   */
  emailSubjectFieldDefinitionId = "";

  /**
   * @generated from field: string email_body_field_definition_id = 8;
   */
  emailBodyFieldDefinitionId = "";

  /**
   * @generated from field: string email_from_field_definition_id = 9;
   */
  emailFromFieldDefinitionId = "";

  /**
   * @generated from field: string email_to_field_definition_id = 10;
   */
  emailToFieldDefinitionId = "";

  /**
   * @generated from field: string email_cc_field_definition_id = 11;
   */
  emailCcFieldDefinitionId = "";

  /**
   * @generated from field: string email_timestamp_field_definition_id = 12;
   */
  emailTimestampFieldDefinitionId = "";

  /**
   * @generated from field: string email_thread_id_field_definition_id = 22;
   */
  emailThreadIdFieldDefinitionId = "";

  /**
   * @generated from field: string person_object_id = 13;
   */
  personObjectId = "";

  /**
   * @generated from field: string person_company_field_definition_id = 14;
   */
  personCompanyFieldDefinitionId = "";

  /**
   * @generated from field: string person_email_field_definition_id = 15;
   */
  personEmailFieldDefinitionId = "";

  /**
   * @generated from field: string person_name_field_definition_id = 16;
   */
  personNameFieldDefinitionId = "";

  /**
   * @generated from field: string company_object_id = 17;
   */
  companyObjectId = "";

  /**
   * @generated from field: string company_domain_field_definition_id = 18;
   */
  companyDomainFieldDefinitionId = "";

  constructor(data?: PartialMessage<OutlookSyncSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.OutlookSyncSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "internal_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "blocklist_entries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "email_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email_dedupe_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email_user_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email_person_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email_sent_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email_subject_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "email_body_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email_from_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email_to_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email_cc_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email_timestamp_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "email_thread_id_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "person_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "person_company_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "person_email_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "person_name_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "company_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "company_domain_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OutlookSyncSettings {
    return new OutlookSyncSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OutlookSyncSettings {
    return new OutlookSyncSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OutlookSyncSettings {
    return new OutlookSyncSettings().fromJsonString(jsonString, options);
  }

  static equals(a: OutlookSyncSettings | PlainMessage<OutlookSyncSettings> | undefined, b: OutlookSyncSettings | PlainMessage<OutlookSyncSettings> | undefined): boolean {
    return proto3.util.equals(OutlookSyncSettings, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.EmailFollowupTask
 */
export class EmailFollowupTask extends Message<EmailFollowupTask> {
  /**
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string email_record_id = 2;
   */
  emailRecordId = "";

  /**
   * @generated from field: bool done = 4;
   */
  done = false;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: string thread_id = 6;
   */
  threadId = "";

  /**
   * @generated from field: bool sent = 7;
   */
  sent = false;

  /**
   * @generated from field: bool starred = 8;
   */
  starred = false;

  /**
   * @generated from field: string body = 9;
   */
  body = "";

  constructor(data?: PartialMessage<EmailFollowupTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.EmailFollowupTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email_record_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "starred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmailFollowupTask {
    return new EmailFollowupTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmailFollowupTask {
    return new EmailFollowupTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmailFollowupTask {
    return new EmailFollowupTask().fromJsonString(jsonString, options);
  }

  static equals(a: EmailFollowupTask | PlainMessage<EmailFollowupTask> | undefined, b: EmailFollowupTask | PlainMessage<EmailFollowupTask> | undefined): boolean {
    return proto3.util.equals(EmailFollowupTask, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.FaviconSyncSettings
 */
export class FaviconSyncSettings extends Message<FaviconSyncSettings> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: string company_object_id = 2;
   */
  companyObjectId = "";

  /**
   * @generated from field: string company_domain_field_definition_id = 3;
   */
  companyDomainFieldDefinitionId = "";

  /**
   * @generated from field: string company_icon_field_definition_id = 4;
   */
  companyIconFieldDefinitionId = "";

  constructor(data?: PartialMessage<FaviconSyncSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.FaviconSyncSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "company_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company_domain_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "company_icon_field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FaviconSyncSettings {
    return new FaviconSyncSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FaviconSyncSettings {
    return new FaviconSyncSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FaviconSyncSettings {
    return new FaviconSyncSettings().fromJsonString(jsonString, options);
  }

  static equals(a: FaviconSyncSettings | PlainMessage<FaviconSyncSettings> | undefined, b: FaviconSyncSettings | PlainMessage<FaviconSyncSettings> | undefined): boolean {
    return proto3.util.equals(FaviconSyncSettings, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.WebhookSettings
 */
export class WebhookSettings extends Message<WebhookSettings> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: string app_portal_url = 2;
   */
  appPortalUrl = "";

  /**
   * @generated from field: string zapier_integration_key = 3;
   */
  zapierIntegrationKey = "";

  constructor(data?: PartialMessage<WebhookSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.WebhookSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "app_portal_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "zapier_integration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebhookSettings {
    return new WebhookSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebhookSettings {
    return new WebhookSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebhookSettings {
    return new WebhookSettings().fromJsonString(jsonString, options);
  }

  static equals(a: WebhookSettings | PlainMessage<WebhookSettings> | undefined, b: WebhookSettings | PlainMessage<WebhookSettings> | undefined): boolean {
    return proto3.util.equals(WebhookSettings, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.VizzlyIdentityConfig
 */
export class VizzlyIdentityConfig extends Message<VizzlyIdentityConfig> {
  /**
   * @generated from field: string data_access_token = 1;
   */
  dataAccessToken = "";

  /**
   * @generated from field: string dashboard_access_token = 2;
   */
  dashboardAccessToken = "";

  constructor(data?: PartialMessage<VizzlyIdentityConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.VizzlyIdentityConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dashboard_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VizzlyIdentityConfig {
    return new VizzlyIdentityConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VizzlyIdentityConfig {
    return new VizzlyIdentityConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VizzlyIdentityConfig {
    return new VizzlyIdentityConfig().fromJsonString(jsonString, options);
  }

  static equals(a: VizzlyIdentityConfig | PlainMessage<VizzlyIdentityConfig> | undefined, b: VizzlyIdentityConfig | PlainMessage<VizzlyIdentityConfig> | undefined): boolean {
    return proto3.util.equals(VizzlyIdentityConfig, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListUsersRequest
 */
export class ListUsersRequest extends Message<ListUsersRequest> {
  /**
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined, b: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListUsersRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListUsersResponse
 */
export class ListUsersResponse extends Message<ListUsersResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.User users = 1;
   */
  users: User[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined, b: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListUsersResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetUserRequest
 */
export class GetUserRequest extends Message<GetUserRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserRequest {
    return new GetUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserRequest | PlainMessage<GetUserRequest> | undefined, b: GetUserRequest | PlainMessage<GetUserRequest> | undefined): boolean {
    return proto3.util.equals(GetUserRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListObjectsRequest
 */
export class ListObjectsRequest extends Message<ListObjectsRequest> {
  /**
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  /**
   * @generated from field: bool include_archived = 2;
   */
  includeArchived = false;

  constructor(data?: PartialMessage<ListObjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListObjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListObjectsRequest {
    return new ListObjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListObjectsRequest {
    return new ListObjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListObjectsRequest {
    return new ListObjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListObjectsRequest | PlainMessage<ListObjectsRequest> | undefined, b: ListObjectsRequest | PlainMessage<ListObjectsRequest> | undefined): boolean {
    return proto3.util.equals(ListObjectsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListObjectsResponse
 */
export class ListObjectsResponse extends Message<ListObjectsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.Object objects = 1;
   */
  objects: Object$[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListObjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListObjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "objects", kind: "message", T: Object$, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListObjectsResponse {
    return new ListObjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListObjectsResponse {
    return new ListObjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListObjectsResponse {
    return new ListObjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListObjectsResponse | PlainMessage<ListObjectsResponse> | undefined, b: ListObjectsResponse | PlainMessage<ListObjectsResponse> | undefined): boolean {
    return proto3.util.equals(ListObjectsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetObjectRequest
 */
export class GetObjectRequest extends Message<GetObjectRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string api_name = 2;
   */
  apiName = "";

  constructor(data?: PartialMessage<GetObjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetObjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetObjectRequest {
    return new GetObjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetObjectRequest {
    return new GetObjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetObjectRequest {
    return new GetObjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetObjectRequest | PlainMessage<GetObjectRequest> | undefined, b: GetObjectRequest | PlainMessage<GetObjectRequest> | undefined): boolean {
    return proto3.util.equals(GetObjectRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateObjectRequest
 */
export class CreateObjectRequest extends Message<CreateObjectRequest> {
  /**
   * @generated from field: okapicrm.v1.Object object = 1;
   */
  object?: Object$;

  constructor(data?: PartialMessage<CreateObjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateObjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object", kind: "message", T: Object$ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateObjectRequest {
    return new CreateObjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateObjectRequest {
    return new CreateObjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateObjectRequest {
    return new CreateObjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateObjectRequest | PlainMessage<CreateObjectRequest> | undefined, b: CreateObjectRequest | PlainMessage<CreateObjectRequest> | undefined): boolean {
    return proto3.util.equals(CreateObjectRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.UpdateObjectRequest
 */
export class UpdateObjectRequest extends Message<UpdateObjectRequest> {
  /**
   * @generated from field: okapicrm.v1.Object object = 1;
   */
  object?: Object$;

  constructor(data?: PartialMessage<UpdateObjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.UpdateObjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object", kind: "message", T: Object$ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateObjectRequest {
    return new UpdateObjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateObjectRequest {
    return new UpdateObjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateObjectRequest {
    return new UpdateObjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateObjectRequest | PlainMessage<UpdateObjectRequest> | undefined, b: UpdateObjectRequest | PlainMessage<UpdateObjectRequest> | undefined): boolean {
    return proto3.util.equals(UpdateObjectRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListReferringFieldDefinitionsRequest
 */
export class ListReferringFieldDefinitionsRequest extends Message<ListReferringFieldDefinitionsRequest> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListReferringFieldDefinitionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListReferringFieldDefinitionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReferringFieldDefinitionsRequest {
    return new ListReferringFieldDefinitionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReferringFieldDefinitionsRequest {
    return new ListReferringFieldDefinitionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReferringFieldDefinitionsRequest {
    return new ListReferringFieldDefinitionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReferringFieldDefinitionsRequest | PlainMessage<ListReferringFieldDefinitionsRequest> | undefined, b: ListReferringFieldDefinitionsRequest | PlainMessage<ListReferringFieldDefinitionsRequest> | undefined): boolean {
    return proto3.util.equals(ListReferringFieldDefinitionsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListReferringFieldDefinitionsResponse
 */
export class ListReferringFieldDefinitionsResponse extends Message<ListReferringFieldDefinitionsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.ReferringFieldDefinition referring_field_definitions = 1;
   */
  referringFieldDefinitions: ReferringFieldDefinition[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListReferringFieldDefinitionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListReferringFieldDefinitionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "referring_field_definitions", kind: "message", T: ReferringFieldDefinition, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReferringFieldDefinitionsResponse {
    return new ListReferringFieldDefinitionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReferringFieldDefinitionsResponse {
    return new ListReferringFieldDefinitionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReferringFieldDefinitionsResponse {
    return new ListReferringFieldDefinitionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReferringFieldDefinitionsResponse | PlainMessage<ListReferringFieldDefinitionsResponse> | undefined, b: ListReferringFieldDefinitionsResponse | PlainMessage<ListReferringFieldDefinitionsResponse> | undefined): boolean {
    return proto3.util.equals(ListReferringFieldDefinitionsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecordsRequest
 */
export class ListRecordsRequest extends Message<ListRecordsRequest> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = "";

  /**
   * @generated from field: string object_api_name = 2;
   */
  objectApiName = "";

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  /**
   * @generated from field: string sort_by = 4;
   */
  sortBy = "";

  /**
   * @generated from field: string sort_direction = 5;
   */
  sortDirection = "";

  constructor(data?: PartialMessage<ListRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "object_api_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sort_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sort_direction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecordsRequest {
    return new ListRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecordsRequest {
    return new ListRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecordsRequest {
    return new ListRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecordsRequest | PlainMessage<ListRecordsRequest> | undefined, b: ListRecordsRequest | PlainMessage<ListRecordsRequest> | undefined): boolean {
    return proto3.util.equals(ListRecordsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecordsResponse
 */
export class ListRecordsResponse extends Message<ListRecordsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.Record records = 1;
   */
  records: Record[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "records", kind: "message", T: Record, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecordsResponse {
    return new ListRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecordsResponse {
    return new ListRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecordsResponse {
    return new ListRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecordsResponse | PlainMessage<ListRecordsResponse> | undefined, b: ListRecordsResponse | PlainMessage<ListRecordsResponse> | undefined): boolean {
    return proto3.util.equals(ListRecordsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.SearchRecordsRequest
 */
export class SearchRecordsRequest extends Message<SearchRecordsRequest> {
  /**
   * @generated from field: repeated string object_ids = 1;
   */
  objectIds: string[] = [];

  /**
   * @generated from field: string query = 2;
   */
  query = "";

  constructor(data?: PartialMessage<SearchRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.SearchRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRecordsRequest {
    return new SearchRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRecordsRequest {
    return new SearchRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRecordsRequest {
    return new SearchRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRecordsRequest | PlainMessage<SearchRecordsRequest> | undefined, b: SearchRecordsRequest | PlainMessage<SearchRecordsRequest> | undefined): boolean {
    return proto3.util.equals(SearchRecordsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.SearchRecordsResponse
 */
export class SearchRecordsResponse extends Message<SearchRecordsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.Record records = 1;
   */
  records: Record[] = [];

  /**
   * @generated from field: repeated okapicrm.v1.RecordHit record_hits = 3;
   */
  recordHits: RecordHit[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<SearchRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.SearchRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "records", kind: "message", T: Record, repeated: true },
    { no: 3, name: "record_hits", kind: "message", T: RecordHit, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRecordsResponse {
    return new SearchRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRecordsResponse {
    return new SearchRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRecordsResponse {
    return new SearchRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRecordsResponse | PlainMessage<SearchRecordsResponse> | undefined, b: SearchRecordsResponse | PlainMessage<SearchRecordsResponse> | undefined): boolean {
    return proto3.util.equals(SearchRecordsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.RecordHit
 */
export class RecordHit extends Message<RecordHit> {
  /**
   * @generated from field: okapicrm.v1.Record record = 1;
   */
  record?: Record;

  /**
   * @generated from field: string field_definition_id = 2;
   */
  fieldDefinitionId = "";

  /**
   * @generated from field: string highlight_snippet = 3;
   */
  highlightSnippet = "";

  constructor(data?: PartialMessage<RecordHit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.RecordHit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record", kind: "message", T: Record },
    { no: 2, name: "field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "highlight_snippet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecordHit {
    return new RecordHit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecordHit {
    return new RecordHit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecordHit {
    return new RecordHit().fromJsonString(jsonString, options);
  }

  static equals(a: RecordHit | PlainMessage<RecordHit> | undefined, b: RecordHit | PlainMessage<RecordHit> | undefined): boolean {
    return proto3.util.equals(RecordHit, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetRecordRequest
 */
export class GetRecordRequest extends Message<GetRecordRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRecordRequest {
    return new GetRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRecordRequest {
    return new GetRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRecordRequest {
    return new GetRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRecordRequest | PlainMessage<GetRecordRequest> | undefined, b: GetRecordRequest | PlainMessage<GetRecordRequest> | undefined): boolean {
    return proto3.util.equals(GetRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateRecordRequest
 */
export class CreateRecordRequest extends Message<CreateRecordRequest> {
  /**
   * @generated from field: okapicrm.v1.Record record = 1;
   */
  record?: Record;

  constructor(data?: PartialMessage<CreateRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record", kind: "message", T: Record },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRecordRequest {
    return new CreateRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRecordRequest {
    return new CreateRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRecordRequest {
    return new CreateRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRecordRequest | PlainMessage<CreateRecordRequest> | undefined, b: CreateRecordRequest | PlainMessage<CreateRecordRequest> | undefined): boolean {
    return proto3.util.equals(CreateRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.UpdateRecordRequest
 */
export class UpdateRecordRequest extends Message<UpdateRecordRequest> {
  /**
   * @generated from field: okapicrm.v1.Record record = 1;
   */
  record?: Record;

  /**
   * @generated from field: repeated string update_fields = 2;
   */
  updateFields: string[] = [];

  constructor(data?: PartialMessage<UpdateRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.UpdateRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record", kind: "message", T: Record },
    { no: 2, name: "update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRecordRequest {
    return new UpdateRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRecordRequest {
    return new UpdateRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRecordRequest {
    return new UpdateRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRecordRequest | PlainMessage<UpdateRecordRequest> | undefined, b: UpdateRecordRequest | PlainMessage<UpdateRecordRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ArchiveRecordRequest
 */
export class ArchiveRecordRequest extends Message<ArchiveRecordRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<ArchiveRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ArchiveRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveRecordRequest {
    return new ArchiveRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveRecordRequest {
    return new ArchiveRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArchiveRecordRequest {
    return new ArchiveRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ArchiveRecordRequest | PlainMessage<ArchiveRecordRequest> | undefined, b: ArchiveRecordRequest | PlainMessage<ArchiveRecordRequest> | undefined): boolean {
    return proto3.util.equals(ArchiveRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.UnarchiveRecordRequest
 */
export class UnarchiveRecordRequest extends Message<UnarchiveRecordRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<UnarchiveRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.UnarchiveRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnarchiveRecordRequest {
    return new UnarchiveRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnarchiveRecordRequest {
    return new UnarchiveRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnarchiveRecordRequest {
    return new UnarchiveRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnarchiveRecordRequest | PlainMessage<UnarchiveRecordRequest> | undefined, b: UnarchiveRecordRequest | PlainMessage<UnarchiveRecordRequest> | undefined): boolean {
    return proto3.util.equals(UnarchiveRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.DeleteRecordRequest
 */
export class DeleteRecordRequest extends Message<DeleteRecordRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.DeleteRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRecordRequest {
    return new DeleteRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRecordRequest {
    return new DeleteRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRecordRequest {
    return new DeleteRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRecordRequest | PlainMessage<DeleteRecordRequest> | undefined, b: DeleteRecordRequest | PlainMessage<DeleteRecordRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.UpsertRecordRequest
 */
export class UpsertRecordRequest extends Message<UpsertRecordRequest> {
  /**
   * @generated from field: okapicrm.v1.Record record = 1;
   */
  record?: Record;

  /**
   * @generated from field: repeated string update_fields = 2;
   */
  updateFields: string[] = [];

  /**
   * @generated from field: string match_on = 3;
   */
  matchOn = "";

  constructor(data?: PartialMessage<UpsertRecordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.UpsertRecordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record", kind: "message", T: Record },
    { no: 2, name: "update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "match_on", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertRecordRequest {
    return new UpsertRecordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertRecordRequest {
    return new UpsertRecordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertRecordRequest {
    return new UpsertRecordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertRecordRequest | PlainMessage<UpsertRecordRequest> | undefined, b: UpsertRecordRequest | PlainMessage<UpsertRecordRequest> | undefined): boolean {
    return proto3.util.equals(UpsertRecordRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ExportRecordsRequest
 */
export class ExportRecordsRequest extends Message<ExportRecordsRequest> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = "";

  constructor(data?: PartialMessage<ExportRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ExportRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportRecordsRequest {
    return new ExportRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportRecordsRequest {
    return new ExportRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportRecordsRequest {
    return new ExportRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportRecordsRequest | PlainMessage<ExportRecordsRequest> | undefined, b: ExportRecordsRequest | PlainMessage<ExportRecordsRequest> | undefined): boolean {
    return proto3.util.equals(ExportRecordsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ExportRecordsResponse
 */
export class ExportRecordsResponse extends Message<ExportRecordsResponse> {
  /**
   * @generated from field: string download_url = 1;
   */
  downloadUrl = "";

  constructor(data?: PartialMessage<ExportRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ExportRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportRecordsResponse {
    return new ExportRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportRecordsResponse {
    return new ExportRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportRecordsResponse {
    return new ExportRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExportRecordsResponse | PlainMessage<ExportRecordsResponse> | undefined, b: ExportRecordsResponse | PlainMessage<ExportRecordsResponse> | undefined): boolean {
    return proto3.util.equals(ExportRecordsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecordRevisionsRequest
 */
export class ListRecordRevisionsRequest extends Message<ListRecordRevisionsRequest> {
  /**
   * @generated from field: string record_id = 1;
   */
  recordId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRecordRevisionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecordRevisionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecordRevisionsRequest {
    return new ListRecordRevisionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecordRevisionsRequest {
    return new ListRecordRevisionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecordRevisionsRequest {
    return new ListRecordRevisionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecordRevisionsRequest | PlainMessage<ListRecordRevisionsRequest> | undefined, b: ListRecordRevisionsRequest | PlainMessage<ListRecordRevisionsRequest> | undefined): boolean {
    return proto3.util.equals(ListRecordRevisionsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecordRevisionsResponse
 */
export class ListRecordRevisionsResponse extends Message<ListRecordRevisionsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.RecordRevision record_revisions = 1;
   */
  recordRevisions: RecordRevision[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListRecordRevisionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecordRevisionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record_revisions", kind: "message", T: RecordRevision, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecordRevisionsResponse {
    return new ListRecordRevisionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecordRevisionsResponse {
    return new ListRecordRevisionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecordRevisionsResponse {
    return new ListRecordRevisionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecordRevisionsResponse | PlainMessage<ListRecordRevisionsResponse> | undefined, b: ListRecordRevisionsResponse | PlainMessage<ListRecordRevisionsResponse> | undefined): boolean {
    return proto3.util.equals(ListRecordRevisionsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListReferringRecordsRequest
 */
export class ListReferringRecordsRequest extends Message<ListReferringRecordsRequest> {
  /**
   * @generated from field: string record_id = 1;
   */
  recordId = "";

  /**
   * @generated from field: string field_definition_id = 2;
   */
  fieldDefinitionId = "";

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListReferringRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListReferringRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "record_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "field_definition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReferringRecordsRequest {
    return new ListReferringRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReferringRecordsRequest {
    return new ListReferringRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReferringRecordsRequest {
    return new ListReferringRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReferringRecordsRequest | PlainMessage<ListReferringRecordsRequest> | undefined, b: ListReferringRecordsRequest | PlainMessage<ListReferringRecordsRequest> | undefined): boolean {
    return proto3.util.equals(ListReferringRecordsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListReferringRecordsResponse
 */
export class ListReferringRecordsResponse extends Message<ListReferringRecordsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.Record records = 1;
   */
  records: Record[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListReferringRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListReferringRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "records", kind: "message", T: Record, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReferringRecordsResponse {
    return new ListReferringRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReferringRecordsResponse {
    return new ListReferringRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReferringRecordsResponse {
    return new ListReferringRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReferringRecordsResponse | PlainMessage<ListReferringRecordsResponse> | undefined, b: ListReferringRecordsResponse | PlainMessage<ListReferringRecordsResponse> | undefined): boolean {
    return proto3.util.equals(ListReferringRecordsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateGoogleConnectionRequest
 */
export class CreateGoogleConnectionRequest extends Message<CreateGoogleConnectionRequest> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  constructor(data?: PartialMessage<CreateGoogleConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateGoogleConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGoogleConnectionRequest {
    return new CreateGoogleConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGoogleConnectionRequest {
    return new CreateGoogleConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGoogleConnectionRequest {
    return new CreateGoogleConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGoogleConnectionRequest | PlainMessage<CreateGoogleConnectionRequest> | undefined, b: CreateGoogleConnectionRequest | PlainMessage<CreateGoogleConnectionRequest> | undefined): boolean {
    return proto3.util.equals(CreateGoogleConnectionRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateGoogleConnectionResponse
 */
export class CreateGoogleConnectionResponse extends Message<CreateGoogleConnectionResponse> {
  constructor(data?: PartialMessage<CreateGoogleConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateGoogleConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGoogleConnectionResponse {
    return new CreateGoogleConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGoogleConnectionResponse {
    return new CreateGoogleConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGoogleConnectionResponse {
    return new CreateGoogleConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGoogleConnectionResponse | PlainMessage<CreateGoogleConnectionResponse> | undefined, b: CreateGoogleConnectionResponse | PlainMessage<CreateGoogleConnectionResponse> | undefined): boolean {
    return proto3.util.equals(CreateGoogleConnectionResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateMicrosoftConnectionRequest
 */
export class CreateMicrosoftConnectionRequest extends Message<CreateMicrosoftConnectionRequest> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  constructor(data?: PartialMessage<CreateMicrosoftConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateMicrosoftConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMicrosoftConnectionRequest {
    return new CreateMicrosoftConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMicrosoftConnectionRequest {
    return new CreateMicrosoftConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMicrosoftConnectionRequest {
    return new CreateMicrosoftConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMicrosoftConnectionRequest | PlainMessage<CreateMicrosoftConnectionRequest> | undefined, b: CreateMicrosoftConnectionRequest | PlainMessage<CreateMicrosoftConnectionRequest> | undefined): boolean {
    return proto3.util.equals(CreateMicrosoftConnectionRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.CreateMicrosoftConnectionResponse
 */
export class CreateMicrosoftConnectionResponse extends Message<CreateMicrosoftConnectionResponse> {
  constructor(data?: PartialMessage<CreateMicrosoftConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.CreateMicrosoftConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMicrosoftConnectionResponse {
    return new CreateMicrosoftConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMicrosoftConnectionResponse {
    return new CreateMicrosoftConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMicrosoftConnectionResponse {
    return new CreateMicrosoftConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMicrosoftConnectionResponse | PlainMessage<CreateMicrosoftConnectionResponse> | undefined, b: CreateMicrosoftConnectionResponse | PlainMessage<CreateMicrosoftConnectionResponse> | undefined): boolean {
    return proto3.util.equals(CreateMicrosoftConnectionResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetGmailSyncSettingsRequest
 */
export class GetGmailSyncSettingsRequest extends Message<GetGmailSyncSettingsRequest> {
  constructor(data?: PartialMessage<GetGmailSyncSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetGmailSyncSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGmailSyncSettingsRequest {
    return new GetGmailSyncSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGmailSyncSettingsRequest {
    return new GetGmailSyncSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGmailSyncSettingsRequest {
    return new GetGmailSyncSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGmailSyncSettingsRequest | PlainMessage<GetGmailSyncSettingsRequest> | undefined, b: GetGmailSyncSettingsRequest | PlainMessage<GetGmailSyncSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetGmailSyncSettingsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetOutlookSyncSettingsRequest
 */
export class GetOutlookSyncSettingsRequest extends Message<GetOutlookSyncSettingsRequest> {
  constructor(data?: PartialMessage<GetOutlookSyncSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetOutlookSyncSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOutlookSyncSettingsRequest {
    return new GetOutlookSyncSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOutlookSyncSettingsRequest {
    return new GetOutlookSyncSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOutlookSyncSettingsRequest {
    return new GetOutlookSyncSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOutlookSyncSettingsRequest | PlainMessage<GetOutlookSyncSettingsRequest> | undefined, b: GetOutlookSyncSettingsRequest | PlainMessage<GetOutlookSyncSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetOutlookSyncSettingsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListEmailFollowupTasksRequest
 */
export class ListEmailFollowupTasksRequest extends Message<ListEmailFollowupTasksRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: bool sent = 2;
   */
  sent = false;

  /**
   * @generated from field: bool received = 3;
   */
  received = false;

  constructor(data?: PartialMessage<ListEmailFollowupTasksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListEmailFollowupTasksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "received", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEmailFollowupTasksRequest {
    return new ListEmailFollowupTasksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEmailFollowupTasksRequest {
    return new ListEmailFollowupTasksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEmailFollowupTasksRequest {
    return new ListEmailFollowupTasksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEmailFollowupTasksRequest | PlainMessage<ListEmailFollowupTasksRequest> | undefined, b: ListEmailFollowupTasksRequest | PlainMessage<ListEmailFollowupTasksRequest> | undefined): boolean {
    return proto3.util.equals(ListEmailFollowupTasksRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListEmailFollowupTasksResponse
 */
export class ListEmailFollowupTasksResponse extends Message<ListEmailFollowupTasksResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.EmailFollowupTask email_followup_tasks = 1;
   */
  emailFollowupTasks: EmailFollowupTask[] = [];

  constructor(data?: PartialMessage<ListEmailFollowupTasksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListEmailFollowupTasksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email_followup_tasks", kind: "message", T: EmailFollowupTask, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEmailFollowupTasksResponse {
    return new ListEmailFollowupTasksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEmailFollowupTasksResponse {
    return new ListEmailFollowupTasksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEmailFollowupTasksResponse {
    return new ListEmailFollowupTasksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEmailFollowupTasksResponse | PlainMessage<ListEmailFollowupTasksResponse> | undefined, b: ListEmailFollowupTasksResponse | PlainMessage<ListEmailFollowupTasksResponse> | undefined): boolean {
    return proto3.util.equals(ListEmailFollowupTasksResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.MarkEmailFollowupTaskDoneRequest
 */
export class MarkEmailFollowupTaskDoneRequest extends Message<MarkEmailFollowupTaskDoneRequest> {
  /**
   * @generated from field: string email_followup_task_id = 1;
   */
  emailFollowupTaskId = "";

  constructor(data?: PartialMessage<MarkEmailFollowupTaskDoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.MarkEmailFollowupTaskDoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email_followup_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkEmailFollowupTaskDoneRequest {
    return new MarkEmailFollowupTaskDoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkEmailFollowupTaskDoneRequest {
    return new MarkEmailFollowupTaskDoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkEmailFollowupTaskDoneRequest {
    return new MarkEmailFollowupTaskDoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MarkEmailFollowupTaskDoneRequest | PlainMessage<MarkEmailFollowupTaskDoneRequest> | undefined, b: MarkEmailFollowupTaskDoneRequest | PlainMessage<MarkEmailFollowupTaskDoneRequest> | undefined): boolean {
    return proto3.util.equals(MarkEmailFollowupTaskDoneRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.MarkEmailFollowupTaskDoneResponse
 */
export class MarkEmailFollowupTaskDoneResponse extends Message<MarkEmailFollowupTaskDoneResponse> {
  constructor(data?: PartialMessage<MarkEmailFollowupTaskDoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.MarkEmailFollowupTaskDoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkEmailFollowupTaskDoneResponse {
    return new MarkEmailFollowupTaskDoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkEmailFollowupTaskDoneResponse {
    return new MarkEmailFollowupTaskDoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkEmailFollowupTaskDoneResponse {
    return new MarkEmailFollowupTaskDoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MarkEmailFollowupTaskDoneResponse | PlainMessage<MarkEmailFollowupTaskDoneResponse> | undefined, b: MarkEmailFollowupTaskDoneResponse | PlainMessage<MarkEmailFollowupTaskDoneResponse> | undefined): boolean {
    return proto3.util.equals(MarkEmailFollowupTaskDoneResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.StarEmailFollowupTaskRequest
 */
export class StarEmailFollowupTaskRequest extends Message<StarEmailFollowupTaskRequest> {
  /**
   * @generated from field: string email_followup_task_id = 1;
   */
  emailFollowupTaskId = "";

  /**
   * @generated from field: bool star = 2;
   */
  star = false;

  constructor(data?: PartialMessage<StarEmailFollowupTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.StarEmailFollowupTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email_followup_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "star", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StarEmailFollowupTaskRequest {
    return new StarEmailFollowupTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StarEmailFollowupTaskRequest {
    return new StarEmailFollowupTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StarEmailFollowupTaskRequest {
    return new StarEmailFollowupTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StarEmailFollowupTaskRequest | PlainMessage<StarEmailFollowupTaskRequest> | undefined, b: StarEmailFollowupTaskRequest | PlainMessage<StarEmailFollowupTaskRequest> | undefined): boolean {
    return proto3.util.equals(StarEmailFollowupTaskRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.StarEmailFollowupTaskResponse
 */
export class StarEmailFollowupTaskResponse extends Message<StarEmailFollowupTaskResponse> {
  constructor(data?: PartialMessage<StarEmailFollowupTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.StarEmailFollowupTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StarEmailFollowupTaskResponse {
    return new StarEmailFollowupTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StarEmailFollowupTaskResponse {
    return new StarEmailFollowupTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StarEmailFollowupTaskResponse {
    return new StarEmailFollowupTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StarEmailFollowupTaskResponse | PlainMessage<StarEmailFollowupTaskResponse> | undefined, b: StarEmailFollowupTaskResponse | PlainMessage<StarEmailFollowupTaskResponse> | undefined): boolean {
    return proto3.util.equals(StarEmailFollowupTaskResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecentContactsRequest
 */
export class ListRecentContactsRequest extends Message<ListRecentContactsRequest> {
  constructor(data?: PartialMessage<ListRecentContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecentContactsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecentContactsRequest {
    return new ListRecentContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecentContactsRequest {
    return new ListRecentContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecentContactsRequest {
    return new ListRecentContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecentContactsRequest | PlainMessage<ListRecentContactsRequest> | undefined, b: ListRecentContactsRequest | PlainMessage<ListRecentContactsRequest> | undefined): boolean {
    return proto3.util.equals(ListRecentContactsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.ListRecentContactsResponse
 */
export class ListRecentContactsResponse extends Message<ListRecentContactsResponse> {
  /**
   * @generated from field: repeated okapicrm.v1.RecentContact recent_contacts = 1;
   */
  recentContacts: RecentContact[] = [];

  constructor(data?: PartialMessage<ListRecentContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.ListRecentContactsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recent_contacts", kind: "message", T: RecentContact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecentContactsResponse {
    return new ListRecentContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecentContactsResponse {
    return new ListRecentContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecentContactsResponse {
    return new ListRecentContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecentContactsResponse | PlainMessage<ListRecentContactsResponse> | undefined, b: ListRecentContactsResponse | PlainMessage<ListRecentContactsResponse> | undefined): boolean {
    return proto3.util.equals(ListRecentContactsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.RecentContact
 */
export class RecentContact extends Message<RecentContact> {
  /**
   * @generated from field: okapicrm.v1.Record person = 1;
   */
  person?: Record;

  /**
   * @generated from field: google.protobuf.Timestamp last_email_time = 2;
   */
  lastEmailTime?: Timestamp;

  constructor(data?: PartialMessage<RecentContact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.RecentContact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person", kind: "message", T: Record },
    { no: 2, name: "last_email_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecentContact {
    return new RecentContact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecentContact {
    return new RecentContact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecentContact {
    return new RecentContact().fromJsonString(jsonString, options);
  }

  static equals(a: RecentContact | PlainMessage<RecentContact> | undefined, b: RecentContact | PlainMessage<RecentContact> | undefined): boolean {
    return proto3.util.equals(RecentContact, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetWebhookSettingsRequest
 */
export class GetWebhookSettingsRequest extends Message<GetWebhookSettingsRequest> {
  constructor(data?: PartialMessage<GetWebhookSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetWebhookSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWebhookSettingsRequest {
    return new GetWebhookSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWebhookSettingsRequest {
    return new GetWebhookSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWebhookSettingsRequest {
    return new GetWebhookSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetWebhookSettingsRequest | PlainMessage<GetWebhookSettingsRequest> | undefined, b: GetWebhookSettingsRequest | PlainMessage<GetWebhookSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetWebhookSettingsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.EnableWebhookSettingsRequest
 */
export class EnableWebhookSettingsRequest extends Message<EnableWebhookSettingsRequest> {
  constructor(data?: PartialMessage<EnableWebhookSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.EnableWebhookSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnableWebhookSettingsRequest {
    return new EnableWebhookSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnableWebhookSettingsRequest {
    return new EnableWebhookSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnableWebhookSettingsRequest {
    return new EnableWebhookSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EnableWebhookSettingsRequest | PlainMessage<EnableWebhookSettingsRequest> | undefined, b: EnableWebhookSettingsRequest | PlainMessage<EnableWebhookSettingsRequest> | undefined): boolean {
    return proto3.util.equals(EnableWebhookSettingsRequest, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.EnableWebhookSettingsResponse
 */
export class EnableWebhookSettingsResponse extends Message<EnableWebhookSettingsResponse> {
  /**
   * @generated from field: okapicrm.v1.WebhookSettings webhook_settings = 1;
   */
  webhookSettings?: WebhookSettings;

  constructor(data?: PartialMessage<EnableWebhookSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.EnableWebhookSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "webhook_settings", kind: "message", T: WebhookSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnableWebhookSettingsResponse {
    return new EnableWebhookSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnableWebhookSettingsResponse {
    return new EnableWebhookSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnableWebhookSettingsResponse {
    return new EnableWebhookSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EnableWebhookSettingsResponse | PlainMessage<EnableWebhookSettingsResponse> | undefined, b: EnableWebhookSettingsResponse | PlainMessage<EnableWebhookSettingsResponse> | undefined): boolean {
    return proto3.util.equals(EnableWebhookSettingsResponse, a, b);
  }
}

/**
 * @generated from message okapicrm.v1.GetVizzlyIdentityConfigRequest
 */
export class GetVizzlyIdentityConfigRequest extends Message<GetVizzlyIdentityConfigRequest> {
  constructor(data?: PartialMessage<GetVizzlyIdentityConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "okapicrm.v1.GetVizzlyIdentityConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVizzlyIdentityConfigRequest {
    return new GetVizzlyIdentityConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVizzlyIdentityConfigRequest {
    return new GetVizzlyIdentityConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVizzlyIdentityConfigRequest {
    return new GetVizzlyIdentityConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVizzlyIdentityConfigRequest | PlainMessage<GetVizzlyIdentityConfigRequest> | undefined, b: GetVizzlyIdentityConfigRequest | PlainMessage<GetVizzlyIdentityConfigRequest> | undefined): boolean {
    return proto3.util.equals(GetVizzlyIdentityConfigRequest, a, b);
  }
}

